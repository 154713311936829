import Vue from 'vue'
import VueRouter from 'vue-router'
// 会员规则
// const  manager= () => import('@/components/beiyong/certification')
// const  fankui= () => import('@/components/beiyong/Fankui')

// const anatarget = () => import('@/components/qiyeshezhi/ana_model/Anatarget')
// const anasale = () => import('@/components/qiyeshezhi/ana_model/Anasale')
// const anatarsale = () => import('@/components/qiyeshezhi/ana_model/Anatarsale')
// const anatarproduct = () => import('@/components/qiyeshezhi/ana_model/Anatarproduct')
// const anatarcount = () => import('@/components/qiyeshezhi/ana_model/Anatarcount')
// const anasalemethod = () => import('@/components/qiyeshezhi/ana_model/Anasalemethod')
// const  anasalebranch= () => import('@/components/qiyeshezhi/ana_model/Anasalebranch')
// const  anasalealert= () => import('@/components/qiyeshezhi/ana_model/Anasalealert')

// const homea = () => import('@/page/homea')
import homea from '@/page/Homea'
// const home = () => import('@/page/home')
import home from '@/components/Home'
const login = () => import('@/components/denglu/Login')
const register = () => import('@/components/denglu/Register')
const forget = () => import('@/components/denglu/Forget')
const formyapp = () => import('@/components/Formyapp')
const initiateapp = () => import('@/components/Initiateapp')
const usetable = () => import('@/components/Usetable')
const ilaunched = () => import('@/components/Ilaunched')
const ihaveapp = () => import('@/components/Ihaveapp')
const orsigntoshare = () => import('@/components/Orsigntoshare')
const deng = () => import('@/components/denglu/Deng')

const copyme = () => import('@/components/Copyme')
const privacy = () => import('@/components/Privacy')

const producta = () => import('@/components/waiye/Producta')
const casea = () => import('@/components/waiye/Casea')
const downloada = () => import('@/components/waiye/Downloada')
const Introductiona = () => import('@/components/waiye/Introductiona')
const customera = () => import('@/components/waiye/Customera')
const traina = () => import('@/components/waiye/Traina')
const me = () => import('@/components/beiyong/me')
const personset = () => import('@/components/beiyong/Personset')
const myncb = () => import('@/components/beiyong/Myncb')
const mypwd = () => import('@/components/beiyong/Mypwd')
const mysuggestion = () => import('@/components/beiyong/Mysuggestion')
const quit = () => import('@/components/beiyong/Quit')

// const me = () => import('@/components/geren/Me')
// const newhead = () => import('@/components/xintou/Newhead')
// const personset = () => import('@/components/geren/Personset')
// const myncb = () => import('@/components/geren/Myncb')
// const mypwd = () => import('@/components/geren/Mypwd')
// const mysuggestion = () => import('@/components/geren/Mysuggestion')
// const ent = () => import('@/components/qiyeshezhi/Ent')
// const quan = () => import('@/components/qiyeshezhi/Quan')
// const ana = () => import('@/components/qiyeshezhi/Ana')
const quan = () => import('@/components/beiyong/Quan')
const enttarget = () => import('@/components/beiyong/Enttarget')
const entsale = () => import('@/components/beiyong/Entsale')
const modelall = () => import('@/components/beiyong/Modelall')
const modelallsale = () => import('@/components/beiyong/Modelallsale')
const enttarsale = () => import('@/components/beiyong/Enttarsale')
const enttarproduct = () => import('@/components/beiyong/Enttarproduct')
const enttarcount = () => import('@/components/beiyong/Enttarcount')
const prodjur = () => import('@/components/beiyong/Prodjur')

// const enttarget = () => import('@/components/qiyeshezhi/ent_model/Enttarget')
// const entsale = () => import('@/components/qiyeshezhi/ent_model/Entsale')
// const enttarsale = () => import('@/components/qiyeshezhi/ent_model/Enttarsale')
// const enttarproduct = () => import('@/components/qiyeshezhi/ent_model/Enttarproduct')
// const enttarcount = () => import('@/components/qiyeshezhi/ent_model/Enttarcount')

const anatarget = () => import('@/components/beiyong/Anatarget')
const anasale = () => import('@/components/beiyong/Anasale')
const anatarsale = () => import('@/components/beiyong/Anatarsale')
const anatarproduct = () => import('@/components/beiyong/Anatarproduct')
const anatarcount = () => import('@/components/beiyong/Anatarcount')
const anasalemethod = () => import('@/components/beiyong/Anasalemethod')
const anasalebranch = () => import('@/components/beiyong/Anasalebranch')
const anasalealert = () => import('@/components/beiyong/Anasalealert')
const anasalekanban = () => import('@/components/beiyong/Anasalekanban')// 角色规则
const membership = () => import('@/components/beiyong/Membership/membership')
// const save = () => import('@/page/save')   //最开始的登录页
const enterprise = () => import('@/page/Enterprise')
const uploada = () => import('@/page/Uploada')
const install = () => import('@/page/Install')
const resetpwd = () => import('@/page/Resetpwd')
const fgtrgs = () => import('@/page/Fgtrgs')
const certification = () => import('@/page/Certification')
const uplo = () => import('@/page/Uplo')
const master = () => import('@/page/Master')
const basicdata = () => import('@/page/Basicdata')
const subadmin = () => import('@/page/Subadmin')
const imports = () => import('@/page/Imports')
const anadata = () => import('@/page/Anadata')
const dbms = () => import('@/page/Dbms')
const calendara = () => import('@/page/Calendara')
const approvalz = () => import('@/page/approvalz')

const ctft = () => import('@/page/pagea/Ctft')
const anaproduct = () => import('@/page/pagea/Anaproduct')
const primaryent = () => import('@/page/pagea/PrimaryEnt')
const detsta = () => import('@/page/pagea/DetSta')
const control = () => import('@/page/pagea/Control')
const worksettings = () => import('@/page/pagea/Worksettings')
const product = () => import('@/page/pagea/Product')
const warehouse = () => import('@/page/pagea/Warehouse')
const systemyear = () => import('@/page/pagea/Systemyear')
const field = () => import('@/page/pagea/Field')
const sales = () => import('@/page/pagea/Sales')
const target = () => import('@/page/pagea/Target')
const feedbackpage = () => import('@/page/pagea/Feedbackpage')
const primarylog = () => import('@/page/pagea/PrimaryLog')
const account = () => import('@/page/pagea/Account')
const importquery = () => import('@/page/pagea/Importquery')
const admlog = () => import('@/page/pagea/Admlog')
const exportb = () => import('@/page/pagea/Exportb')
const role = () => import('@/page/pagea/Role')
const appsett = () => import('@/page/pagea/AppSett')
const appdaochu = () => import('@/page/pagea/Appdaochu')

const accountingb = () => import('@/page/shujumodal/Accountingb')
const growupb = () => import('@/page/shujumodal/Growupb')
const managementb = () => import('@/page/shujumodal/Managementb')
const productb = () => import('@/page/shujumodal/Productb')
const saleb = () => import('@/page/shujumodal/Saleb')
const targetb = () => import('@/page/shujumodal/Targetb')

const aspreackeven = () => import('@/modala/ASpreackeven')
const sale = () => import('@/modala/Sale')
const productModal = () => import('@/modala/Product')

const tableone = () => import('@/modala/Tables/TableOne')
const tabletwo = () => import('@/modala/Tables/TableTwo')
const tablethree = () => import('@/modala/Tables/TableThree')
const tablefour = () => import('@/modala/Tables/TableFour')
const tablefive = () => import('@/modala/Tables/TableFive')
const tablesix = () => import('@/modala/Tables/TableSix')
const tableseven = () => import('@/modala/Tables/TableSeven')

const cusman = () => import('@/modala/xiaoshou/Cusman')// 客户管理
const salesman = () => import('@/modala/xiaoshou/Salesman')
const salesana = () => import('@/modala/xiaoshou/Salesana')
const vipAdimn = () => import('@/vip/vipAdmin')
const vipDetail = () => import('@/vip/vipDetail')// 会员设置
const vipDetailTable = () => import('@/vip/vipDetailTable')
const kanban = () => import('@/modala/xiaoshou/Kanban')
// const kanbanlist = () => import('@/modala/xiaoshou/Kanbanlist')
const assignedlist = () => import('@/modala/xiaoshou/Assignedlist')
const highseas = () => import('@/modala/xiaoshou/Highseas')
const refware = () => import('@/modala/xiaoshou/Refware')
const customerrules = () => import('@/modala/xiaoshou/Customerrules')
const importexport = () => import('@/modala/xiaoshou/Importexport')
const importexport_xiaoshou = () => import('@/modala/xiaoshou/Importexportx')

const purchasing = () => import('@/modala/chanpin/Purchasing')
const purchasingkb = () => import('@/modala/chanpin/Purchasingkb')
const inventory = () => import('@/modala/chanpin/Inventory')
const purchasing_tou = () => import('@/modala/chanpin/Purchasingtou')
const inventorytou = () => import('@/modala/chanpin/Inventorytou')

const sqe = () => import('@/modala/chanpin/caigou/Sqe')
const price = () => import('@/modala/chanpin/caigou/Price')
const procurement_rules = () => import('@/modala/chanpin/caigou/ProcurementRules')
const inventory_rules = () => import('@/modala/chanpin/caigou/Inventoryrules')
const stock = () => import('@/modala/chanpin/caigou/Stock')
const disc = () => import('@/modala/chanpin/caigou/Disc')
const importextgong = () => import('@/modala/chanpin/caigou/Importextgong')
const supplierprice = () => import('@/modala/chanpin/caigou/Supplierprice')

const rolekanban = () => import('@/modala/Gongzuotai/Rolekanban')

const approvala = () => import('@/approvalmodal/Approvala')

const biaodangg = () => import('@/gongyou/biaodanGG')
const biaodansj = () => import('@/gongyou/biaodanSJ')
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'deng',
    component: deng,
    redirect: '/login',
    children: [
      {
        path: '/login',
        name: 'login',
        component: login
      }, {
        path: '/register',
        name: 'register',
        component: register
      }, {
        path: '/forget',
        name: 'forget',
        component: forget
      }
    ]
  },
  {
    path: '/enterprise',
    name: 'enterprise',
    component: enterprise
  },
  {
    path: '/uploada',
    name: 'uploada',
    component: uploada
  },
  {
    path: '/install',
    name: 'install',
    component: install
  },
  {
    path: '/resetpwd',
    name: 'resetpwd',
    component: resetpwd
  },
  {
    path: '/fgtrgs',
    name: 'fgtrgs',
    component: fgtrgs
  },
  {
    path: '/certification',
    name: 'certification',
    component: certification
  },
  {
    path: '/uplo',
    name: 'uplo',
    component: uplo
  },
  {
    path: '/master',
    name: 'master',
    component: master
  },
  {
    path: '/ctft',
    name: 'ctft',
    component: ctft
  },
  {
    path: '/primaryent',
    name: 'primaryent',
    component: primaryent
  },
  {
    path: '/subadmin',
    name: 'subadmin',
    component: subadmin
  },
  {
    path: '/basicdata',
    name: 'basicdata',
    component: basicdata,
    redirect: '/detsta',
    children: [
      {
        path: '/detsta',
        name: 'detsta',
        component: detsta
      },
      {
        path: '/control',
        name: 'control',
        component: control
      },
      {
        path: '/worksettings',
        name: 'worksettings',
        component: worksettings
      },
      {
        path: '/product',
        name: 'product',
        component: product
      },
      {
        path: '/warehouse',
        name: 'warehouse',
        component: warehouse
      },
      {
        path: '/account',
        name: 'account',
        component: account
      },
      {
        path: '/systemyear',
        name: 'systemyear',
        component: systemyear
      },
      {
        path: '/field',
        name: 'field',
        component: field
      },
      {
        path: '/importquery',
        name: 'importquery',
        component: importquery
      },
      {
        path: '/admlog',
        name: 'admlog',
        component: admlog
      },
      {
        path: '/role',
        name: 'role',
        component: role
      }
    ]
  },
  {
    path: '/imports',
    name: 'imports',
    component: imports
  },
  {
    path: '/feedbackpage',
    name: 'feedbackpage',
    component: feedbackpage
  },
  {
    path: '/producta',
    name: 'producta',
    component: producta
  },
  {
    path: '/casea',
    name: 'casea',
    component: casea
  },
  {
    path: '/downloada',
    name: 'downloada',
    component: downloada
  },
  {
    path: '/Introductiona',
    name: 'Introductiona',
    component: Introductiona
  },
  {
    path: '/customera',
    name: 'customera',
    component: customera
  },
  {
    path: '/traina',
    name: 'traina',
    component: traina
  },
  {
    path: '/anadata',
    name: 'anadata',
    component: anadata,
    children: [
      {
        path: '/anatarget',
        name: 'anatarget',
        component: anatarget,
        redirect: '/anatarsale',
        children: [
          {
            path: '/anatarsale',
            name: 'anatarsale',
            component: anatarsale
          },
          {
            path: '/anatarproduct',
            name: 'anatarproduct',
            component: anatarproduct
          },
          {
            path: '/anatarcount',
            name: 'anatarcount',
            component: anatarcount
          }
        ]
      },
      {
        path: '/anaproduct',
        name: 'anaproduct',
        component: anaproduct
      },
      {
        path: '/anasale',
        name: 'anasale',
        component: anasale,
        redirect: '/anasalemethod',
        children: [
          {
            path: '/anasalemethod',
            name: 'anasalemethod',
            component: anasalemethod
          },
          {
            path: '/anasalebranch',
            name: 'anasalebranch',
            component: anasalebranch
          },
          {
            path: '/anasalealert',
            name: 'anasalealert',
            component: anasalealert
          },
          {
            path: '/anasalekanban', // 角色规则
            name: 'anasalekanban',
            component: anasalekanban
          }, {
            path: '/membership', // 会员规则
            name: 'membership',
            component: membership
          }
        ]
      }
    ]
  },
  {
    path: '/primarylog',
    name: 'primarylog',
    component: primarylog
  },
  {
    path: '/home',
    name: 'home',
    component: home
  },
  {
    path: '/dbms',
    name: 'dbms',
    component: dbms,
    redirect: '/targetb',
    children: [
      {
        path: '/accountingb', // 核算
        name: 'accountingb',
        component: accountingb
      },
      {
        path: '/growupb', // 成长
        name: 'growupb',
        component: growupb
      },
      {
        path: '/managementb', // 风控
        name: 'managementb',
        component: managementb
      },
      {
        path: '/productb', // 产品
        name: 'productb',
        component: productb
      },
      {
        path: '/saleb', // 销售
        name: 'saleb',
        component: saleb
      },
      {
        path: '/targetb', // 目标
        name: 'targetb',
        component: targetb
      }
    ]
  },
  {
    path: '/calendara',
    name: 'calendara',
    component: calendara
  },
  {
    path: '/approvalz',
    name: 'approvalz',
    component: approvalz,
    redirect: '/appsett',
    children: [
      {
        path: '/appsett', // 审批管理
        name: 'appsett',
        component: appsett
      },
      {
        path: '/modelallsale', // 表单-代写
        name: 'modelallsale',
        component: modelallsale
      },
      {
        path: '/appdaochu', // 审批导出
        name: 'appdaochu',
        component: appdaochu
      }
    ]
  },
  {
    path: '/exportb',
    name: 'exportb',
    component: exportb
  },
  {
    path: '/aspreackeven',
    name: 'aspreackeven',
    component: aspreackeven,
    children: [
      {
        path: '/tableone',
        name: 'tableone',
        component: tableone
      },
      {
        path: '/tabletwo',
        name: 'tabletwo',
        component: tabletwo
      },
      {
        path: '/tablethree',
        name: 'tablethree',
        component: tablethree
      },
      {
        path: '/tablefour',
        name: 'tablefour',
        component: tablefour
      },
      {
        path: '/tablefive',
        name: 'tablefive',
        component: tablefive
      },
      {
        path: '/tablesix',
        name: 'tablesix',
        component: tablesix
      },
      {
        path: '/tableseven',
        name: 'tableseven',
        component: tableseven
      }
    ]
  },
  {
    path: '/sale',
    name: 'sale',
    component: sale,
    redirect: '/cusman',
    children: [
      {
        path: '/cusman', // 客户管理
        name: 'cusman',
        component: cusman,
        children: [
          {
            path: '/assignedlist', // 已分配列表
            name: 'assignedlist',
            component: assignedlist
          },
          {
            path: '/highseas', // 公海池列表
            name: 'highseas',
            component: highseas
          },
          {
            path: '/importexport', // 导入导出
            name: 'importexport',
            component: importexport
          },
          {
            path: '/refware', // 冷冻仓列表
            name: 'refware',
            component: refware
          },
          {
            path: '/customerrules', // 规则
            name: 'customerrules',
            component: customerrules
          }
        ]
      }, {
        path: '/vipAdimn',
        name: 'vipAdimn',
        component: vipAdimn
      },
      {
        path: '/vipDetailTable',
        name: 'vipDetailTable',
        component: vipDetailTable
      },
      {
        path: '/vipDetail',
        name: 'vipDetail', // 会员分析
        component: vipDetail
      },
      {
        path: '/salesman', // 销售管理
        name: 'salesman',
        component: salesman
      },
      {
        path: '/salesana', // 销售分析
        name: 'salesana',
        component: salesana
      },
      {
        path: '/kanban', // 销售看板
        name: 'kanban',
        component: kanban
      },
      {
        path: '/importexport_xiaoshou',
        name: 'importexport_xiaoshou',
        component: importexport_xiaoshou
      }
    ]
  },
  {
    path: '/productModal',
    name: 'productModal',
    component: productModal,
    redirect: '/purchasing',
    children: [
      {
        path: '/purchasing', // 采购管理
        name: 'purchasing',
        component: purchasing
      },
      {
        path: '/purchasingkb', // 采购看板管理
        name: 'purchasingkb',
        component: purchasingkb
      },
      {
        path: '/inventory', // 库存管理
        name: 'inventory',
        component: inventory
      },
      {
        path: '/purchasing_tou', // 库存管理  sqe
        name: 'purchasing_tou',
        component: purchasing_tou,
        redirect: '/sqe',
        children: [
          {
            path: '/sqe', // 供应商管理列表
            name: 'sqe',
            component: sqe
          },
          {
            path: '/price', // 供应商比价列表
            name: 'price',
            component: price
          },
          {
            path: '/procurement_rules', // 采购单规则
            name: 'procurement_rules',
            component: procurement_rules
          },
          {
            path: '/supplierprice', // 供应商价格
            name: 'supplierprice',
            component: supplierprice
          }
        ]
      },
      {
        path: '/inventorytou', // 库存管理  sqe
        name: 'inventorytou',
        component: inventorytou,
        redirect: '/stock',
        children: [
          {
            path: '/stock', // 供应商管理列表
            name: 'stock',
            component: stock
          },
          {
            path: '/disc', // 盘点列表
            name: 'disc',
            component: disc
          },
          {
            path: '/inventory_rules', // 盘点列表
            name: 'inventory_rules',
            component: inventory_rules
          },
          {
            path: '/importextgong', // 盘点列表
            name: 'importextgong',
            component: importextgong
          }
        ]
      }
    ]
  },
  {
    path: '/approvala',
    name: 'approvala',
    component: approvala
  },
  {
    path: '/formyapp',
    name: 'formyapp',
    component: formyapp
  },
  {
    path: '/ihaveapp',
    name: 'ihaveapp',
    component: ihaveapp
  },

  {
    path: '/copyme',
    name: 'copyme',
    component: copyme
  },
  {
    path: '/ilaunched',
    name: 'ilaunched',
    component: ilaunched
  },
  {
    path: '/initiateapp',
    name: 'initiateapp',
    component: initiateapp
  },
  {
    path: '/orsigntoshare',
    name: 'orsigntoshare',
    component: orsigntoshare
  },
  {
    path: '/usetable',
    name: 'usetable',
    component: usetable
  },
  {
    path: '/biaodangg',
    name: 'biaodangg',
    component: biaodangg
  },
  {
    path: '/biaodansj',
    name: 'biaodansj',
    component: biaodansj
  },
  {
    path: '/quan',
    name: 'quan',
    component: quan,
    children: [
      {
        path: '/enttarget',
        name: 'enttarget',
        component: enttarget,
        redirect: '/enttarsale',
        children: [
          {
            path: '/enttarsale',
            name: 'enttarsale',
            component: enttarsale
          },
          {
            path: '/enttarproduct',
            name: 'enttarproduct',
            component: enttarproduct
          },
          {
            path: '/enttarcount',
            name: 'enttarcount',
            component: enttarcount
          }
        ]
      },
      {
        path: '/entsale',
        name: 'entsale',
        component: entsale
      },
      {
        path: '/modelall',
        name: 'modelall',
        component: modelall
      },
      {
        path: '/prodjur',
        name: 'prodjur',
        component: prodjur
      }
    ]
  },
  {
    path: '/me',
    name: 'me',
    component: me,
    redirect: '/personset',
    children: [
      {
        path: '/personset',
        name: 'personset',
        component: personset
      },
      {
        path: '/myncb',
        name: 'myncb',
        component: myncb
      },
      {
        path: '/mypwd',
        name: 'mypwd',
        component: mypwd
      },
      {
        path: '/mysuggestion',
        name: 'mysuggestion',
        component: mysuggestion
      },
      {
        path: '/quit',
        name: 'quit',
        component: quit
      }
    ]
  },
  {
    path: '/rolekanban',
    name: 'rolekanban',
    component: rolekanban
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: privacy
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
