<template>
    <div>
      <!-- 流动表单 流程设计 -->
        <div class="node-wrap" v-if="nodeConfig.type!=4">
            <div class="node-wrap-box" :class="(nodeConfig.type==0?'start-node ':'')+(isTried&&nodeConfig.error?'active error':'')">
                <div>
                    <div class="title" :style="'background: rgb('+ ['87, 106, 149','255, 148, 62','50, 150, 250'][nodeConfig.type] +');'">
                        <span class="iconfont" v-show="nodeConfig.type==1"></span>
                        <span class="iconfont" v-show="nodeConfig.type==2"></span>
                        <span v-if="nodeConfig.type==0">{{nodeConfig.nodeName}}</span>
                        <input type="text" class="ant-input editable-title-input" v-if="nodeConfig.type!=0&&isInput"
                        @blur="blurEvent()" @focus="$event.currentTarget.select()" v-focus
                        v-model="nodeConfig.nodeName" :placeholder="placeholderList[nodeConfig.type]">
                        <span class="editable-title" @click="clickEvent()" v-if="nodeConfig.type!=0&&!isInput">{{nodeConfig.nodeName}}</span>
                        <i class="anticon anticon-close close" v-if="nodeConfig.type!=0" @click="delNode()"></i>
                    </div>
                    <div class="content" @click="setPerson">
                        <div class="text" v-if="nodeConfig.type==0">{{arrToStr(flowPermission)?arrToStr(flowPermission):'所有人'}}</div>
                        <div class="text" v-if="nodeConfig.type==1">
                            <span class="placeholder" v-if="!setApproverStr(nodeConfig)">请选择{{placeholderList[nodeConfig.type]}}</span>
                            {{setApproverStr(nodeConfig)}}
                        </div>
                        <div class="text" v-if="nodeConfig.type==2">
                            <span class="placeholder" v-if="!copyerStr(nodeConfig)">请选择{{placeholderList[nodeConfig.type]}}</span>
                            {{copyerStr(nodeConfig)}}
                        </div>
                        <div class="text" v-if="nodeConfig.type==9">
                            <span class="placeholder" v-if="!copyerStr(nodeConfig)">请选择{{placeholderList[nodeConfig.type-6]}}</span>
                            {{copyerStr(nodeConfig)}}
                        </div>
                        <i class="anticon anticon-right arrow"></i>
                    </div>
                    <div class="error_tip" v-if="isTried&&nodeConfig.error">
                        <i class="anticon anticon-exclamation-circle" style="color: rgb(242, 86, 67);"></i>
                    </div>
                </div>
            </div>
            <addNode :childNodeP.sync="nodeConfig.childNode"></addNode>
        </div>
        <div class="branch-wrap" v-if="nodeConfig.type==4">
            <div class="branch-box-wrap">
                <div class="branch-box">
                    <button class="add-branch" @click="addTerm">添加条件</button>
                    <div class="col-box" v-for="(item,index) in nodeConfig.conditionNodes" :key="index">
                        <div class="condition-node">
                            <div class="condition-node-box">
                                <div class="auto-judge" :class="isTried&&item.error?'error active':''">
                                    <div class="sort-left" v-if="index!=0" @click="arrTransfer(index,-1)">&lt;</div>
                                    <div class="title-wrapper">
                                        <input type="text" class="ant-input editable-title-input" v-if="isInputList[index]"
                                        @blur="blurEvent(index)" @focus="$event.currentTarget.select()" v-focus v-model="item.nodeName">
                                        <span class="editable-title" @click="clickEvent(index)" v-if="!isInputList[index]">{{item.nodeName}}</span>
                                        <span class="priority-title" @click="setPerson(item.priorityLevel)">优先级{{item.priorityLevel}}</span>
                                        <i class="anticon anticon-close close" @click="delTerm(index)"></i>
                                    </div>
                                    <div class="sort-right" v-if="index!=nodeConfig.conditionNodes.length-1"
                                        @click="arrTransfer(index)">&gt;</div>
                                    <div class="content" @click="setPerson(item.priorityLevel)">{{conditionStr(item,index)}}</div>
                                    <div class="error_tip" v-if="isTried&&item.error">
                                        <i class="anticon anticon-exclamation-circle" style="color: rgb(242, 86, 67);"></i>
                                    </div>
                                </div>
                                <addNode :childNodeP.sync="item.childNode"></addNode>
                            </div>
                        </div>
                        <nodeWrap v-if="item.childNode && item.childNode" :nodeConfig.sync="item.childNode" :tableId="tableId"
                        :isTried.sync="isTried" :directorMaxLevel="directorMaxLevel"></nodeWrap>
                        <div class="top-left-cover-line" v-if="index==0"></div>
                        <div class="bottom-left-cover-line" v-if="index==0"></div>
                        <div class="top-right-cover-line" v-if="index==nodeConfig.conditionNodes.length-1"></div>
                        <div class="bottom-right-cover-line" v-if="index==nodeConfig.conditionNodes.length-1"></div>
                    </div>
                </div>
                <addNode :childNodeP.sync="nodeConfig.childNode"></addNode>
            </div>
        </div>
        <el-drawer title="发起人" :visible.sync="promoterDrawer" direction="rtl" class="set_promoter" size="5.5rem" :before-close="savePromoter">
            <div class="demo-drawer__content">
                <div class="promoter_content drawer_content">
                    <p>{{arrToStr(flowPermission1)?arrToStr(flowPermission1):'所有人'}}</p>
                    <el-button type="primary" @click="addPromoter">添加/修改发起人</el-button>
                </div>
                <div class="demo-drawer__footer clear">
                    <el-button type="primary" @click="savePromoter">确 定</el-button>
                    <el-button @click="promoterDrawer = false">取 消</el-button>
                </div>
                <!-- <el-dialog title="选择成员" :visible.sync="promoterVisible" width="6rem" append-to-body class="promoter_person">
                    <div class="person_body clear">
                        <div class="person_tree l">
                            <input type="text" placeholder="搜索成员" v-model="promoterSearchName" @input="getDebounceData($event)">
                            <p class="ellipsis tree_nav" v-if="!promoterSearchName">
                                <span @click="getDepartmentList(0)" class="ellipsis">天下</span>
                                <span v-for="(item,index) in departments.titleDepartments" class="ellipsis"
                                :key="index+'a'" @click="getDepartmentList(item.id)">{{item.departmentName}}</span>
                            </p>
                            <ul>
                                <li v-for="(item,index) in departments.childDepartments" :key="index+'b'" class="check_box">
                                    <a :class="toggleClass(checkedDepartmentList,item)&&'active'" @click="toChecked(checkedDepartmentList,item)">
                                        <img src="@/assets/images/icon_file.png">{{item.departmentName}}</a>
                                    <i @click="getDepartmentList(item.id)">下级</i>
                                </li>
                                <li v-for="(item,index) in departments.employees" :key="index+'c'" class="check_box">
                                    <a :class="toggleClass(checkedEmployessList,item)&&'active'" @click="toChecked(checkedEmployessList,item)" :title="item.departmentNames">
                                        <img src="@/assets/images/icon_people.png">{{item.employeeName}}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="has_selected l">
                            <p class="clear">已选（{{checkedDepartmentList.length+checkedEmployessList.length}}）
                                <a @click="checkedDepartmentList=[];checkedEmployessList=[]">清空</a>
                            </p>
                            <ul>
                                <li v-for="(item,index) in checkedDepartmentList" :key="index+'d'">
                                    <img src="@/assets/images/icon_file.png">
                                    <span>{{item.departmentName}}</span>
                                    <img src="@/assets/images/cancel.png" @click="removeEle(checkedDepartmentList,item)">
                                </li>
                                <li v-for="(item,index) in checkedEmployessList" :key="index+'e'">
                                    <img src="@/assets/images/icon_people.png">
                                    <span>{{item.employeeName}}</span>
                                    <img src="@/assets/images/cancel.png" @click="removeEle(checkedEmployessList,item)">
                                </li>
                            </ul>
                        </div>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="promoterVisible = false">取 消</el-button>
                        <el-button type="primary" @click="surePromoter">确 定</el-button>
                    </span>
                </el-dialog> -->
                <el-dialog title="选择人员" :center="true" :show-close='false' append-to-body :visible.sync="promoterVisible">
                    <div class="tiao_bumen">
                        <div class="xuanze">
                            <p class="tit">选择：</p>
                            <el-tree
                                :data="faqi_ren_list"
                                class="tree_bumen"
                                show-checkbox
                                default-expand-all
                                node-key="id"
                                ref="faqi_ren_tree"
                                highlight-current
                                @check-change="xuanze_renyuan"
                                :props="defaultProps">
                            </el-tree>
                            <!-- <div class="xuanze_sousuo">
                                <i class="el-icon-search"></i>
                                <input type="text" placeholder="搜索" @keyup="keyu" v-model="text">
                            </div> -->
                            <!-- <div class="mianbaoxie">
                                <div v-for="(i,index) in mianbao_list" :key='index'  @click="mianbao_dian(i,index)">
                                    <p :class="mianbao_list.length-1==index?'p1':'p2'">{{i.name}}</p>
                                    <i class="el-icon-arrow-right"></i>
                                </div>
                            </div>
                            <div class="quanxuan"><input type="checkbox" @click="dianji_quanxuan" ref='quan'><span>全选</span></div>
                            <div class="nei_box">
                                <div class="liebiao">
                                    <ul>
                                        <li v-for="(i,index) in qiye_bumen_list" :key="index" @click="dianji_bumen(i)">
                                            <p style="margin-left:0.16rem;">{{i.dept_name}}</p>
                                            <img class="img1" :src="i.child_flag=='0'?require('../assets/chazi.png'):require('../assets/chazi_ok.png')" alt="">
                                        </li>
                                    </ul>
                                    <ul>
                                        <li v-for="(i,index) in qiye_renyuan_list" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
                                            <input ref="have" type="checkbox" @click="dianji_xuanren(i,$event,index)">
                                            <p>{{i.name}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div> -->
                        </div>
                        <div class="yixuan">
                            <p class="tit">已选：</p>
                            <div class="nei_box">
                                <div class="liebiao">
                                    <ul>
                                        <li v-for="(i,index) in qiye_renyuan_list_xuan" :key="index">
                                            <p>{{i.label}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="foote">
                        <p class="queding" @click="faqi_ren_sure">确定</p>
                        <p class="quxiao" @click="promoterVisible=false">取消</p>
                    </div>
                </el-dialog>
            </div>
        </el-drawer>
        <el-drawer title="审批人设置" :visible.sync="approverDrawer" direction="rtl" class="set_promoter" size="5.5rem" :before-close="saveApprover">
            <div class="demo-drawer__content">
                <div class="drawer_content">
                    <div class="drawer_content_tit">
                        <ul>
                            <li v-for="(i,index) in biaodan_quanxian_list" @click="biaodan_quanxian_num=index" :class="biaodan_quanxian_num==index?'shenpi_ren_zise':''" :key="index">{{i}}</li>
                        </ul>
                    </div>
                    <div class="approver_content" v-show="biaodan_quanxian_num==0">
                        <el-radio-group v-model="approverConfig.settype" class="clear" @change="changeType">
                            <el-radio :label="1">指定成员</el-radio>
                            <el-radio :label="2">主管</el-radio>
                            <el-radio :label="8">角色</el-radio>
                            <el-radio :label="4">发起人自选</el-radio>
                            <!-- <el-radio :label="5">发起人自己</el-radio>
                            <el-radio :label="7">连续多级主管</el-radio> -->
                        </el-radio-group>
                    </div>
                    <div class="approver_manager" v-show="biaodan_quanxian_num==0" v-if="approverConfig.settype==2">
                        <p>
                            <span>发起人的：</span>
                            <select v-model="approverConfig.directorLevel">
                                <option v-for="item in directorMaxLevel" :value="item" :key="item">{{item==1?'直接':'第'+item+'级'}}主管</option>
                            </select>
                        </p>
                        <p class="tip">找不到主管时，由上级主管代审批</p>
                    </div>
                    <!-- <div class="approver_self" v-if="approverConfig.settype==5">
                        <p>该审批节点设置“发起人自己”后，审批人默认为发起人</p>
                    </div>
                    <div class="approver_self_select" v-show="approverConfig.settype==4">
                        <el-radio-group v-model="approverConfig.selectMode" style="width: 100%;">
                            <el-radio :label="1">选一个人</el-radio>
                            <el-radio :label="2">选多个人</el-radio>
                        </el-radio-group>
                        <h3>选择范围</h3>
                        <el-radio-group v-model="approverConfig.selectRange" style="width: 100%;" @change="changeRange">
                            <el-radio :label="1">全公司</el-radio>
                            <el-radio :label="2">指定成员</el-radio>
                            <el-radio :label="3">指定角色</el-radio>
                        </el-radio-group>
                        <el-button type="primary" @click="addApprover" v-if="approverConfig.selectRange==2">添加/修改成员</el-button>
                        <el-button type="primary" @click="addRoleApprover" v-if="approverConfig.selectRange==3">添加/修改角色</el-button>
                        <p class="selected_list" v-if="approverConfig.selectRange==2||approverConfig.selectRange==3">
                            <span v-for="(item,index) in approverConfig.nodeUserList" :key="index">{{item.name}}
                                <img src="@/assets/images/add-close1.png" @click="removeEle(approverConfig.nodeUserList,item,'targetId')">
                            </span>
                            <a v-if="approverConfig.nodeUserList.length!=0&&approverConfig.selectRange!=1" @click="approverConfig.nodeUserList=[]">清除</a>
                        </p>
                    </div>
                    <div class="approver_manager" v-if="approverConfig.settype==7">
                        <p>审批终点</p>
                        <p style="padding-bottom:0.2rem">
                            <span>发起人的：</span>
                            <select v-model="approverConfig.examineEndDirectorLevel">
                                <option v-for="item in directorMaxLevel" :value="item" :key="item">{{item==1?'最高':'第'+item}}层级主管</option>
                            </select>
                        </p>
                    </div> -->
                    <div class="approver_manager" v-show="biaodan_quanxian_num==0" v-if="approverConfig.settype==4">
                        <p class="zixuan">
                            <select v-model="approverConfig.self_select_user_num" @change="changeUserNum">
                                <option value="1">自选单个人</option>
                                <option value="2">自选多个人</option>
                            </select>
                        </p>
                        <p>选择范围</p>
                        <p class="zixuan">
                            <select v-model="approverConfig.self_select_area" @change="changeArea">
                                <option value="1">全公司</option>
                                <option value="2">指定成员</option>
                                <option value="3">角色</option>
                            </select>
                        </p>
                    </div>
                    <div class="approver_manager" v-show="biaodan_quanxian_num==0">
                        <el-button type="primary" @click="addApprover" v-if="approverConfig.settype==1||(approverConfig.settype==4&&approverConfig.self_select_area==2)">添加/修改成员</el-button>
                        <el-button type="primary" @click="addRoleApprover" v-if="approverConfig.settype==8||(approverConfig.settype==4&&approverConfig.self_select_area==3)">添加/修改角色</el-button>
                        <p class="selected_list" style="margin-top:0.2rem;" v-if="approverConfig.settype==1||approverConfig.settype==8||approverConfig.settype==4">
                            <span v-for="(item,index) in approverConfig.nodeUserList" :key="index">{{item.name}}
                                <img src="@/assets/images/add-close1.png" @click="removeEle(approverConfig.nodeUserList,item,'targetId')">
                            </span>
                            <a v-if="approverConfig.nodeUserList.length!=0" @click="approverConfig.nodeUserList=[];qiye_juesery_list_xuan=[];qiye_renyuan_list_xuan=[]">清除</a>
                        </p>
                    </div>
                    <div class="approver_some" v-show="biaodan_quanxian_num==0" v-if="(approverConfig.settype==1&&approverConfig.nodeUserList.length>1)
                        ||(approverConfig.settype==8&&
                        approverConfig.nodeUserList.length>=1)||
                        (approverConfig.self_select_user_num==2&&
                        ((approverConfig.settype==4&&approverConfig.nodeUserList.length>1)||
                        (approverConfig.self_select_area==3&&approverConfig.nodeUserList.length==1)||
                        approverConfig.self_select_area==1))">
                        <p>多人审批时采用的审批方式</p>
                        <el-radio-group v-model="approverConfig.examineMode" class="clear">
                            <el-radio :label="1" v-if="approverConfig.settype!=8">依次审批</el-radio>
                            <br/>
                            <el-radio :label="2" v-if="approverConfig.settype!=2">会签(须所有审批人同意)</el-radio>
                            <br/>
                            <el-radio :label="3" v-if="approverConfig.settype!=2">或签(其中一个审批人同意)</el-radio>
                        </el-radio-group>
                    </div>
                    <div class="biaodan_kongjian_quanxuan" v-show="biaodan_quanxian_num==1">
                        <ul class="biaodan_kongjian_quanxuan_ul">
                            <li class="biaodan_kongjian_quanxuan_li">
                                <ul>
                                    <li>表单字段</li>
                                    <li @click="dianji_xuanze_lie('1')"><img :src="ke_bianji?require('../assets/me/xuan_zi.png'):require('../assets/me/weixuan_zi.png')">可编辑</li>
                                    <li @click="dianji_xuanze_lie('2')"><img :src="zhidu?require('../assets/me/xuan_zi.png'):require('../assets/me/weixuan_zi.png')">只读</li>
                                    <li @click="dianji_xuanze_lie('3')"><img :src="yincang?require('../assets/me/xuan_zi.png'):require('../assets/me/weixuan_zi.png')">隐藏</li>
                                </ul>
                            </li>
                            <li class="biaodan_kongjian_quanxuan_li" v-for="(i,index) in kongjian_quanxian_list" :key="index">
                                <ul>
                                    <li>{{i.kongjian_name}}</li>
                                    <!-- <li :style="i.bianji?'cursor: not-allowed':''" @click="i.bianji?wuyong():dianji_qiehuan_zhuangtai(i,'1')"><img :src="i.type==1?require('../assets/me/xuan_zi.png'):require('../assets/me/weixuan_zi.png')"></li> -->
                                    <li @click="dianji_qiehuan_zhuangtai(i,'1')"><img :src="i.type==1?require('../assets/me/xuan_zi.png'):require('../assets/me/weixuan_zi.png')"></li>
                                    <li @click="dianji_qiehuan_zhuangtai(i,'2')"><img :src="i.type==2?require('../assets/me/xuan_zi.png'):require('../assets/me/weixuan_zi.png')"></li>
                                    <li @click="dianji_qiehuan_zhuangtai(i,'3')"><img :src="i.type==3?require('../assets/me/xuan_zi.png'):require('../assets/me/weixuan_zi.png')"></li>
                                </ul>
                            </li>
                        </ul>

                    </div>
                    <!-- <div class  -->
                </div>
                <div class="demo-drawer__footer clear">
                    <el-button type="primary" @click="saveApprover">确 定</el-button>
                    <el-button @click="approverDrawer = false">取 消</el-button>
                </div>
                <!-- <el-dialog title="选择成员" :visible.sync="approverVisible" width="6rem" append-to-body class="promoter_person">
                    <div class="person_body clear">
                        <div class="person_tree l">
                            <input type="text" placeholder="搜索成员" v-model="approverSearchName" @input="getDebounceData($event)">
                            <p class="ellipsis tree_nav" v-if="!approverSearchName">
                                <span @click="getDepartmentList(0)" class="ellipsis">天下</span>
                                <span v-for="(item,index) in departments.titleDepartments" class="ellipsis"
                                :key="index+'a'" @click="getDepartmentList(item.id)">{{item.departmentName}}</span>
                            </p>
                            <ul>
                                <li v-for="(item,index) in departments.childDepartments" :key="index+'b'" class="check_box not">
                                    <a><img src="@/assets/images/icon_file.png">{{item.departmentName}}</a>
                                    <i @click="getDepartmentList(item.id)">下级</i>
                                </li>
                                <li v-for="(item,index) in departments.employees" :key="index+'c'" class="check_box">
                                    <a :class="toggleClass(approverEmplyessList,item)&&'active'" @click="toChecked(approverEmplyessList,item)" :title="item.departmentNames">
                                        <img src="@/assets/images/icon_people.png">{{item.employeeName}}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="has_selected l">
                            <p class="clear">已选（{{approverEmplyessList.length}}）
                                <a @click="approverEmplyessList=[]">清空</a>
                            </p>
                            <ul>
                                <li v-for="(item,index) in approverEmplyessList" :key="index+'e'">
                                    <img src="@/assets/images/icon_people.png">
                                    <span>{{item.employeeName}}</span>
                                    <img src="@/assets/images/cancel.png" @click="removeEle(approverEmplyessList,item)">
                                </li>
                            </ul>
                        </div>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="approverVisible = false">取 消</el-button>
                        <el-button type="primary" @click="sureApprover">确 定</el-button>
                    </span>
                </el-dialog> -->
                <!-- <el-dialog title="选择人员" :center="true" :show-close='false' append-to-body :visible.sync="approverVisible">
                    <div class="tiao_bumen">
                        <div class="xuanze">
                            <p class="tit">选择：</p>
                            <div class="xuanze_sousuo">
                                <i class="el-icon-search"></i>
                                <input type="text" placeholder="搜索" @keyup="keyu" v-model="text">
                            </div>
                            <div class="mianbaoxie">
                                <div v-for="(i,index) in mianbao_list" :key='index'  @click="mianbao_dian(i,index)">
                                    <p :class="mianbao_list.length-1==index?'p1':'p2'">{{i.name}}</p>
                                    <i class="el-icon-arrow-right"></i>
                                </div>
                            </div>
                            <div class="quanxuan"><input type="checkbox" @click="dianji_quanxuan" ref='quan'><span>全选</span></div>
                            <div class="nei_box">
                                <div class="liebiao">
                                    <ul>
                                        <li v-for="(i,index) in qiye_bumen_list" :key="index" @click="dianji_bumen(i)">
                                            <p style="margin-left:0.16rem;">{{i.dept_name}}</p>
                                            <img class="img1" :src="i.child_flag=='0'?require('../assets/chazi.png'):require('../assets/chazi_ok.png')" alt="">
                                        </li>
                                    </ul>
                                    <ul>
                                        <li v-for="(i,index) in qiye_renyuan_list" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
                                            <input ref="have" type="checkbox" @click="dianji_xuanren(i,$event,index)">
                                            <p>{{i.name}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="yixuan">
                            <p class="tit">已选：</p>
                            <div class="nei_box">
                                <div class="liebiao">
                                    <ul>
                                        <li v-for="(i,index) in qiye_renyuan_list_xuan" :key="index">
                                            <p>{{i.name}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="foote">
                        <p class="queding" @click="sureApprover">确定</p>
                        <p class="quxiao" @click="approverVisible=false">取消</p>
                    </div>
                </el-dialog> -->
                <el-dialog title="选择人员" :center="true" :show-close='false' append-to-body :visible.sync="approverVisible">
                    <div class="tiao_bumen">
                        <div class="xuanze">
                            <p class="tit">选择：</p>
                            <div class="xuanze_sousuo">
                                <i class="el-icon-search"></i>
                                <input type="text" placeholder="搜索" @keyup="keyu" v-model="text">
                            </div>
                            <div class="mianbaoxie">
                                <div v-for="(i,index) in mianbao_list" :key='index'  @click="mianbao_dian(i,index)">
                                    <p :class="mianbao_list.length-1==index?'p1':'p2'">{{i.name}}</p>
                                    <i class="el-icon-arrow-right"></i>
                                </div>
                            </div>
                            <div class="quanxuan">
                              
                                <!-- <input type="checkbox" @click="dianji_quanxuan" ref='quan'> -->
                                <img @click="dianji_quanxuan" :src="renyuan_quanxuan?require('../assets/okblue.png'):require('../assets/noccc.png')" alt="">
                                <span>全选</span>
                                
                            </div>
                            <div class="nei_box">
                                <div class="liebiao">
                                    <ul>
                                        <li v-for="(i,index) in qiye_bumen_list" :key="index" @click="dianji_bumen(i)">
                                            <p style="margin-left:0.16rem;">{{i.dept_name}}</p>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li v-for="(i,index) in qiye_renyuan_list" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
                                            <!-- <input ref="have" type="checkbox" @click="dianji_xuanren(i,$event,index)"> -->
                                            <img class="img2" @click="dianji_xuanren(i)" :src="i.zhi?require('../assets/okblue.png'):require('../assets/noccc.png')" alt="">
                                            <p>{{i.name}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="yixuan">
                            <p class="tit">已选：</p>
                            <div class="nei_box">
                                <div class="liebiao">
                                    <ul>
                                        <li v-for="(i,index) in qiye_renyuan_list_xuan" :key="index">
                                            <p>{{i.name}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="foote">
                        <p class="queding" @click="sureApprover">确定</p>
                        <p class="quxiao" @click="approverVisible=false">取消</p>
                    </div>
                </el-dialog>
                 <!-- <el-dialog title="选择角色" :visible.sync="approverRoleVisible" width="6rem" append-to-body class="promoter_person">
                    <div class="person_body clear">
                        <div class="person_tree l">
                            <input type="text" placeholder="搜索角色" v-model="approverRoleSearchName" @input="getDebounceData($event,2)">
                            <ul>
                                <li v-for="(item,index) in roles" :key="index+'b'" class="check_box not"
                                    :class="toggleClass(roleList,item,'roleId')&&'active'" @click="roleList=[item]">
                                    <a :title="item.description"><img src="@/assets/images/icon_role.png">{{item.roleName}}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="has_selected l">
                            <p class="clear">已选（{{roleList.length}}）
                                <a @click="roleList=[]">清空</a>
                            </p>
                            <ul>
                                <li v-for="(item,index) in roleList" :key="index+'e'">
                                    <img src="@/assets/images/icon_role.png">
                                    <span>{{item.roleName}}</span>
                                    <img src="@/assets/images/cancel.png" @click="removeEle(roleList,item,'roleId')">
                                </li>
                            </ul>
                        </div>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="approverRoleVisible = false">取 消</el-button>
                        <el-button type="primary" @click="sureApprover">确 定</el-button>
                    </span>
                </el-dialog> -->
                <el-dialog title="选择角色" :center="true" :show-close='false' append-to-body :visible.sync="approverRoleVisible">
                    <div class="tiao_bumen">
                        <div class="xuanze">
                            <p class="tit">选择：</p>
                            <!-- <div class="xuanze_sousuo">
                                <i class="el-icon-search"></i>
                                <input type="text" placeholder="搜索" @keyup="keyu_jue" v-model="juese_sousuo">
                            </div> -->
                            <div class="mianbaoxie">
                                <div v-for="(i,index) in mianbao_list_jue" :key='index'  @click="mianbao_dian_jue(i,index)">
                                    <p :class="mianbao_list_jue.length-1==index?'p1':'p2'">{{i.name}}</p>
                                    <i class="el-icon-arrow-right"></i>
                                </div>
                            </div>
                            <!-- <div class="quanxuan"><input type="checkbox" @click="dianji_quanxuan_jue" ref='quan_jue'><span>全选</span></div> -->
                            <div class="nei_box">
                                <div class="liebiao">
                                    <ul>
                                        <li v-for="(i,index) in qiye_juese_list" :key="index" @click="dianji_bumen_jue(i)">
                                            <p style="margin-left:0.16rem;">{{i.role_group_name}}</p>
                                            <!-- <img class="img1" :src="i.child_flag=='0'?require('../assets/chazi.png'):require('../assets/chazi_ok.png')" alt=""> -->
                                        </li>
                                    </ul>
                                    <ul>
                                        <li v-for="(i,index) in qiye_juesery_list" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
                                            <!-- <input ref="have_jue" type="checkbox" @click="dianji_xuanren_jue(i,$event,index)"> -->
                                            <img class="img2" @click="dianji_xuanren_jue(i,index)" :src="juese_num==index?require('../assets/okblue.png'):require('../assets/noccc.png')" alt="">
                                            <p>{{i.role_name}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="yixuan">
                            <p class="tit">已选：</p>
                            <div class="nei_box">
                                <div class="liebiao">
                                    <ul>
                                        <li v-for="(i,index) in qiye_juesery_list_xuan" :key="index">
                                            <p>{{i.role_name}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="foote">
                        <p class="queding" @click="sureApprover_jue">确定</p>
                        <p class="quxiao" @click="approverRoleVisible=false">取消</p>
                    </div>
                </el-dialog>
            </div>
        </el-drawer>
        <el-drawer title="抄送人设置" :visible.sync="copyerDrawer" direction="rtl" class="set_copyer" size="5.5rem" :before-close="saveCopyer">
            <div class="demo-drawer__content">
                <div class="drawer_content">
                    <div class="approver_content">
                        <el-radio-group v-model="copyerConfig.settype" class="clear" @change="changeType_chaosong">
                            <el-radio :label="1">指定成员</el-radio>
                            <el-radio style="margin-left:0.1rem;" :label="8">角色</el-radio>
                        </el-radio-group>
                    </div>
                    <div class="copyer_content drawer_content">
                        <el-checkbox-group v-model="ccSelfSelectFlag" class="clear">
                            <el-checkbox :label="1">允许发起人自选抄送人</el-checkbox>
                        </el-checkbox-group>
                    </div>
                    <div class="approver_manager">
                        <el-button type="primary" @click="addCopyer" v-if="copyerConfig.settype==1">添加/修改成员</el-button>
                        <el-button type="primary" @click="addRoleApprover_chaosong" v-if="copyerConfig.settype==8">添加/修改角色</el-button>
                        <p class="selected_list" style="margin-top:0.2rem;" v-if="copyerConfig.settype==1||copyerConfig.settype==8||copyerConfig.settype==4">
                            <span v-for="(item,index) in copyerConfig.nodeUserList" :key="index">{{item.name}}
                                <img src="@/assets/images/add-close1.png" @click="removeEle(copyerConfig.nodeUserList,item,'targetId')">
                            </span>
                            <a v-if="copyerConfig.nodeUserList.length!=0" @click="copyerConfig.nodeUserList=[];qiye_juesery_list_xuan=[];qiye_renyuan_list_xuan=[]">清除</a>
                        </p>
                    </div>
                </div>
                <div class="demo-drawer__footer clear">
                    <el-button type="primary" @click="saveCopyer">确 定</el-button>
                    <el-button @click="copyerDrawer = false">取 消</el-button>
                </div>
                <el-dialog title="选择人员" :center="true" :show-close='false' append-to-body :visible.sync="copyerVisible">
                    <div class="tiao_bumen">
                        <div class="xuanze">
                            <p class="tit">选择：</p>
                            <div class="xuanze_sousuo">
                                <i class="el-icon-search"></i>
                                <input type="text" placeholder="搜索" @keyup="keyu" v-model="text">
                            </div>
                            <div class="mianbaoxie">
                                <div v-for="(i,index) in mianbao_list" :key='index'  @click="mianbao_dian(i,index)">
                                    <p :class="mianbao_list.length-1==index?'p1':'p2'">{{i.name}}</p>
                                    <i class="el-icon-arrow-right"></i>
                                </div>
                            </div>
                            <div class="quanxuan" v-if="text.length!=0">
                                <img @click="dianji_quanxuan" :src="renyuan_quanxuan?require('../assets/okblue.png'):require('../assets/noccc.png')" alt="">
                                <span>全选</span>
                            </div>
                            <div class="nei_box">
                                <div class="liebiao">
                                    <ul>
                                        <li v-for="(i,index) in qiye_bumen_list" :key="index" @click="dianji_bumen(i)">
                                            <p style="margin-left:0.16rem;">{{i.dept_name}}</p>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li v-for="(i,index) in qiye_renyuan_list" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
                                            <img class="img2" @click="dianji_xuanren(i)" :src="i.zhi?require('../assets/okblue.png'):require('../assets/noccc.png')" alt="">
                                            <p>{{i.name}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="yixuan">
                            <p class="tit">已选：</p>
                            <div class="nei_box">
                                <div class="liebiao">
                                    <ul>
                                        <li v-for="(i,index) in qiye_renyuan_list_xuan" :key="index">
                                            <p>{{i.name}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="foote">
                        <p class="queding" @click="sureCopyer">确定</p>
                        <p class="quxiao" @click="copyerVisible=false">取消</p>
                    </div>
                </el-dialog>
                <el-dialog title="选择角色" :center="true" :show-close='false' append-to-body :visible.sync="approverRoleVisible_chaosong">
                    <div class="tiao_bumen">
                        <div class="xuanze">
                            <p class="tit">选择：</p>
                            <!-- <div class="xuanze_sousuo">
                                <i class="el-icon-search"></i>
                                <input type="text" placeholder="搜索" @keyup="keyu_jue" v-model="juese_sousuo">
                            </div> -->
                            <div class="mianbaoxie">
                                <div v-for="(i,index) in mianbao_list_jue" :key='index'  @click="mianbao_dian_jue(i,index)">
                                    <p :class="mianbao_list_jue.length-1==index?'p1':'p2'">{{i.name}}</p>
                                    <i class="el-icon-arrow-right"></i>
                                </div>
                            </div>
                            <!-- <div class="quanxuan"><input type="checkbox" @click="dianji_quanxuan_jue" ref='quan_jue'><span>全选</span></div> -->
                            <div class="nei_box">
                                <div class="liebiao">
                                    <ul>
                                        <li v-for="(i,index) in qiye_juese_list" :key="index" @click="dianji_bumen_jue(i)">
                                            <p style="margin-left:0.16rem;">{{i.role_group_name}}</p>
                                            <!-- <img class="img1" :src="i.child_flag=='0'?require('../assets/chazi.png'):require('../assets/chazi_ok.png')" alt=""> -->
                                        </li>
                                    </ul>
                                    <ul>
                                        <li v-for="(i,index) in qiye_juesery_list" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
                                            <!-- <input ref="have_jue" type="checkbox" @click="dianji_xuanren_jue(i,$event,index)"> -->
                                            <img class="img2" @click="dianji_xuanren_jue(i,index)" :src="juese_num==index?require('../assets/okblue.png'):require('../assets/noccc.png')" alt="">
                                            <p>{{i.role_name}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="yixuan">
                            <p class="tit">已选：</p>
                            <div class="nei_box">
                                <div class="liebiao">
                                    <ul>
                                        <li v-for="(i,index) in qiye_juesery_list_xuan" :key="index">
                                            <p>{{i.role_name}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="foote">
                        <p class="queding" @click="sureApprover_jue_chaosong">确定</p>
                        <p class="quxiao" @click="approverRoleVisible_chaosong=false">取消</p>
                    </div>
                </el-dialog>
            </div>
            <!-- <div class="demo-drawer__content">
                <div class="copyer_content drawer_content">
                    <el-button type="primary" @click="addCopyer">添加成员</el-button>
                    <p class="selected_list">
                        <span v-for="(item,index) in copyerConfig.nodeUserList" :key="index">{{item.name}}
                            <img src="@/assets/images/add-close1.png" @click="removeEle(copyerConfig.nodeUserList,item,'targetId')">
                        </span>
                        <a v-if="copyerConfig.nodeUserList&&copyerConfig.nodeUserList.length!=0" @click="copyerConfig.nodeUserList=[]">清除</a>
                    </p>
                    <el-checkbox-group v-model="ccSelfSelectFlag" class="clear">
                        <el-checkbox :label="1">允许发起人自选抄送人</el-checkbox>
                    </el-checkbox-group>
                </div>
                <div class="demo-drawer__footer clear">
                    <el-button type="primary" @click="saveCopyer">确 定</el-button>
                    <el-button @click="copyerDrawer = false">取 消</el-button>
                </div>
                <el-dialog title="选择人员" :center="true" :show-close='false' append-to-body :visible.sync="copyerVisible">
                    <div class="tiao_bumen">
                        <div class="xuanze">
                            <p class="tit">选择：</p>
                            <div class="xuanze_sousuo">
                                <i class="el-icon-search"></i>
                                <input type="text" placeholder="搜索" @keyup="keyu" v-model="text">
                            </div>
                            <div class="mianbaoxie">
                                <div v-for="(i,index) in mianbao_list" :key='index'  @click="mianbao_dian(i,index)">
                                    <p :class="mianbao_list.length-1==index?'p1':'p2'">{{i.name}}</p>
                                    <i class="el-icon-arrow-right"></i>
                                </div>
                            </div>
                            <div class="quanxuan" v-if="qiye_renyuan_list.length!=0">
                                <img @click="dianji_quanxuan" :src="renyuan_quanxuan?require('../assets/okblue.png'):require('../assets/noccc.png')" alt="">
                                <span>全选</span>
                            </div>
                            <div class="nei_box">
                                <div class="liebiao">
                                    <ul>
                                        <li v-for="(i,index) in qiye_bumen_list" :key="index" @click="dianji_bumen(i)">
                                            <p style="margin-left:0.16rem;">{{i.dept_name}}</p>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li v-for="(i,index) in qiye_renyuan_list" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
                                            <img class="img2" @click="dianji_xuanren(i)" :src="i.zhi?require('../assets/okblue.png'):require('../assets/noccc.png')" alt="">
                                            <p>{{i.name}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="yixuan">
                            <p class="tit">已选：</p>
                            <div class="nei_box">
                                <div class="liebiao">
                                    <ul>
                                        <li v-for="(i,index) in qiye_renyuan_list_xuan" :key="index">
                                            <p>{{i.name}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="foote">
                        <p class="queding" @click="sureCopyer">确定</p>
                        <p class="quxiao" @click="copyerVisible=false">取消</p>
                    </div>
                </el-dialog>
            </div> -->
        </el-drawer>
        <el-drawer title="归档人设置" :visible.sync="guidang_youce" direction="rtl" class="set_copyer" size="5.5rem" :before-close="guidang_queding">
            <div class="demo-drawer__content">
                <div class="copyer_content drawer_content">
                    <el-button type="primary" @click="dakai_guidang_renyuan">添加成员</el-button>
                    <p class="selected_list">
                        <span v-for="(item,index) in guidang.nodeUserList" :key="index">{{item.name}}
                            <img src="@/assets/images/add-close1.png" @click="removeEle(guidang.nodeUserList,item,'targetId')">
                        </span>
                        <a v-if="guidang.nodeUserList&&guidang.nodeUserList.length!=0" @click="guidang.nodeUserList=[]">清除</a>
                    </p>
                </div>
                <div class="demo-drawer__footer clear">
                    <el-button type="primary" @click="guidang_queding">确 定</el-button>
                    <el-button @click="guidang_youce = false">取 消</el-button>
                </div>
                <el-dialog title="选择人员" :center="true" :show-close='false' append-to-body :visible.sync="guidang_chengyuan_list">
                    <div class="tiao_bumen">
                        <div class="xuanze">
                            <p class="tit">选择：</p>
                            <div class="xuanze_sousuo">
                                <i class="el-icon-search"></i>
                                <input type="text" placeholder="搜索" @keyup="keyu" v-model="text">
                            </div>
                            <div class="mianbaoxie">
                                <div v-for="(i,index) in mianbao_list" :key='index'  @click="mianbao_dian(i,index)">
                                    <p :class="mianbao_list.length-1==index?'p1':'p2'">{{i.name}}</p>
                                    <i class="el-icon-arrow-right"></i>
                                </div>
                            </div>
                            <div class="quanxuan"><input type="checkbox" @click="dianji_quanxuan" ref='quan'><span>全选</span></div>
                            <div class="nei_box">
                                <div class="liebiao">
                                    <ul>
                                        <li v-for="(i,index) in qiye_bumen_list" :key="index" @click="dianji_bumen(i)">
                                            <p style="margin-left:0.16rem;">{{i.dept_name}}</p>
                                            <!-- <img class="img1" :src="i.child_flag=='0'?require('../assets/chazi.png'):require('../assets/chazi_ok.png')" alt=""> -->
                                        </li>
                                    </ul>
                                    <ul>
                                        <li v-for="(i,index) in qiye_renyuan_list" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
                                            <input ref="have" type="checkbox" @click="dianji_xuanren(i,$event,index)">
                                            <p>{{i.name}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="yixuan">
                            <p class="tit">已选：</p>
                            <div class="nei_box">
                                <div class="liebiao">
                                    <ul>
                                        <li v-for="(i,index) in qiye_renyuan_list_xuan" :key="index">
                                            <p>{{i.name}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="foote">
                        <p class="queding" @click="guidang_chengyuan_sure">确定</p>
                        <p class="quxiao" @click="guidang_chengyuan_list=false">取消</p>
                    </div>
                </el-dialog>
            </div>
        </el-drawer>
        <el-drawer title="条件设置" :visible.sync="conditionDrawer" direction="rtl" class="condition_copyer" size="5.5rem" :before-close="saveCondition">
            <select class="youxian_ji" v-model="conditionConfig.priorityLevel">
                <option v-for="item in conditionsConfig.conditionNodes.length" :value="item" :key="item">优先级{{item}}</option>
            </select>
            <div class="demo-drawer__content">
                <div class="condition_content drawer_content">
                    <p class="tip">当审批单同时满足以下条件时进入此流程</p>
                    <ul>
                        <li v-for="(item,index) in conditionConfig.conditionList" :key="index">
                            <!-- {{conditionConfig.conditionList}} -->
                            <span class="ellipsis">{{item.type==1?'发起人':item.showName}}：</span>
                            <div v-if="item.type==1">
                                <p :class="conditionConfig.nodeUserList.length > 0?'selected_list':''" @click.self="addConditionRole" style="cursor:text">
                                    <span v-for="(item1,index1) in conditionConfig.nodeUserList" :key="index1">
                                        {{item1.name}}<img src="@/assets/images/add-close1.png" @click="removeEle(conditionConfig.nodeUserList,item1,'targetId')">
                                    </span>
                                    <input type="text" placeholder="请选择具体人员" v-if="conditionConfig.nodeUserList.length == 0" @click="addConditionRole">
                                </p>
                            </div>
                            <!-- <div v-else-if="item.columnType == 'String' && item.showType == 3">
                                <p class="check_box">
                                    <a :class="toggleStrClass(item,item1.key)&&'active'" @click="toStrChecked(item,item1.key)"
                                    v-for="(item1,index1) in JSON.parse(item.fixedDownBoxValue)" :key="index1">{{item1.value}}</a>
                                </p>
                            </div> -->
                            <div v-if="item.type==2">
                                <p style="display:flex;">
                                    <select v-model="item.optType" :style="'width:'+(item.optType==6?370:100)+'px'" @change="changeOptType(item)">
                                        <option value="1">小于</option>
                                        <option value="2">大于</option>
                                        <option value="3">小于等于</option>
                                        <option value="4">等于</option>
                                        <option value="5">大于等于</option>
                                        <option value="6">介于两个数之间</option>
                                    </select>
                                    <input v-if="item.optType!=6" type="text" :placeholder="'请输入'+item.showName" v-enter-number="2" v-model="item.zdy1">
                                </p>
                                <p style="display:flex;align-items: center;" v-if="item.optType==6">
                                    <input type="text" style="width:0.75rem;" class="mr_10" v-enter-number="2" v-model="item.zdy1">
                                    <select style="width:0.6rem;" v-model="item.opt1">
                                        <option value="1">&lt;</option>
                                        <option value="2">≤</option>
                                    </select>
                                    <span class="ellipsis" style="display:inline-block;width:0.6rem;vertical-align: text-bottom;">{{item.showName}}</span>
                                    <select style="width:0.6rem;" class="ml_10" v-model="item.opt2">
                                        <option value="1">&lt;</option>
                                        <option value="2">≤</option>
                                    </select>
                                    <input type="text" style="width:0.75rem;" v-enter-number="2" v-model="item.zdy2">
                                </p>
                            </div>
                            <div v-if="item.type==3">
                                <p style="display:flex;">
                                    <select v-model="item.optType" :style="'width:'+(item.optType==6?370:100)+'px'" @change="changeOptType(item)">
                                        <option value="1">属于</option>
                                    </select>
                                    <el-select v-model="item.value_list" class="tiaojian_dan_duoxuan" multiple placeholder="请选择">
                                        <el-option
                                        v-for="(item,index) in item.defaultOptions"
                                        :key="index"
                                        :label="item.text"
                                        :value="item.text">
                                        </el-option>
                                    </el-select>
                                </p>
                                <!-- <p style="display:flex;align-items: center;" v-if="item.optType==6">
                                    <input type="text" style="width:0.75rem;" class="mr_10" v-enter-number="2" v-model="item.zdy1">
                                    <select style="width:0.6rem;" v-model="item.opt1">
                                        <option value="1">&lt;</option>
                                        <option value="2">≤</option>
                                    </select>
                                    <span class="ellipsis" style="display:inline-block;width:0.6rem;vertical-align: text-bottom;">{{item.showName}}</span>
                                    <select style="width:0.6rem;" class="ml_10" v-model="item.opt2">
                                        <option value="1">&lt;</option>
                                        <option value="2">≤</option>
                                    </select>
                                    <input type="text" style="width:0.75rem;" v-enter-number="2" v-model="item.zdy2">
                                </p> -->
                            </div>
                            <div v-if="item.type==4">
                                <p style="display:flex;">
                                    <select v-model="item.optType" :style="'width:'+(item.optType==6?370:100)+'px'" @change="changeOptType(item)">
                                        <option value="1">完全等于</option>
                                        <option value="2">包含任意</option>
                                    </select>
                                    <el-select v-model="item.value_list" class="tiaojian_dan_duoxuan" multiple placeholder="请选择">
                                        <el-option
                                        v-for="(item,index) in item.defaultOptions"
                                        :key="index"
                                        :label="item.text"
                                        :value="item.text">
                                        </el-option>
                                    </el-select>
                                </p>
                            </div>
                            <a v-if="item.type==1" @click="conditionConfig.nodeUserList= [];removeEle(conditionConfig.conditionList,item,'idx')">删除</a>
                            <a v-if="item.type==2||item.type==3||item.type==4" @click="removeEle(conditionConfig.conditionList,item,'idx')">删除</a>
                        </li>
                    </ul>
                    <el-button type="primary" @click="addCondition">添加条件</el-button>
                    <el-dialog title="选择条件" :visible.sync="conditionVisible" width="4.8rem" append-to-body class="condition_list">
                        <p>请选择用来区分审批流程的条件字段</p>
                        <p class="check_box">
                            <a :class="toggleClass(conditionList,{idx:'faqi_ren'},'idx')&&'active'" @click="toChecked(conditionList,{idx:'faqi_ren'},'idx')">发起人</a>
                            <a v-for="(item,index) in conditions" :key="index" :class="toggleClass(conditionList,item,'idx')&&'active'"
                            @click="toChecked(conditionList,item,'idx')">{{item.defaultLable}}</a>
                        </p>
                        <p v-if="conditions_wei.length!=0">非必填条件不能用来区分流程，如需使用请前往表单设置</p>
                        <p class="check_box">
                            <!-- <el-tooltip class="item" effect="dark" content="勾选后该字段将被设为提交人必填，且审批人不可编辑" placement="top"> -->
                                <a v-for="(item,index) in conditions_wei" :key="index" :class="toggleClass(conditionList,item,'idx')&&'active'"
                                @click="toChecked_wei(index,item)">{{item.defaultLable}}</a>
                            <!-- </el-tooltip> -->
                        </p>
                        <span slot="footer" class="dialog-footer">
                            <el-button @click="conditionVisible = false">取 消</el-button>
                            <el-button type="primary" @click="sureCondition">确 定</el-button>
                        </span>
                    </el-dialog>
                </div>
                <!-- <el-dialog title="选择成员" :visible.sync="conditionRoleVisible" width="6rem" append-to-body class="promoter_person">
                    <div class="person_body clear">
                        <div class="person_tree l">
                            <input type="text" placeholder="搜索成员" v-model="conditionRoleSearchName" @input="getDebounceData($event,activeName)">
                            <el-tabs v-model="activeName" @tab-click="handleClick">
                                <el-tab-pane label="组织架构" name="1"></el-tab-pane>
                                <el-tab-pane label="角色列表" name="2"></el-tab-pane>
                            </el-tabs>
                            <p class="ellipsis tree_nav" v-if="activeName==1&&!conditionRoleSearchName">
                                <span @click="getDepartmentList(0)" class="ellipsis">天下</span>
                                <span v-for="(item,index) in departments.titleDepartments" class="ellipsis"
                                :key="index+'a'" @click="getDepartmentList(item.id)">{{item.departmentName}}</span>
                            </p>
                            <ul style="height: 3.6rem;" v-if="activeName==1">
                                <li v-for="(item,index) in departments.childDepartments" :key="index+'b'" class="check_box">
                                    <a :class="toggleClass(conditionDepartmentList,item)&&'active'" @click="toChecked(conditionDepartmentList,item)">
                                        <img src="@/assets/images/icon_file.png">{{item.departmentName}}</a>
                                    <i @click="getDepartmentList(item.id)">下级</i>
                                </li>
                                <li v-for="(item,index) in departments.employees" :key="index+'c'" class="check_box">
                                    <a :class="toggleClass(conditionEmployessList,item)&&'active'" @click="toChecked(conditionEmployessList,item)" :title="item.departmentNames">
                                        <img src="@/assets/images/icon_people.png">{{item.employeeName}}</a>
                                </li>
                            </ul>
                            <ul style="height: 3.6rem;" v-if="activeName==2">
                                <li v-for="(item,index) in roles" :key="index+'c'" class="check_box">
                                    <a :class="toggleClass(conditionRoleList,item,'roleId')&&'active'" @click="toChecked(conditionRoleList,item,'roleId')" :title="item.description">
                                        <img src="@/assets/images/icon_role.png">{{item.roleName}}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="has_selected l">
                            <p class="clear">已选（{{conditionDepartmentList.length+conditionEmployessList.length+conditionRoleList.length}}）
                                <a @click="conditionDepartmentList=[];conditionEmployessList=[];conditionRoleList=[]">清空</a>
                            </p>
                            <ul>
                                <li v-for="(item,index) in conditionRoleList" :key="index+'e'">
                                    <img src="@/assets/images/icon_role.png">
                                    <span>{{item.roleName}}</span>
                                    <img src="@/assets/images/cancel.png" @click="removeEle(conditionRoleList,item,'roleId')">
                                </li>
                                <li v-for="(item,index) in conditionDepartmentList" :key="index+'e1'">
                                    <img src="@/assets/images/icon_file.png">
                                    <span>{{item.departmentName}}</span>
                                    <img src="@/assets/images/cancel.png" @click="removeEle(conditionDepartmentList,item)">
                                </li>
                                <li v-for="(item,index) in conditionEmployessList" :key="index+'e2'">
                                    <img src="@/assets/images/icon_people.png">
                                    <span>{{item.employeeName}}</span>
                                    <img src="@/assets/images/cancel.png" @click="removeEle(conditionEmployessList,item)">
                                </li>
                            </ul>
                        </div>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="conditionRoleVisible = false">取 消</el-button>
                        <el-button type="primary" @click="sureConditionRole">确 定</el-button>
                    </span>
                </el-dialog> -->
                <el-dialog title="选择人员" :center="true" :show-close='false' append-to-body :visible.sync="conditionRoleVisible">
                    <div class="tiao_bumen">
                        <div class="xuanze" v-if="biao_list.length==0">
                            <p class="tit">选择：</p>
                            <el-tree
                                :data="faqi_ren_list"
                                class="tree_bumen"
                                show-checkbox
                                default-expand-all
                                node-key="id"
                                ref="tiaojian_faqi_ren_tree"
                                highlight-current
                                @check-change="xuanze_tiaojian_renyuan"
                                :props="defaultProps">
                            </el-tree>
                            <!-- <div class="xuanze_sousuo">
                                <i class="el-icon-search"></i>
                                <input type="text" placeholder="搜索" @keyup="keyu" v-model="text">
                            </div>
                            <div class="mianbaoxie">
                                <div v-for="(i,index) in mianbao_list" :key='index'  @click="mianbao_dian(i,index)">
                                    <p :class="mianbao_list.length-1==index?'p1':'p2'">{{i.name}}</p>
                                    <i class="el-icon-arrow-right"></i>
                                </div>
                            </div>
                            <div class="quanxuan"><input type="checkbox" @click="dianji_quanxuan" ref='quan'><span>全选</span></div>
                            <div class="nei_box">
                                <div class="liebiao">
                                    <ul>
                                        <li v-for="(i,index) in qiye_bumen_list" :key="index" @click="dianji_bumen(i)">
                                            <p style="margin-left:0.16rem;">{{i.dept_name}}</p>
                                            <img class="img1" :src="i.child_flag=='0'?require('../assets/chazi.png'):require('../assets/chazi_ok.png')" alt="">
                                        </li>
                                    </ul>
                                    <ul>
                                        <li v-for="(i,index) in qiye_renyuan_list" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
                                            <input ref="have" type="checkbox" @click="dianji_xuanren(i,$event,index)">
                                            <p>{{i.name}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div> -->
                        </div>
                        <div class="xuanze" v-if="biao_list.length!=0">
                            <p class="tit">选择：</p>
                            <div class="mianbaoxie">
                                <div v-for="(i,index) in mianbao_tiaojian_list" :key='index'  @click="mianbao_tiaojian_dian(i,index)">
                                    <p :class="mianbao_tiaojian_list.length-1==index?'p1':'p2'">{{i.name}}</p>
                                    <i class="el-icon-arrow-right"></i>
                                </div>
                            </div>
                            <div class="nei_box">
                                <div class="liebiao">
                                    <ul>
                                        <li v-for="(i,index) in faqi_bumen_list" @click="dianji_tiaojian_bumen(i)" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
                                            <img class="img2" @click.stop="dianji_xuanze_faqi_ren(i)" :src="i.zhi?require('../assets/kuang_duigou.png'):require('../assets/kaung_kongxin.png')">
                                            <p>{{i.name}}</p>
                                        </li>
                                    </ul>
                                </div>
                                <div class="liebiao">
                                    <ul>
                                        <li v-for="(i,index) in faqi_bumenren_list" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
                                            <img class="img2" @click.stop="dianji_xuanze_faqi_ren(i)" :src="i.zhi?require('../assets/kuang_duigou.png'):require('../assets/kaung_kongxin.png')">
                                            <p>{{i.name}}</p>
                                        </li>
                                    </ul>
                                </div>
                                <div class="liebiao">
                                    <ul>
                                        <li v-for="(i,index) in faqi_ren_list" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
                                            <img class="img2" @click.stop="dianji_xuanze_faqi_ren(i)" :src="i.zhi?require('../assets/kuang_duigou.png'):require('../assets/kaung_kongxin.png')">
                                            <p>{{i.name}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="yixuan">
                            <p class="tit">已选：</p>
                            <div class="nei_box">
                                <div class="liebiao" v-if="biao_list.length==0">
                                    <ul>
                                        <li v-for="(i,index) in qiye_renyuan_list_xuan" :key="index">
                                            <p>{{i.label}}</p>
                                        </li>
                                    </ul>
                                </div>
                                <div class="liebiao" v-if="biao_list.length!=0">
                                    <ul>
                                        <li v-for="(i,index) in qiye_renyuan_list_xuan" :key="index">
                                            <p>{{i.name}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="foote">
                        <p class="queding" @click="sureConditionRole">确定</p>
                        <p class="quxiao" @click="conditionRoleVisible=false">取消</p>
                    </div>
                </el-dialog>
                <div class="demo-drawer__footer clear">
                    <el-button type="primary" @click="saveCondition">确 定</el-button>
                    <el-button @click="conditionDrawer = false">取 消</el-button>
                </div>
            </div>
        </el-drawer>
        <nodeWrap v-if="nodeConfig.childNode && nodeConfig.childNode" :nodeConfig.sync="nodeConfig.childNode" :tableId="tableId"
        :isTried.sync="isTried" :directorMaxLevel="directorMaxLevel"></nodeWrap>
    </div>
</template>
<script>
import { query_user_list_of_role, query_dept_staff_tree_aim_not, query_role_info_list, query_role_group_info_list, get_staff_list_like, query_ent_dept_list, query_dept_staff_list } from '../api/api'
export default {
  props: ['nodeConfig', 'flowPermission', 'directorMaxLevel', 'isTried', 'tableId'],
  data () {
    return {
      placeholderList: ['发起人', '审批人', '抄送人', '归档人'],
      isInputList: [],
      isInput: false,
      promoterVisible: false, // 打开成员   是否
      promoterDrawer: false,
      departments: {},
      checkedDepartmentList: [],
      checkedEmployessList: [],
      promoterSearchName: '',
      flowPermission1: this.flowPermission,
      approverDrawer: false,
      approverVisible: false,
      approverRoleVisible: false,
      approverRoleVisible_chaosong: false,
      approverConfig: {},
      approverEmplyessList: [],
      approverSearchName: '',
      roles: [],
      roleList: [],
      approverRoleSearchName: '',
      copyerDrawer: false,
      guidang_youce: false, // 归档人的右侧展示或隐藏
      copyerVisible: false,
      copyerConfig: {},
      guidang: {}, // 归档人的右侧展示
      guidang_chengyuan_list: false,
      copyerSearchName: '',
      activeName: '1',
      copyerEmployessList: [],
      copyerRoleList: [],
      ccSelfSelectFlag: [],
      conditionDrawer: false,
      conditionVisible: false,
      conditionConfig: {},
      conditionsConfig: {
        conditionNodes: []
      },
      bPriorityLevel: '',
      conditions: [],
      conditions_wei: [],
      conditionList: [],
      conditionRoleVisible: false,
      conditionRoleSearchName: '',
      conditionDepartmentList: [],
      conditionEmployessList: [],
      conditionRoleList: [],
      promoterVisible: false,
      mianbao_list: [
        {
          name: '',
          dept_id: '0'
        }
      ],
      qiye_bumen_list: [],
      qiye_renyuan_list: [],
      qiye_renyuan_list_xuan: [],
      ziguanliyuan_bei: [],
      renyuan_quanxian_list: [],
      text: '',
      // 角色用到的data
      mianbao_list_jue: [
        {
          name: '',
          dept_id: '0'
        }
      ],
      qiye_juese_list: [],
      qiye_juesery_list: [],
      qiye_juesery_list_xuan: [],
      juesezgly_bei: [],
      juese_quanxian_list: [],
      juese_sousuo: '',
      defaultProps: {
        children: 'children',
        label: 'label',
        id: 'id'
      },
      faqi_ren_list: [],

      // 4.9新构
      renyuan_quanxuan: false,
      shenpi_juese_list: [],
      juese_num: -1,
      biao_list: [], // 发起人list
      // juese_list:[],
      // juese_lista:[],
      // juese_zu:[]
      mianbao_tiaojian_list: [
        {
          name: '',
          dept_id: '0'
        }
      ],
      faqi_bumen_list: [],
      faqi_bumenren_list: [],
      biaodan_quanxian_list: ['设置审批人', '表单操作权限'],
      biaodan_quanxian_num: '0',
      kongjian_quanxian_list: [],
      ke_bianji: false,
      zhidu: true,
      yincang: false,
      tiaojian_kongjian_cunzai: []
    }
  },
  created () {
    this.mianbao_list[0].name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
    this.mianbao_list_jue[0].name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
    this.mianbao_tiaojian_list[0].name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
  },
  mounted () {
    if (this.nodeConfig.type == 1) {
      this.nodeConfig.error = !this.setApproverStr(this.nodeConfig)
    } else if (this.nodeConfig.type == 2) {
      this.nodeConfig.error = !this.copyerStr(this.nodeConfig)
    } else if (this.nodeConfig.type == 4) {
      for (var i = 0; i < this.nodeConfig.conditionNodes.length; i++) {
        this.nodeConfig.conditionNodes[i].error = this.conditionStr(this.nodeConfig.conditionNodes[i], i) == '请设置条件' && i != this.nodeConfig.conditionNodes.length - 1
      }
    }
    console.log(this.flowPermission)
  },
  watch: {
    approverRoleVisible () {
      if (!this.approverRoleVisible) {
        this.qiye_juesery_list = []
      }
    },
    approverRoleVisible_chaosong () {
      if (!this.approverRoleVisible) {
        this.qiye_juesery_list = []
      }
    },
    // qiye_juesery_list_xuan:{
    //     handler(newValue,oldValue){
    //         if(newValue.length!=0){
    //             this.$refs.quan_jue.checked=this.qiye_juesery_list.length==newValue.length?true:false
    //         }else{
    //             this.$refs.quan_jue.checked=false
    //         }
    //     },
    //     deep:true
    // },
    // qiye_juesery_list:{
    //     handler(newValue,oldValue){
    //         if(newValue.length!=0){
    //             for(let i=0;i<this.qiye_juesery_list_xuan.length;i++){
    //                 newValue.map((item,index) =>{
    //                     if(this.qiye_juesery_list_xuan[i].role_id==item.role_id){
    //                         console.log(index)
    //                         setTimeout(() => {
    //                             this.$refs.have_jue[index].checked=true
    //                         }, 0)
    //                     }
    //                 })
    //             }
    //         }
    //     },
    //     deep:true
    // },
    conditionRoleVisible () {
      if (!this.conditionRoleVisible) {
        this.mianbao_tiaojian_list = [
          {
            name: JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name,
            dept_id: '0'
          }
        ]
      }
    },
    //
    approverDrawer () {
      if (!this.approverDrawer) {
        this.qiye_juesery_list_xuan = []
        this.qiye_renyuan_list_xuan = []
        this.qiye_juese_list = []
        this.qiye_juesery_list = []
      }
    },
    // 监听表单操作权限
    kongjian_quanxian_list: {
      handler (newValue, oldValue) {
        this.panduan_ziduan_quanxuan()
      },
      deep: true
    }
  },
  methods: {
    clickEvent (index) {
      if (index || index === 0) {
        this.$set(this.isInputList, index, true)
      } else {
        this.isInput = true
      }
    },
    blurEvent (index) {
      if (index || index === 0) {
        this.$set(this.isInputList, index, false)
        this.nodeConfig.conditionNodes[index].nodeName = this.nodeConfig.conditionNodes[index].nodeName ? this.nodeConfig.conditionNodes[index].nodeName : '条件'
      } else {
        this.isInput = false
        this.nodeConfig.nodeName = this.nodeConfig.nodeName ? this.nodeConfig.nodeName : this.placeholderList[this.nodeConfig.type]
      }
    },
    conditionStr (item, index) {
      var { conditionList, nodeUserList } = item
      if (conditionList.length == 0) {
        return (index == this.nodeConfig.conditionNodes.length - 1) && this.nodeConfig.conditionNodes[0].conditionList.length != 0 ? '其他条件进入此流程' : '请设置条件'
      } else {
        let str = ''
        for (var i = 0; i < conditionList.length; i++) {
          var { idx, type, showType, showName, optType, zdy1, opt1, zdy2, opt2, fixedDownBoxValue } = conditionList[i]
          if (idx == 'faqi_ren') {
            if (nodeUserList.length != 0) {
              str += '发起人属于：'
              str += nodeUserList.map(item => { return item.name }).join('或') + ' 并且 '
            }
          }
          // if (columnType == "String" && showType == "3") {
          //     if (zdy1) {
          //         str += showName + '属于：' + this.dealStr(zdy1, JSON.parse(fixedDownBoxValue)) + " 并且 "
          //     }
          // }
          if (type == '2') {
            if (optType != 6 && zdy1) {
              var optTypeStr = ['', '<', '>', '≤', '=', '≥'][optType]
              str += `${showName} ${optTypeStr} ${zdy1} 并且 `
            } else if (optType == 6 && zdy1 && zdy2) {
              var optTypeStr = ['', '<', '≤'][opt1]
              var optTypeStr2 = ['', '<', '≤'][opt2]
              str += `${zdy1} ${optTypeStr} ${showName} ${optTypeStr2} ${zdy2} 并且 `
            }
          }
          if (type == 3) {
            str += `${showName} 属于 ` + conditionList[i].value_list.join(',') + ' 并且 '
          }
          if (type == 4) {
            var optTypeStr = ['', '完全等于', '包含任意'][optType]
            str += `${showName} ${optTypeStr} ` + conditionList[i].value_list.join(',') + ' 并且 '
          }
        }
        //
        return str ? str.substring(0, str.length - 4) : '请设置条件'
      }
    },
    dealStr (str, obj) {
      const arr = []
      const list = str.split(',')
      for (var elem in obj) {
        list.map(item => {
          if (item == elem) {
            arr.push(obj[elem].value)
          }
        })
      }
      return arr.join('或')
    },
    // 所有自定义控件         敢动这段代码  死
    zi_dingyi_kongjian () {
      const date = this.$bendi_naqu_cunchu('mlbb_biaodan_sheji', '暂时')
      console.log(this.nodeConfig)
      const yiyou_list = this.nodeConfig.edit_list != undefined ? this.nodeConfig.edit_list : []
      this.tiaojian_kongjian_cunzai = []
      this.tiaojian_li_kongjian()
      console.log(date)
      if (date != undefined) {
        for (let i = 0; i < date.length; i++) {
          if (date[i].componentName == 'assappform' || date[i].componentName == 'product' || date[i].componentName == 'calcform') {
            date.splice(i, 1)
            i = i - 1
          } else if (date[i].componentName == 'tablefield') {
            for (let a = 0; a < date[i].components.length; a++) {
              if (date[i].components[a].componentName == 'product' || date[i].components[a].componentName == 'calcform') {
                date[i].components.splice(a, 1)
                a = a - 1
              }
            }
          }
        }
      }
      setTimeout(() => {
        const list = []
        console.log(this.nodeConfig.edit_list)
        date.forEach(item => {
          yiyou_list.forEach(ite => {
            if (this.tiaojian_kongjian_cunzai.length != 0) {
              this.tiaojian_kongjian_cunzai.forEach(it => {
                if (it.idx == ite.idx && item.idx == it.idx && ite.type == 1) {
                  this.$set(ite, 'type', '2')
                }
              })
            }
            if (ite.idx == item.idx) {
              item.type = ite.type
            }
            if (item.componentName == 'tablefield') {
              item.components.forEach(it => {
                if (ite.idx == it.idx) {
                  it.type = ite.type
                }
              })
            }
          })
          if (item.siyou != undefined && item.siyou == 22 && item.componentName != 'textnote' && item.componentName != 'tablefield') {
            this.$set(item, 'parent_idx', false)
            this.$set(item, 'kongjian_name', item.defaultLable)
            this.$set(item, 'mingxi_kuang', false)
            this.$set(item, 'type', item.type != undefined ? item.type : '2')
            list.push(item)
          }
          if (item.siyou != undefined && item.siyou == 22 && item.componentName == 'textnote') {
            this.$set(item, 'parent_idx', false)
            this.$set(item, 'kongjian_name', item.defaultProps)
            this.$set(item, 'mingxi_kuang', false)
            this.$set(item, 'type', item.type != undefined ? item.type : '2')
            list.push(item)
          }
          if (item.siyou != undefined && item.siyou == 22 && item.componentName == 'tablefield') {
            this.$set(item, 'parent_idx', false)
            this.$set(item, 'kongjian_name', item.defaultLable)
            this.$set(item, 'mingxi_kuang', true)
            this.$set(item, 'type', item.type != undefined ? item.type : '2')
            list.push(item)
          }
          if (item.componentName == 'tablefield') {
            item.components.forEach(ite => {
              if (ite.siyou != undefined && ite.siyou == 22 && ite.componentName != 'textnote') {
                this.$set(ite, 'parent_idx', item.idx)
                this.$set(ite, 'mingxi_kuang', false)
                this.$set(ite, 'kongjian_name', item.defaultLable + '.' + ite.defaultLable)
                this.$set(ite, 'type', ite.type != undefined ? ite.type : '2')
                list.push(ite)
              }
              if (ite.siyou != undefined && ite.siyou == 22 && ite.componentName == 'textnote') {
                this.$set(ite, 'parent_idx', item.idx)
                this.$set(ite, 'mingxi_kuang', false)
                this.$set(ite, 'kongjian_name', item.defaultProps + '.' + ite.defaultProps)
                this.$set(ite, 'type', ite.type != undefined ? ite.type : '2')
                list.push(ite)
              }
            })
          }
        })
        console.log(list)
        // list.forEach(item=>{
        //     yiyou_list.forEach(ite=>{
        //         if(ite.idx==item.idx){
        //             item.type==ite.type
        //         }
        //     })
        // })
        this.tiaojian_kongjian_cunzai = []
        this.tiaojian_li_kongjian()
        console.log(this.tiaojian_kongjian_cunzai)
        this.tiaojian_kongjian_cunzai.forEach(item => {
          list.forEach(ite => {
            if (item.idx == ite.idx) {
              this.$set(ite, 'bianji', true)
            }
          })
        })
        this.kongjian_quanxian_list = list
      }, 0)

      // kongjian_quanxian_list
    },
    dianji_xuanze_lie (id) {
      this.kongjian_quanxian_list.forEach(item => {
        if (id == 1) {
          if (item.bianji == undefined) {
            this.$set(item, 'type', id)
          }
        } else {
          this.$set(item, 'type', id)
        }
      })
    },
    // 紫点是否全部选中
    panduan_ziduan_quanxuan () {
      const bianji_list = []
      const keshi_list = []
      const yincang_list = []
      const tiaojian_list = []
      this.approverConfig.edit_list = []
      this.kongjian_quanxian_list.forEach(item => {
        if (item.bianji && item.idx != 'faqi_ren') {
          tiaojian_list.push('111')
        }
        if (item.type == 1) {
          bianji_list.push(item)
        } else if (item.type == 2) {
          keshi_list.push(item)
        } else if (item.type == 3) {
          yincang_list.push(item)
        }
        this.approverConfig.edit_list.push({
          idx: item.idx,
          type: item.type,
          parent_idx: item.parent_idx,
          name: item.kongjian_name,
          mingxi_kuang: item.mingxi_kuang
        })
      })
      if (this.kongjian_quanxian_list.length != 0) {
        if (Number(this.kongjian_quanxian_list.length) - Number(tiaojian_list.length) != 0 && bianji_list.length == Number(this.kongjian_quanxian_list.length) - Number(tiaojian_list.length)) {
          this.ke_bianji = true
        } else {
          this.ke_bianji = false
        }
        if (keshi_list.length == this.kongjian_quanxian_list.length) {
          this.zhidu = true
        } else {
          this.zhidu = false
        }
        if (yincang_list.length == this.kongjian_quanxian_list.length) {
          this.yincang = true
        } else {
          this.yincang = false
        }
      } else {
        this.approverConfig.edit_list = []
      }
    },
    // 条件里的控件
    tiaojian_li_kongjian () {
      const date = this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi', '暂时')
      this.liucheng_digui(date)
      console.log(this.tiaojian_kongjian_cunzai)
    },
    liucheng_digui (data, zhi) {
      // if (data) {
      console.log(data)
      console.log(zhi)
      if (data.type == 1) {
        if (zhi != undefined && zhi.length != 0) {
          console.log(zhi)
          data.edit_list.forEach(item => {
            zhi.forEach(ite => {
              console.log(zhi)
              console.log(item)
              if (ite.idx == item.idx && item.type == 1) {
                // item.type=2
                this.$set(item, 'type', 2)
              }
            })
          })
        }
        if (data.childNode != null) {
          this.liucheng_digui(data.childNode, zhi)
        }
      } else if ((data.type == 0 || data.type == 2 || data.type == 9) && data.childNode != null) { // 审批人
        this.liucheng_digui(data.childNode, zhi)
      } else if (data.type == 3) {
        data.conditionList.forEach(item => {
          this.tiaojian_kongjian_cunzai.push(item)
        })
        if (data.childNode != null) {
          this.liucheng_digui(data.childNode, zhi)
        }
      } else if (data.type == 4) {
        if (data.childNode != null) {
          this.liucheng_digui(data.childNode, zhi)
        }
        for (let i = 0; i < data.conditionNodes.length; i++) {
          // if(data.childNode.conditionNodes[i].childNode!=null){
          this.liucheng_digui(data.conditionNodes[i], zhi)
          // }
        }
      }
      // } else {
      //     data.childNode = null
      // }
    },
    addConditionRole () { // 条件里打开人员
      // this.conditionRoleSearchName = "";
      var list = this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi_flowPermission', '暂时')
      if (list == undefined) {
        this.biao_list = []
      } else {
        this.biao_list = this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi_flowPermission', '暂时')
      }
      this.qiye_renyuan_list_xuan = []
      console.log(list)
      if (list == undefined || list.length == 0) {
        this.conditionRoleVisible = true
        query_dept_staff_tree_aim_not({
          data: {
            ent_id: this.$ent_id(),
            active: '1'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10168) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.faqi_ren_list = date
          } else if (res.data.code == 10169) {}
        })
      } else {
        this.conditionRoleVisible = true
        list.map(item => {
          this.$set(item, 'zhi', false)
        })
        const lista = []
        const listb = []
        for (let a = 0; a < list.length; a++) {
          if (list[a].type == 1) {
            lista.push(list[a])
          } else if (list[a].type == 2) {
            listb.push(list[a])
          }
        }
        this.faqi_ren_list = listb
        this.faqi_bumen_list = lista
        // query_dept_staff_tree_aim_not({data:{
        //     ent_id:this.$ent_id(),
        //     active:'1'
        // }}).then(res=>{
        //     console.log(res)
        //     if(res.data.code==10168){
        //         let date=JSON.parse(res.data.body.data)
        //         console.log(date)
        //         this.faqi_ren_list=date
        //     }else if(res.data.code==10169){}
        // })
      }
      // this.activeName = "1";
      // this.getDepartmentList();
      // this.dakai_bumen()
      // this.conditionDepartmentList = [];
      // this.conditionEmployessList = [];
      // this.conditionRoleList = [];
      // console.log(this.conditionConfig)
      // for (var i = 0; i < this.conditionConfig.nodeUserList.length; i++) {
      //     var { type, name, targetId } = this.conditionConfig.nodeUserList[i];
      //     if (type == 1) {
      //         this.conditionEmployessList.push({
      //             employeeName: name,
      //             id: targetId
      //         });
      //     } else if (type == 2) {
      //         this.conditionRoleList.push({
      //             roleName: name,
      //             roleId: targetId
      //         });
      //     } else if (type == 3) {
      //         this.conditionDepartmentList.push({
      //             departmentName: name,
      //             id: targetId
      //         });
      //     }
      // }
    },
    // 点击选择发起人    发起人里有  选择的是发起人里的发起人
    dianji_xuanze_faqi_ren (i) {
      console.log(i)
      i.zhi = !i.zhi
      if (i.zhi) {
        this.qiye_renyuan_list_xuan.push(i)
      } else {
        for (let a = 0; a < this.qiye_renyuan_list_xuan.length; a++) {
          if (this.qiye_renyuan_list_xuan[a].targetId == i.targetId) {
            this.qiye_renyuan_list_xuan.splice(a, 1)
          }
        }
      }
    },
    sureConditionRole () { // 条件里选人确定
      // if(this.qiye_renyuan_list_xuan.length!=0){
      console.log(this.qiye_renyuan_list_xuan)
      if (this.biao_list.length == 0) {
        const list = []
        const id = []
        this.conditionConfig.nodeUserList = []
        for (let i = 0; i < this.qiye_renyuan_list_xuan.length; i++) {
          list.push(this.qiye_renyuan_list_xuan[i].label)
          id.push(this.qiye_renyuan_list_xuan[i].id)
          this.ziguanliyuan_bei.push(this.qiye_renyuan_list_xuan[i])
          this.conditionConfig.nodeUserList.push({
            type: this.qiye_renyuan_list_xuan[i].type,
            targetId: this.qiye_renyuan_list_xuan[i].id,
            name: this.qiye_renyuan_list_xuan[i].label
          })
        }
        this.conditionRoleVisible = false
      } else {
        const id = []
        this.conditionConfig.nodeUserList = []
        for (let i = 0; i < this.qiye_renyuan_list_xuan.length; i++) {
          id.push(this.qiye_renyuan_list_xuan[i].targetId)
          this.ziguanliyuan_bei.push(this.qiye_renyuan_list_xuan[i])
          this.conditionConfig.nodeUserList.push({
            type: this.qiye_renyuan_list_xuan[i].type,
            targetId: this.qiye_renyuan_list_xuan[i].targetId,
            name: this.qiye_renyuan_list_xuan[i].name
          })
        }
        this.conditionRoleVisible = false
      }
      // }else{
      //     this.$message({
      //         message: '请选择人员',
      //         type: 'warning'
      //     });
      // }
      // this.conditionRoleList.map(item => {   //成员
      //     this.conditionConfig.nodeUserList.push({
      //         type: 2,
      //         targetId: item.roleId,
      //         name: item.roleName
      //     })
      // });
      // this.conditionDepartmentList.map(item => {  //部门
      //     this.conditionConfig.nodeUserList.push({
      //         type: 3,
      //         targetId: item.id,
      //         name: item.departmentName
      //     })
      // });
      // this.conditionEmployessList.map(item => {  //人员
      //     this.conditionConfig.nodeUserList.push({
      //         type: 1,
      //         targetId: item.id,
      //         name: item.employeeName
      //     })
      // });
    },
    // 审批人里  切换权限状态
    dianji_qiehuan_zhuangtai (i, id) {
      this.$set(i, 'type', id)
    },
    addCondition () {
      this.conditionList = []
      this.conditionVisible = true
      const date = this.$bendi_naqu_cunchu('mlbb_biaodan_sheji', '暂时')
      this.conditions = []
      this.conditions_wei = []
      date.map((i, index) => {
        console.log(i)
        if ((i.componentName == 'numberfield' || i.componentName == 'calcform' || i.componentName == 'moneyfield') && i.defaultImportant) {
          // i['columnType']='Double'
          i.detailedtype = '1'
          i.type = '2'
          this.conditions.push(i)
        } else if (i.componentName == 'ddselectfield' && i.defaultImportant) {
          i.detailedtype = '1'
          i.type = '3'
          this.conditions.push(i)
        } else if (i.componentName == 'ddmultiselectfield' && i.defaultImportant) {
          i.detailedtype = '1'
          i.type = '4'
          this.conditions.push(i)
        }
        if ((i.componentName == 'numberfield' || i.componentName == 'calcform' || i.componentName == 'moneyfield') && !i.defaultImportant) {
          i.detailedtype = '1'
          i.type = '2'
          this.conditions_wei.push(i)
        } else if (i.componentName == 'ddselectfield' && !i.defaultImportant) {
          i.detailedtype = '1'
          i.type = '3'
          this.conditions_wei.push(i)
        } else if (i.componentName == 'ddmultiselectfield' && !i.defaultImportant) {
          i.detailedtype = '1'
          i.type = '4'
          this.conditions_wei.push(i)
        }
        // if(i.componentName=='tablefield'){
        //     for(let a=0;a<i.components.length;a++){
        //         if(i.components[a].componentName=='numberfield'||i.components[a].componentName=='calcform'||i.components[a].componentName=='moneyfield'){
        //             // i.components[a]['columnType']='Double'
        //             i.components[a]['detailedtype']='2'
        //             i.components[a]['type']='2'
        //             this.conditions.push(i.components[a])
        //         }
        //     }
        // }
      })
      // this.$axios.get("/conditions.json?tableId=" + this.tableId).then(res => {
      //     this.conditions = res.data;
      //     if (this.conditionConfig.conditionList) {
      //         for (var i = 0; i < this.conditionConfig.conditionList.length; i++) {
      //             var { columnId } = this.conditionConfig.conditionList[i]
      //             if (columnId == 0) {
      //                 this.conditionList.push({ columnId: 0 })
      //             } else {
      //                 this.conditionList.push(this.conditions.filter(item => { return item.columnId == columnId; })[0])
      //             }
      //         }
      //     }
      // })
    },
    changeOptType (item) {
      if (item.optType == 1) {
        item.zdy1 = 2
      } else {
        item.zdy1 = 1
        item.zdy2 = 2
      }
    },
    sureCondition () {
      // 1.弹窗有，外面无+
      // 2.弹窗有，外面有不变
      console.log(this.conditionList)
      this.conditionConfig.conditionList = []
      for (var i = 0; i < this.conditionList.length; i++) {
        console.log(this.conditionList[i])
        var { defaultLable, detailedtype, componentName, idx, columnDbname, type, fixedDownBoxValue, defaultOptions } = this.conditionList[i]
        if (this.toggleClass(this.conditionConfig.conditionList, this.conditionList[i], 'idx')) {
          continue
        }
        if (idx == 'faqi_ren') {
          this.conditionConfig.nodeUserList == []
          this.conditionConfig.conditionList.push({
            type: '1',
            idx: 'faqi_ren',
            showName: '发起人',
            id: this.randomstring(10)
          })
        }
        // else {
        // -----------------------------------
        if (type == '2') {
          this.conditionConfig.conditionList.push({
            showType: componentName,
            type: type,
            showName: defaultLable,
            optType: '1',
            zdy1: '2',
            opt1: '1',
            zdy2: '1',
            opt2: '1',
            idx: idx,
            detailedtype: detailedtype
          })
          // }
          // else if (columnType == "String" && showType == "3") {
          //     this.conditionConfig.conditionList.push({
          //         "showType": showType,
          //         "columnId": columnId,
          //         "type": 2,
          //         "showName": showName,
          //         "zdy1": "",
          //         "columnDbname": columnName,
          //         "columnType": columnType,
          //         "fixedDownBoxValue": fixedDownBoxValue
          //     })
          // }
        }
        if (type == '3') {
          this.conditionConfig.conditionList.push({
            showType: componentName,
            type: type,
            optType: '1',
            showName: defaultLable,
            defaultOptions: defaultOptions,
            value_list: [],
            idx: idx,
            detailedtype: detailedtype
          })
        }
        if (type == '4') {
          this.conditionConfig.conditionList.push({
            showType: componentName,
            type: type,
            showName: defaultLable,
            optType: '1',
            defaultOptions: defaultOptions,
            value_list: [],
            idx: idx,
            detailedtype: detailedtype
          })
        }
      }
      /// /3.弹窗无，外面有-
      for (var i = this.conditionConfig.conditionList.length - 1; i >= 0; i--) {
        if (!this.toggleClass(this.conditionList, this.conditionConfig.conditionList[i], 'idx')) {
          this.conditionConfig.conditionList.splice(i, 1)
        }
      }
      this.conditionConfig.conditionList.sort(function (a, b) { return a.idx - b.idx })
      this.conditionVisible = false
    },
    // -------------------
    // 条件点击确定
    saveCondition () {
      this.conditionDrawer = false
      var a = this.conditionsConfig.conditionNodes.splice(this.bPriorityLevel - 1, 1)// 截取旧下标
      this.conditionsConfig.conditionNodes.splice(this.conditionConfig.priorityLevel - 1, 0, a[0])// 填充新下标
      this.conditionsConfig.conditionNodes.map((item, index) => {
        item.priorityLevel = index + 1
      })
      for (var i = 0; i < this.conditionsConfig.conditionNodes.length; i++) {
        this.conditionsConfig.conditionNodes[i].error = this.conditionStr(this.conditionsConfig.conditionNodes[i], i) == '请设置条件' && i != this.conditionsConfig.conditionNodes.length - 1
      }
      const date = this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi', '暂时')
      console.log(this.conditionsConfig)
      this.$emit('update:nodeConfig', this.conditionsConfig)
      const zhi = []// 存放哪些条件
      this.conditionsConfig.conditionNodes.forEach(item => {
        if (item.conditionList.length != 0) {
          item.conditionList.forEach(ite => {
            zhi.push(ite)
          })
        }
      })
      console.log(zhi)
      this.liucheng_digui(date, zhi)
      this.$bendi_cunfang_cunchu('mlbb_liucheng_shezhi', date, '暂时')
    },
    getDebounceData (event, type = 1) {
      this.$func.debounce(function () {
        if (event.target.value) {
          if (type == 1) {
            this.departments.childDepartments = []
            // this.$axios.get(`/employees.json?searchName=${event.target.value}&pageNum=1&pageSize=30`).then(res => {
            //     this.departments.employees = res.data.list
            // })
          } else {
            // this.$axios.get(`/roles.json?searchName=${event.target.value}&pageNum=1&pageSize=30`).then(res => {
            //     this.roles = res.data.list
            // })
          }
        } else {
          type == 1 ? this.getDepartmentList() : this.getRoleList()
        }
      }.bind(this))()
    },
    handleClick () {
      this.conditionRoleSearchName = ''
      if (this.activeName == 1) {
        this.getDepartmentList()
      } else {
        this.getRoleList()
      }
    },
    // 归档人
    dakai_guidang_renyuan () {
      this.guidang_chengyuan_list = true
      this.dakai_bumen()
    },
    guidang_queding () { // 归档右侧确定
      // setApproverStr
      this.guidang.error = !this.copyerStr(this.guidang)
      this.$emit('update:nodeConfig', this.guidang)
      this.guidang_youce = false
    },
    guidang_chengyuan_sure () { // 归档成员确定
      if (this.qiye_renyuan_list_xuan.length != 0) {
        this.guidang.nodeUserList = []
        console.log(this.qiye_renyuan_list_xuan)
        for (let i = 0; i < this.qiye_renyuan_list_xuan.length; i++) {
          this.guidang.nodeUserList.push({
            type: 1,
            targetId: this.qiye_renyuan_list_xuan[i].staff_id,
            name: this.qiye_renyuan_list_xuan[i].name
          })
        }
        this.guidang_chengyuan_list = false
      } else {
        this.$message({
          message: '请选择人员',
          type: 'warning'
        })
      }
    },
    addCopyer () { // 抄送人打开人员
      this.copyerVisible = true
      // this.activeName = "1";
      // this.getDepartmentList();
      if (this.copyerConfig.settype == 1) {
        this.copyerConfig.nodeUserList.map(item => {
          this.$set(item, 'staff_id', item.targetId)
        })
        this.qiye_renyuan_list_xuan = this.copyerConfig.nodeUserList
      } else if (this.copyerConfig.settype == 8) {
        this.copyerConfig.nodeUserList.map(item => {
          this.$set(item, 'role_id', item.targetId)
          this.$set(item, 'role_name', item.name)
        })
        this.qiye_juesery_list_xuan = this.copyerConfig.nodeUserList
      }
      this.dakai_bumen()
      // this.copyerEmployessList = [];
      // this.copyerRoleList = [];
      // for (var i = 0; i < this.copyerConfig.nodeUserList.length; i++) {
      //     var { type, name, targetId } = this.copyerConfig.nodeUserList[i];
      //     if (type == 1) {
      //         this.copyerEmployessList.push({
      //             employeeName: name,
      //             id: targetId
      //         });
      //     }
      // else if (type == 2) {
      //     this.copyerRoleList.push({
      //         roleName: name,
      //         roleId: targetId
      //     });
      // }
      // }
    },
    sureCopyer () { // 抄送人员确定
      if (this.qiye_renyuan_list_xuan.length != 0) {
        this.copyerConfig.nodeUserList = []
        console.log(this.qiye_renyuan_list_xuan)
        for (let i = 0; i < this.qiye_renyuan_list_xuan.length; i++) {
          this.copyerConfig.nodeUserList.push({
            type: 1,
            targetId: this.qiye_renyuan_list_xuan[i].staff_id,
            name: this.qiye_renyuan_list_xuan[i].name
          })
        }
        this.copyerVisible = false
      } else {
        this.$message({
          message: '请选择人员',
          type: 'warning'
        })
      }
      // this.copyerEmployessList.map(item => {
      //     this.copyerConfig.nodeUserList.push({
      //         type: 1,
      //         targetId: item.id,
      //         name: item.employeeName
      //     })
      // });
      // this.copyerRoleList.map(item => {
      //     this.copyerConfig.nodeUserList.push({
      //         type: 2,
      //         targetId: item.roleId,
      //         name: item.roleName
      //     })
      // });
    },
    saveCopyer () {
      this.copyerConfig.ccSelfSelectFlag = this.ccSelfSelectFlag.length == 0 ? 0 : 1
      this.copyerConfig.error = !this.copyerStr(this.copyerConfig)
      this.$emit('update:nodeConfig', this.copyerConfig)
      this.copyerDrawer = false
    },
    copyerStr (nodeConfig) {
      if (nodeConfig.nodeUserList.length != 0) {
        return this.arrToStr(nodeConfig.nodeUserList)
      }
      // else {
      //     if (nodeConfig.ccSelfSelectFlag == 1) {
      //         return "发起人自选"
      //     }
      // }
    },
    changeRange (val) {
      this.approverConfig.nodeUserList = []
    },
    changeType (val) {
      console.log(val)
      this.approverConfig.nodeUserList = []
      if (val == 2) {
        this.approverConfig.directorLevel = 1
      } else if (val == 4) {
        this.approverConfig.self_select_user_num = 1
        this.approverConfig.self_select_area = 1
        // this.$set(this.approverConfig,'self_select_user_num',1)
        // this.$set(this.approverConfig,'self_select_area',1)
      }
      this.approverConfig.self_select_user_num = 1
      this.approverConfig.examineMode = 1
      this.approverConfig.noHanderAction = 2
      //  else if (val == 7) {
      //     this.approverConfig.examineEndDirectorLevel = 1
      // }
    },
    changeType_chaosong (val) {
      console.log(val)
      this.copyerConfig.nodeUserList = []
      // if (val == 2) {
      //     this.copyerConfig.directorLevel = 1;
      // }else if (val == 4) {
      //     this.copyerConfig.self_select_user_num=1
      //     this.copyerConfig.self_select_area=1
      //     // this.$set(this.approverConfig,'self_select_user_num',1)
      //     // this.$set(this.approverConfig,'self_select_area',1)
      // }
      // this.copyerConfig.examineMode = 1;
      // this.copyerConfig.noHanderAction = 2;
      //  else if (val == 7) {
      //     this.approverConfig.examineEndDirectorLevel = 1
      // }
    },
    addApprover () { // 审批里打开成员
      this.approverVisible = true
      if (this.approverConfig.settype == 1 || this.approverConfig.self_select_area == 2) {
        this.approverConfig.nodeUserList.map(item => {
          this.$set(item, 'staff_id', item.targetId)
        })
        this.qiye_renyuan_list_xuan = this.approverConfig.nodeUserList
      } else if (this.approverConfig.settype == 8 || this.approverConfig.self_select_area == 3) {
        this.approverConfig.nodeUserList.map(item => {
          this.$set(item, 'role_id', item.targetId)
          this.$set(item, 'role_name', item.name)
        })
        this.qiye_juesery_list_xuan = this.approverConfig.nodeUserList
      }
      this.dakai_bumen()
      // this.approverSearchName = "";
      // this.getDepartmentList();
      // this.approverEmplyessList = [];
      // for (var i = 0; i < this.approverConfig.nodeUserList.length; i++) {
      //     var { name, targetId } = this.approverConfig.nodeUserList[i];
      //     this.approverEmplyessList.push({
      //         employeeName: name,
      //         id: targetId
      //     });
      // }
    },
    addRoleApprover () { // 审批里打开角色
      if (this.approverConfig.settype == 1 || this.approverConfig.self_select_area == 2) {
        this.approverConfig.nodeUserList.map(item => {
          this.$set(item, 'staff_id', item.targetId)
        })
        this.qiye_renyuan_list_xuan = this.approverConfig.nodeUserList
      } else if (this.approverConfig.settype == 8 || this.approverConfig.self_select_area == 3) {
        this.approverConfig.nodeUserList.map(item => {
          this.$set(item, 'role_id', item.targetId)
          this.$set(item, 'role_name', item.name)
        })
        this.qiye_juesery_list_xuan = this.approverConfig.nodeUserList
      }
      this.zhanshi_juese_zuoce()
      this.approverRoleVisible = true
      this.dakai_juese()
      // this.approverRoleSearchName = "";
      // this.getRoleList();
      // this.roleList = [];
      // for (var i = 0; i < this.approverConfig.nodeUserList.length; i++) {
      //     var { name, targetId } = this.approverConfig.nodeUserList[i];
      //     this.roleList.push({
      //         roleName: name,
      //         roleId: targetId
      //     });
      // }
    },
    addRoleApprover_chaosong () { // 审批里打开角色
      if (this.copyerConfig.settype == 1 || this.copyerConfig.self_select_area == 2) {
        this.copyerConfig.nodeUserList.map(item => {
          this.$set(item, 'staff_id', item.targetId)
        })
        this.qiye_renyuan_list_xuan = this.copyerConfig.nodeUserList
      } else if (this.copyerConfig.settype == 8 || this.copyerConfig.self_select_area == 3) {
        this.copyerConfig.nodeUserList.map(item => {
          this.$set(item, 'role_id', item.targetId)
          this.$set(item, 'role_name', item.name)
        })
        this.qiye_juesery_list_xuan = this.copyerConfig.nodeUserList
      }
      this.zhanshi_juese_zuoce()
      this.approverRoleVisible_chaosong = true
      this.dakai_juese()
      // this.approverRoleSearchName = "";
      // this.getRoleList();
      // this.roleList = [];
      // for (var i = 0; i < this.approverConfig.nodeUserList.length; i++) {
      //     var { name, targetId } = this.approverConfig.nodeUserList[i];
      //     this.roleList.push({
      //         roleName: name,
      //         roleId: targetId
      //     });
      // }
    },
    sureApprover () { // 审批人里的成员点击确定
      if (this.qiye_renyuan_list_xuan.length != 0) {
        // let list=[]
        // let id=[]
        this.approverConfig.nodeUserList = []
        console.log(this.qiye_renyuan_list_xuan)
        for (let i = 0; i < this.qiye_renyuan_list_xuan.length; i++) {
          // list.push(this.qiye_renyuan_list_xuan[i].name)
          // id.push(this.qiye_renyuan_list_xuan[i].staff_id)
          this.ziguanliyuan_bei.push(this.qiye_renyuan_list_xuan[i])
          this.approverConfig.nodeUserList.push({
            type: 1,
            targetId: this.qiye_renyuan_list_xuan[i].staff_id,
            name: this.qiye_renyuan_list_xuan[i].name
          })
        }
        this.approverVisible = false
      } else {
        this.$message({
          message: '请选择人员',
          type: 'warning'
        })
      }
      // if (this.approverConfig.settype == 1 || (this.approverConfig.settype == 4 && this.approverConfig.selectRange == 2)) {
      //     this.approverEmplyessList.map(item => {
      //         this.approverConfig.nodeUserList.push({
      //             type: 1,
      //             targetId: item.id,
      //             name: item.employeeName
      //         })
      //     });
      //     this.approverVisible = false;
      // }
      // else if (this.approverConfig.settype == 4 && this.approverConfig.selectRange == 3) {
      //     this.roleList.map(item => {
      //         this.approverConfig.nodeUserList.push({
      //             type: 2,
      //             targetId: item.roleId,
      //             name: item.roleName
      //         })
      //     });
      //     this.approverRoleVisible = false;
      // }
    },
    sureApprover_jue () { // 审批人里的角色点击确定
      if (this.qiye_juesery_list_xuan.length != 0) {
        // let list=[]
        // let id=[]
        query_user_list_of_role({
          data: {
            ent_id: this.$ent_id(),
            role_id: this.qiye_juesery_list_xuan[0].role_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            if (date.length != 0) {
              this.approverConfig.nodeUserList = []
              console.log(this.qiye_juesery_list_xuan)
              for (let i = 0; i < this.qiye_juesery_list_xuan.length; i++) {
                // list.push(this.qiye_juesery_list_xuan[i].post_name)
                // id.push(this.qiye_juesery_list_xuan[i].post_id)
                this.juesezgly_bei.push(this.qiye_juesery_list_xuan[i])
                this.approverConfig.nodeUserList.push({
                  type: 8,
                  targetId: this.qiye_juesery_list_xuan[i].role_id,
                  name: this.qiye_juesery_list_xuan[i].role_name
                })
              }
              this.approverConfig.examineMode = 2
              this.approverRoleVisible = false
            } else {
              this.$message({
                message: '该角色下没有人员',
                type: 'warning'
              })
            }
          } else if (res.data.code == 500) {}
        })
      } else {
        this.$message({
          message: '请选择角色',
          type: 'warning'
        })
      }
    },
    sureApprover_jue_chaosong () { // 抄送人里的角色点击确定
      if (this.qiye_juesery_list_xuan.length != 0) {
        // let list=[]
        // let id=[]
        query_user_list_of_role({
          data: {
            ent_id: this.$ent_id(),
            role_id: this.qiye_juesery_list_xuan[0].role_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            if (date.length != 0) {
              this.copyerConfig.nodeUserList = []
              console.log(this.qiye_juesery_list_xuan)
              for (let i = 0; i < this.qiye_juesery_list_xuan.length; i++) {
                // list.push(this.qiye_juesery_list_xuan[i].post_name)
                // id.push(this.qiye_juesery_list_xuan[i].post_id)
                this.juesezgly_bei.push(this.qiye_juesery_list_xuan[i])
                this.copyerConfig.nodeUserList.push({
                  type: 8,
                  targetId: this.qiye_juesery_list_xuan[i].role_id,
                  name: this.qiye_juesery_list_xuan[i].role_name
                })
              }
              // this.copyerConfig.examineMode=2
              this.approverRoleVisible_chaosong = false
            } else {
              this.$message({
                message: '该角色下没有人员',
                type: 'warning'
              })
            }
          } else if (res.data.code == 500) {}
        })
      } else {
        this.$message({
          message: '请选择角色',
          type: 'warning'
        })
      }
    },
    setApproverStr (nodeConfig) { // 外面图形展示审批  会签 还是或签
      console.log(nodeConfig)
      if (nodeConfig.settype == 1 || nodeConfig.settype == 8) {
        if (nodeConfig.nodeUserList.length == 1) {
          return nodeConfig.nodeUserList[0].name
        } else if (nodeConfig.nodeUserList.length > 1) {
          if (nodeConfig.examineMode == 1) {
            return this.arrToStr(nodeConfig.nodeUserList)
          } else if ((nodeConfig.examineMode == 2 && nodeConfig.settype == 1)) {
            return nodeConfig.nodeUserList.map(item => item.name).join(',')
          } else if (nodeConfig.examineMode == 3 && nodeConfig.settype == 1) {
            return nodeConfig.nodeUserList.map(item => item.name).join(',')
          } else if (nodeConfig.examineMode == 2 && nodeConfig.settype == 8) {
            return nodeConfig.nodeUserList.map(item => item.name).join(',')
          } else if (nodeConfig.examineMode == 3 && nodeConfig.settype == 8) {
            return nodeConfig.nodeUserList.map(item => item.name).join(',')
          }
        }
      } else if (nodeConfig.settype == 2) {
        const level = nodeConfig.directorLevel == 1 ? '直接主管' : '第' + nodeConfig.directorLevel + '级主管'
        if (nodeConfig.examineMode == 1) {
          return level
        } else if (nodeConfig.examineMode == 2) {
          return level + '会签'
        }
      } else if (nodeConfig.settype == 4) {
        if (nodeConfig.self_select_area == 1) {
          return '发起人自选'
        } else {
          if (nodeConfig.nodeUserList.length > 0) {
            if (nodeConfig.self_select_area == 2) {
              return '发起人自选'
            } else {
              return '发起人从' + nodeConfig.nodeUserList[0].name + '中自选'
            }
          } else {
            return ''
          }
        }
      }
      // else if (nodeConfig.settype == 5) {
      //     return "发起人自己"
      // } else if (nodeConfig.settype == 7) {
      //     return '从直接主管到通讯录中级别最高的第' + nodeConfig.examineEndDirectorLevel + '个层级主管'
      // }
    },
    saveApprover () { // 审批人里点击确定
      console.log(this.approverConfig)
      this.approverConfig.error = !this.setApproverStr(this.approverConfig)
      this.$emit('update:nodeConfig', this.approverConfig)
      this.approverDrawer = false
    },
    xuanze_renyuan (date) {
      console.log(this.$refs.faqi_ren_tree.getCheckedNodes())
      this.qiye_renyuan_list_xuan = this.$func.getSimpleCheckedNodes(this.$refs.faqi_ren_tree.store)
      // this.qiye_renyuan_list_xuan=this.$refs.faqi_ren_tree.getCheckedNodes()
    },
    xuanze_tiaojian_renyuan () {
      console.log(this.$func.getSimpleCheckedNodes(this.$refs.tiaojian_faqi_ren_tree.store))
      this.qiye_renyuan_list_xuan = this.$func.getSimpleCheckedNodes(this.$refs.tiaojian_faqi_ren_tree.store)
    },
    addPromoter () { // 发起人里的成员打开
      // this.promoterVisible = true;
      this.promoterVisible = true
      query_dept_staff_tree_aim_not({
        data: {
          ent_id: this.$ent_id(),
          active: '1'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.faqi_ren_list = date
        } else if (res.data.code == 10169) {}
      })
      // this.dakai_bumen()
      // this.getDepartmentList();
      // this.promoterSearchName = "";
      // this.checkedEmployessList = [];
      // this.checkedDepartmentList = [];
      // for (var i = 0; i < this.flowPermission1.length; i++) {
      //     var { type, name, targetId } = this.flowPermission1[i];
      //     if (type == 1) {
      //         this.checkedEmployessList.push({
      //             employeeName: name,
      //             id: targetId
      //         });
      //     } else if (type == 3) {
      //         this.checkedDepartmentList.push({
      //             departmentName: name,
      //             id: targetId
      //         });
      //     }
      // }
    },
    faqi_ren_sure () {
      // if(this.qiye_renyuan_list_xuan.length!=0){
      const list = []
      const id = []
      this.flowPermission1 = []
      console.log(this.qiye_renyuan_list_xuan)
      for (let i = 0; i < this.qiye_renyuan_list_xuan.length; i++) {
        list.push(this.qiye_renyuan_list_xuan[i].label)
        id.push(this.qiye_renyuan_list_xuan[i].id)
        this.ziguanliyuan_bei.push(this.qiye_renyuan_list_xuan[i])
        this.flowPermission1.push({
          type: this.qiye_renyuan_list_xuan[i].type,
          targetId: this.qiye_renyuan_list_xuan[i].id,
          name: this.qiye_renyuan_list_xuan[i].label
        })
      }
      console.log(this.flowPermission1)
      this.promoterVisible = false
      // }else{
      //     this.$message({
      //         message: '请选择人员',
      //         type: 'warning'
      //     });
      // }
    },
    // surePromoter() {
    //     this.flowPermission1 = [];
    //     // this.checkedDepartmentList.map(item => {
    //     //     this.flowPermission1.push({
    //     //         type: 3,
    //     //         targetId: item.id,
    //     //         name: item.departmentName
    //     //     })
    //     // });
    //     console.log(11111)
    //     this.checkedEmployessList.map(item => {
    //         console.log(item)
    //         this.flowPermission1.push({
    //             type: 1,
    //             targetId: item.id,
    //             name: item.employeeName
    //         })
    //     });
    //     console.log( this.flowPermission1)
    //     this.promoterVisible = false;
    // },
    savePromoter () {
      this.$emit('update:flowPermission', this.flowPermission1)
      this.promoterDrawer = false
    },
    arrToStr (arr) {
      if (arr) {
        return arr.map(item => { return item.name }).toString()
      }
    },
    toggleStrClass (item, key) {
      const a = item.zdy1 ? item.zdy1.split(',') : []
      return a.some(item => { return item == key })
    },
    toStrChecked (item, key) {
      const a = item.zdy1 ? item.zdy1.split(',') : []
      var isIncludes = this.toggleStrClass(item, key)
      if (!isIncludes) {
        a.push(key)
        item.zdy1 = a.toString()
      } else {
        this.removeStrEle(item, key)
      }
    },
    removeStrEle (item, key) {
      const a = item.zdy1 ? item.zdy1.split(',') : []
      var includesIndex
      a.map((item, index) => {
        if (item == key) {
          includesIndex = index
        }
      })
      a.splice(includesIndex, 1)
      item.zdy1 = a.toString()
    },
    toggleClass (arr, elem, key = 'id') {
      // console.log(arr)
      // console.log(elem)
      // console.log([key])
      return arr.some(item => { return item[key] == elem[key] })
    },
    toChecked (arr, elem, key = 'id') {
      var isIncludes = this.toggleClass(arr, elem, key)
      !isIncludes ? arr.push(elem) : this.removeEle(arr, elem, key)
    },
    toChecked_wei (index, item) {
      this.conditionList = []
      const date = this.$bendi_naqu_cunchu('mlbb_biaodan_sheji', '暂时')
      this.conditions = []
      this.conditions_wei = []
      date.forEach(i => {
        console.log(i)
        if (item.idx == i.idx) {
          i.defaultImportant = true
        }
        // if((i.componentName=='numberfield'||i.componentName=='calcform'||i.componentName=='moneyfield')&&i.defaultImportant){
        //     // i['columnType']='Double'
        //     i['detailedtype']='1'
        //     i['type']='2'
        //     this.conditions.push(i)
        // }else if((i.componentName=='numberfield'||i.componentName=='calcform'||i.componentName=='moneyfield')&&!i.defaultImportant){
        //     i['detailedtype']='1'
        //     i['type']='2'
        //     this.conditions_wei.push(i)
        // }
        if ((i.componentName == 'numberfield' || i.componentName == 'calcform' || i.componentName == 'moneyfield') && i.defaultImportant) {
          // i['columnType']='Double'
          i.detailedtype = '1'
          i.type = '2'
          this.conditions.push(i)
        } else if (i.componentName == 'ddselectfield' && i.defaultImportant) {
          i.detailedtype = '1'
          i.type = '3'
          this.conditions.push(i)
        } else if (i.componentName == 'ddmultiselectfield' && i.defaultImportant) {
          i.detailedtype = '1'
          i.type = '4'
          this.conditions.push(i)
        }
        if ((i.componentName == 'numberfield' || i.componentName == 'calcform' || i.componentName == 'moneyfield') && !i.defaultImportant) {
          i.detailedtype = '1'
          i.type = '2'
          this.conditions_wei.push(i)
        } else if (i.componentName == 'ddselectfield' && !i.defaultImportant) {
          i.detailedtype = '1'
          i.type = '3'
          this.conditions_wei.push(i)
        } else if (i.componentName == 'ddmultiselectfield' && !i.defaultImportant) {
          i.detailedtype = '1'
          i.type = '4'
          this.conditions_wei.push(i)
        }
        // if(i.componentName=='tablefield'){
        //     for(let a=0;a<i.components.length;a++){
        //         if(i.components[a].componentName=='numberfield'||i.components[a].componentName=='calcform'||i.components[a].componentName=='moneyfield'){
        //             // i.components[a]['columnType']='Double'
        //             i.components[a]['detailedtype']='2'
        //             i.components[a]['type']='2'
        //             this.conditions.push(i.components[a])
        //         }
        //     }
        // }
      })
      this.$bendi_cunfang_cunchu('mlbb_biaodan_sheji', date, '暂时')
      // var isIncludes = this.toggleClass(this.conditionList, this.conditions[this.conditions.length-1], 'idx');
      // !isIncludes ? this.conditionList.push(this.conditions[this.conditions.length-1]) : this.removeEle(this.conditionList, this.conditions[this.conditions.length-1], 'idx');
      // this.conditions_wei.splice(index,1)
    },
    removeEle (arr, elem, key = 'id') {
      console.log(elem)
      console.log(key)
      console.log(arr)
      var includesIndex
      arr.map((item, index) => {
        if (item[key] == elem[key]) {
          includesIndex = index
        }
      })

      arr.splice(includesIndex, 1)
      if (arr.length == 1) {
        this.approverConfig.examineMode = 1
      }
    },
    getRoleList () {
      // this.$axios.get("/roles.json").then(res => {
      //     this.roles = res.data.list;
      // })
    },
    getDepartmentList (parentId = 0) {
      // this.$axios.get("/departments.json?parentId=" + parentId).then(res => {
      //     this.departments = res.data;
      // })
    },
    delNode () {
      this.$emit('update:nodeConfig', this.nodeConfig.childNode)
    },
    randomstring (L) {
      var s = ''
      var randomchar = function () {
        var n = Math.floor(Math.random() * 62)
        if (n < 10) return n // 1-10
        if (n < 36) return String.fromCharCode(n + 55) // A-Z
        return String.fromCharCode(n + 61) // a-z
      }
      while (s.length < L) s += randomchar()
      return s
    },
    addTerm () {
      const len = this.nodeConfig.conditionNodes.length + 1
      this.nodeConfig.conditionNodes.push({
        nodeName: '条件' + len,
        type: 3,
        priorityLevel: len,
        conditionList: [],
        nodeUserList: [],
        childNode: null,
        id: this.randomstring(10)
      })
      for (var i = 0; i < this.nodeConfig.conditionNodes.length; i++) {
        this.nodeConfig.conditionNodes[i].error = this.conditionStr(this.nodeConfig.conditionNodes[i], i) == '请设置条件' && i != this.nodeConfig.conditionNodes.length - 1
      }
      this.$emit('update:nodeConfig', this.nodeConfig)
    },
    delTerm (index) {
      this.nodeConfig.conditionNodes.splice(index, 1)
      for (var i = 0; i < this.nodeConfig.conditionNodes.length; i++) {
        this.nodeConfig.conditionNodes[i].error = this.conditionStr(this.nodeConfig.conditionNodes[i], i) == '请设置条件' && i != this.nodeConfig.conditionNodes.length - 1
      }
      this.$emit('update:nodeConfig', this.nodeConfig)
      if (this.nodeConfig.conditionNodes.length == 1) {
        if (this.nodeConfig.childNode) {
          if (this.nodeConfig.conditionNodes[0].childNode) {
            this.reData(this.nodeConfig.conditionNodes[0].childNode, this.nodeConfig.childNode)
          } else {
            this.nodeConfig.conditionNodes[0].childNode = this.nodeConfig.childNode
          }
        }
        this.$emit('update:nodeConfig', this.nodeConfig.conditionNodes[0].childNode)
      }
    },
    reData (data, addData) {
      if (!data.childNode) {
        data.childNode = addData
      } else {
        this.reData(data.childNode, addData)
      }
    },
    setPerson (priorityLevel) { // 右侧弹出啥
      console.log(this.nodeConfig)
      var { type } = this.nodeConfig
      console.log(type)
      if (type == 0) {
        this.promoterDrawer = true
        this.flowPermission1 = this.flowPermission
      } else if (type == 1) {
        this.zi_dingyi_kongjian()
        this.approverDrawer = true
        this.approverConfig = JSON.parse(JSON.stringify(this.nodeConfig))
        this.approverConfig.settype = this.approverConfig.settype ? this.approverConfig.settype : 1
        if (this.approverConfig.settype == 1 || this.approverConfig.self_select_area == 2) {
          this.approverConfig.nodeUserList.map(item => {
            this.$set(item, 'staff_id', item.targetId)
          })
          this.qiye_renyuan_list_xuan = this.approverConfig.nodeUserList
        } else if (this.approverConfig.settype == 8 || this.approverConfig.self_select_area == 3) {
          this.approverConfig.nodeUserList.map(item => {
            this.$set(item, 'role_id', item.targetId)
            this.$set(item, 'role_name', item.name)
          })
          this.qiye_renyuan_list_xuan = this.approverConfig.nodeUserList
        }
      } else if (type == 2) {
        this.copyerConfig = JSON.parse(JSON.stringify(this.nodeConfig))
        this.copyerConfig.settype = this.copyerConfig.settype ? this.copyerConfig.settype : 1
        if (this.copyerConfig.settype == 1 || this.copyerConfig.self_select_area == 2) {
          this.copyerConfig.nodeUserList.map(item => {
            this.$set(item, 'staff_id', item.targetId)
          })
          this.qiye_renyuan_list_xuan = this.copyerConfig.nodeUserList
        } else if (this.copyerConfig.settype == 8 || this.copyerConfig.self_select_area == 3) {
          this.copyerConfig.nodeUserList.map(item => {
            this.$set(item, 'role_id', item.targetId)
            this.$set(item, 'role_name', item.name)
          })
          this.qiye_renyuan_list_xuan = this.copyerConfig.nodeUserList
        }
        this.copyerDrawer = true
        // this.copyerConfig = JSON.parse(JSON.stringify(this.nodeConfig))
        this.ccSelfSelectFlag = this.copyerConfig.ccSelfSelectFlag == 0 ? [] : [this.copyerConfig.ccSelfSelectFlag]
      } else if (type == 9) {
        this.guidang_youce = true
        this.guidang = JSON.parse(JSON.stringify(this.nodeConfig))
      } else {
        this.conditionDrawer = true
        this.bPriorityLevel = priorityLevel
        this.conditionsConfig = JSON.parse(JSON.stringify(this.nodeConfig))
        this.conditionConfig = this.conditionsConfig.conditionNodes[priorityLevel - 1]
      }
    },
    arrTransfer (index, type = 1) { // 向左-1,向右1
      this.nodeConfig.conditionNodes[index] = this.nodeConfig.conditionNodes.splice(index + type, 1, this.nodeConfig.conditionNodes[index])[0]
      this.nodeConfig.conditionNodes.map((item, index) => {
        item.priorityLevel = index + 1
      })
      for (var i = 0; i < this.nodeConfig.conditionNodes.length; i++) {
        this.nodeConfig.conditionNodes[i].error = this.conditionStr(this.nodeConfig.conditionNodes[i], i) == '请设置条件' && i != this.nodeConfig.conditionNodes.length - 1
      }
      this.$emit('update:nodeConfig', this.nodeConfig)
    },
    // 部门列表   自己的
    bumen_liebiao () {
      query_ent_dept_list({
        data: {
          ent_id: this.$ent_id(),
          active: '1',
          parent_id: this.mianbao_list[this.mianbao_list.length - 1].dept_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.qiye_bumen_list = date
        } else if (res.data.code == 10169) {
        }
      })
      query_dept_staff_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          dept_id: this.mianbao_list[this.mianbao_list.length - 1].dept_id,
          active: '1'
        }
      }).then(ras => {
        if (ras.data.code == 10149) {
          const listdata = JSON.parse(ras.data.body.data)
          console.log(99999, listdata)
          listdata.map(item => { item.zhi = false })
          this.qiye_renyuan_list = listdata
          this.panduan_yixuan()
        } else if (ras.data.code == 10150) {
        }
      })
    },
    dakai_bumen () {
      this.mianbao_list.splice(1, this.mianbao_list.length)
      this.bumen_liebiao()
    },
    // 点击全选
    // dianji_quanxuan(e){
    //     console.log(e)
    //     console.log(this.$refs.have)
    //     if(e.path[0].checked){
    //         for(let i=0;i<this.$refs.have.length;i++){
    //             this.$refs.have[i].checked=true
    //         }
    //         this.qiye_renyuan_list_xuan=[]
    //         for(let i=0;i<this.qiye_renyuan_list.length;i++){
    //             this.qiye_renyuan_list_xuan.push(this.qiye_renyuan_list[i])
    //         }
    //         console.log(this.qiye_renyuan_list_xuan)
    //     }else{
    //         for(let i=0;i<this.$refs.have.length;i++){
    //             this.$refs.have[i].checked=false
    //         }
    //         this.qiye_renyuan_list_xuan=[]
    //     }
    // },
    // 单选人
    // dianji_xuanren(i,e,index){
    //     console.log(e)
    //     if(e.path[0].checked==true){
    //         this.qiye_renyuan_list_xuan.push(i)
    //     }else{
    //         console.log(this.qiye_renyuan_list_xuan)
    //         for(let i=0;i<this.qiye_renyuan_list_xuan.length;i++){
    //             if(this.qiye_renyuan_list[index].name==this.qiye_renyuan_list_xuan[i].name){
    //                 this.qiye_renyuan_list_xuan.splice(i,1)
    //             }
    //         }
    //     }
    // },
    // 点击部门
    dianji_bumen (i) {
      console.log(44444, i)
      // if(i.child_flag==1){
      query_ent_dept_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          parent_id: i.dept_id,
          active: '1'
        }
      }).then(res => {
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.qiye_bumen_list = date
          this.mianbao_list.push({ name: i.dept_name, dept_id: i.dept_id })
          this.inde = -1
          if (!this.bumen) {
            query_dept_staff_list({
              data: {
                ent_id: this.$ent_id(),
                user_id: this.$jichuxinxi().user_id,
                dept_id: i.dept_id,
                active: '1'
              }
            }).then(ras => {
              if (ras.data.code == 10149) {
                const listdata = JSON.parse(ras.data.body.data)
                console.log(99999, listdata)
                listdata.map(item => { item.zhi = false })
                this.qiye_renyuan_list = listdata
                this.youce_guanlian_zuoce()
              } else if (ras.data.code == 10150) {
              }
            })
          }
        } else if (res.data.code == 10169) {
        }
      })
      // }else{
      //     this.$message({
      //         message: '该部门没有子部门了',
      //         type: 'warning'
      //     });
      // }
    },
    // 点击面包屑进行页面内容切换
    mianbao_dian (i, index) {
      console.log(i.dept_id)
      if (this.mianbao_list.length - 1 != index) {
        query_ent_dept_list({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            parent_id: i.dept_id,
            active: '1'
          }
        }).then(res => {
          if (res.data.code == 10168) {
            var date = JSON.parse(res.data.body.data)
            // console.log(date)
            this.qiye_bumen_list = date
            this.mianbao_list = date
            date.forEach(item => {
              this.mianbao_list.push({
                dept_name: item.dept_name, dept_id: item.dept_id
              })
            })
            this.mianbao_list.splice(index + 1, this.mianbao_list.length)
            query_dept_staff_list({
              data: {
                ent_id: this.$ent_id(),
                user_id: this.$jichuxinxi().user_id,
                dept_id: i.dept_id,
                active: '1'
              }
            }).then(ras => {
              console.log(ras)
              if (ras.data.code == 10149) {
                const listdata = JSON.parse(ras.data.body.data)
                console.log(99999, listdata)
                listdata.map(item => { item.zhi = false })
                this.qiye_renyuan_list = listdata
                this.youce_guanlian_zuoce()
              } else if (ras.data.code == 10150) {
              }
            })
          } else if (res.data.code == 10169) {
          }
        })
      }
    },
    // 发起人里成员点击确定
    surePromoter () {
      if (this.qiye_renyuan_list_xuan.length != 0) {
        const list = []
        const id = []
        this.flowPermission1 = []
        console.log(this.qiye_renyuan_list_xuan)
        for (let i = 0; i < this.qiye_renyuan_list_xuan.length; i++) {
          list.push(this.qiye_renyuan_list_xuan[i].name)
          id.push(this.qiye_renyuan_list_xuan[i].staff_id)
          this.ziguanliyuan_bei.push(this.qiye_renyuan_list_xuan[i])
          this.flowPermission1.push({
            type: 1,
            targetId: this.qiye_renyuan_list_xuan[i].staff_id,
            name: this.qiye_renyuan_list_xuan[i].name
          })
        }
        console.log(this.flowPermission1)
        this.promoterVisible = false
      } else {
        this.$message({
          message: '请选择人员',
          type: 'warning'
        })
      }
    },
    keyu () {
      
      get_staff_list_like({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          staff_name: this.text,
          active: '1'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10027) {
          console.log(JSON.parse(res.data.body.data))
          const date = JSON.parse(res.data.body.data)
          date.forEach(item => {
            this.$set(item, 'name', item.staff_name)
          })
          this.qiye_bumen_list = []
          this.qiye_renyuan_list = date
        } else if (res.data.code == 10028) {
        }
      })
    },
    // 角色列表
    juese_liebiao () {
      query_role_group_info_list({
        data: {
          ent_id: this.$ent_id(),
          active: '1'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.qiye_juese_list = date
        } else if (res.data.code == 500) {
        }
      })
      // query_role_info_list({data:{
      //     ent_id:this.$ent_id(),
      // }}).then(ras=>{
      //     if(ras.data.code==200){
      //         let listdata=JSON.parse(ras.data.body.data)
      //         console.log(99999,listdata)
      //         this.qiye_juesery_list=listdata
      //     }else if(ras.data.code==500){
      //     }
      // })

      // query_role_group_info_list({data:{
      //     ent_id:this.$ent_id(),
      //     active:'1'
      // }}).then(res=>{
      //     console.log(res)
      //     if(res.data.code==200){
      //         let date=JSON.parse(res.data.body.data)
      //         console.log(date)
      //         this.juese_list=[]
      //         this.juese_lista=[]
      //         this.juese_zu=[]
      //         // let juese_xuanze_list=[]
      //         for(let i=0;i<date.length;i++){
      //             this.juese_list.push({})
      //             this.juese_list[i]['label']=date[i].role_group_name
      //             this.juese_list[i]['id']=date[i].role_group_id
      //             for(let a=0;a<date[i].role_list.length;a++){
      //                 date[i].role_list[a]['label']=date[i].role_list[a].role_name
      //                 date[i].role_list[a]['id']=date[i].role_list[a].role_id
      //                 // juese_xuanze_list.push(date[i].role_list[a])
      //             }
      //             this.juese_list[i]['children']=date[i].role_list
      //             this.juese_zu.push(date[i].role_group_id)
      //             this.juese_lista.push(this.juese_list[i])
      //             console.log(this.juese_lista)
      //             console.log(this.juese_zu)
      //         }
      //         // this.juese_zu=this.juese_lista.map(item=>item.id).join(',')
      //         // this.juese_xuanze_list=juese_xuanze_list
      //     }else if(res.data.code==500){}
      // })
    },
    dakai_juese () {
      this.mianbao_list_jue.splice(1, this.mianbao_list_jue.length)
      this.juese_liebiao()
    },
    // 点击全选
    // dianji_quanxuan_jue(e){
    //     console.log(e)
    //     console.log(this.$refs.have_jue)
    //     if(e.path[0].checked){
    //         for(let i=0;i<this.$refs.have_jue.length;i++){
    //             this.$refs.have_jue[i].checked=true
    //         }
    //         this.qiye_juesery_list_xuan=[]
    //         for(let i=0;i<this.qiye_juesery_list.length;i++){
    //             this.qiye_juesery_list_xuan.push(this.qiye_juesery_list[i])
    //         }
    //         console.log(this.qiye_juesery_list_xuan)
    //     }else{
    //         for(let i=0;i<this.$refs.have_jue.length;i++){
    //             this.$refs.have_jue[i].checked=false
    //         }
    //         this.qiye_juesery_list_xuan=[]
    //     }
    // },
    // // 单选人
    dianji_xuanren_jue (i, index) {
      this.juese_num = index
      console.log(index)
      console.log(i)
      const list = []
      list.push(i)
      this.qiye_juesery_list_xuan = list
      // console.log(e)
      // if(e.path[0].checked==true){
      //     this.qiye_juesery_list_xuan.push(i)
      // }else{
      //     console.log(this.qiye_juesery_list_xuan)
      //     for(let i=0;i<this.qiye_juesery_list_xuan.length;i++){
      //         if(this.qiye_juesery_list[index].role_group_name==this.qiye_juesery_list_xuan[i].role_group_name){
      //             this.qiye_juesery_list_xuan.splice(i,1)
      //         }
      //     }
      // }
    },
    // 点击部门
    dianji_bumen_jue (i) {
      console.log(44444, i)
      this.qiye_juese_list = []
      this.mianbao_list_jue.push({ name: i.role_group_name, dept_id: i.role_group_id })
      query_role_info_list({
        data: {
          ent_id: this.$ent_id(),
          role_group_id: i.role_group_id
        }
      }).then(ras => {
        if (ras.data.code == 200) {
          const listdata = JSON.parse(ras.data.body.data)
          console.log(99999, listdata)
          this.qiye_juesery_list = listdata
        } else if (ras.data.code == 500) {
        }
      })
    },
    // 点击面包屑进行页面内容切换
    mianbao_dian_jue (i, index) {
      if (this.mianbao_list_jue.length - 1 != index) {
        query_role_group_info_list({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          if (res.data.code == 200) {
            var date = JSON.parse(res.data.body.data)
            // console.log(date)
            this.qiye_juese_list = date
            this.mianbao_list_jue.splice(index + 1, this.mianbao_list_jue.length)
            this.qiye_juesery_list = []
            // query_role_info_list({data:{
            //     ent_id:this.$ent_id(),
            // }}).then(ras=>{
            //     if(ras.data.code==200){
            //         let listdata=JSON.parse(ras.data.body.data)
            //         console.log(99999,listdata)
            //         this.qiye_juesery_list=listdata
            //     }else if(ras.data.code==10150){
            //     }
            // })
          } else if (res.data.code == 500) {
          }
        })
      }
    },
    keyu_jue () {
      if (this.juese_sousuo != undefined && this.juese_sousuo.length == 0) {
        this.juese_sousuo = null
      }
      query_role_info_list({
        data: {
          ent_id: this.$ent_id(),
          role_name: this.juese_sousuo
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10027) {
          console.log(JSON.parse(res.data.body.data))
          const date = JSON.parse(res.data.body.data)
          this.qiye_juesery_list = date
        } else if (res.data.code == 10028) {
        }
      })
    },
    // 点击全选
    dianji_quanxuan () {
      this.renyuan_quanxuan = !this.renyuan_quanxuan
      if (this.renyuan_quanxuan) {
        this.qiye_renyuan_list.map(item => {
          item.zhi = true
          this.qiye_renyuan_list_xuan.push(item)
        })
        this.qiye_renyuan_list_xuan = this.$func.Es5duplicate(this.qiye_renyuan_list_xuan, 'staff_id')
      } else {
        this.qiye_renyuan_list.map(item => { item.zhi = false })
        for (let i = 0; i < this.qiye_renyuan_list.length; i++) {
          for (let a = 0; a < this.qiye_renyuan_list_xuan.length; a++) {
            if (this.qiye_renyuan_list[i].staff_id == this.qiye_renyuan_list_xuan[a].staff_id) {
              this.qiye_renyuan_list_xuan.splice(a, 1)
            }
          }
        }
      }
    },
    panduan_yixuan () {
      const list = []
      for (let i = 0; i < this.qiye_renyuan_list.length; i++) {
        for (let a = 0; a < this.qiye_renyuan_list_xuan.length; a++) {
          if (this.qiye_renyuan_list[i].staff_id == this.qiye_renyuan_list_xuan[a].staff_id) {
            this.qiye_renyuan_list[i].zhi = true
            list.push('1111')
          }
        }
      }
      if (this.qiye_renyuan_list.length != 0) {
        if (list.length == this.qiye_renyuan_list.length) {
          this.renyuan_quanxuan = true
        } else {
          this.renyuan_quanxuan = false
        }
      }
    },
    dianji_xuanren (i) {
      i.zhi = !i.zhi
      if (i.zhi) {
        this.qiye_renyuan_list_xuan.push(i)
      } else {
        for (let a = 0; a < this.qiye_renyuan_list_xuan.length; a++) {
          if (i.staff_id == this.qiye_renyuan_list_xuan[a].staff_id) {
            this.qiye_renyuan_list_xuan.splice(a, 1)
          }
        }
      }
      this.panduan_shifou_quanxuan()
    },
    panduan_shifou_quanxuan () {
      const list = []
      console.log(this.qiye_renyuan_list)
      for (let i = 0; i < this.qiye_renyuan_list.length; i++) {
        if (this.qiye_renyuan_list[i].zhi) {
          list.push('1111')
        }
      }
      if (this.qiye_renyuan_list.length != 0) {
        if (list.length == this.qiye_renyuan_list.length) {
          this.renyuan_quanxuan = true
        } else {
          this.renyuan_quanxuan = false
        }
      }
    },
    youce_guanlian_zuoce () {
      // qiye_renyuan_list
      // qiye_renyuan_list_xuan
      for (let i = 0; i < this.qiye_renyuan_list_xuan.length; i++) {
        for (let a = 0; a < this.qiye_renyuan_list.length; a++) {
          if (this.qiye_renyuan_list[a].staff_id == this.qiye_renyuan_list_xuan[i].staff_id) {
            this.qiye_renyuan_list[a].zhi = true
          }
        }
      }
      console.log(this.qiye_renyuan_list)
      console.log(this.qiye_renyuan_list_xuan)
      this.panduan_shifou_quanxuan()
    },
    // xuanze_juese(data,checked, node){
    //     if(checked){
    //         this.$refs.shenpi_juese_tree.setCheckedNodes([data]);
    //         this.qiye_juesery_list_xuan=this.$refs.shenpi_juese_tree.getCheckedNodes()
    //     }
    // },
    // 发起人自选   单个人  多个人
    changeUserNum (date) {
      this.approverConfig.nodeUserList = []
    },
    changeArea (date) {
      this.approverConfig.nodeUserList = []
    },
    // 根据右侧展示左边选中角色
    zhanshi_juese_zuoce () {
      if (this.qiye_juesery_list_xuan.length != 0) {
        for (let i = 0; i < this.qiye_juesery_list.length; i++) {
          if (this.qiye_juesery_list[i].role_id == this.qiye_juesery_list_xuan[0].targetId) {
            this.juese_num = i
          }
        }
      } else {
        this.juese_num = -1
      }
    },
    dianji_tiaojian_bumen (i) {
      console.log(i)
      this.mianbao_tiaojian_list.push({
        name: i.name,
        dept_id: i.targetId
      })
      this.bumen_ren_jiekou()
    },
    bumen_ren_jiekou () {
      query_ent_dept_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          parent_id: this.mianbao_tiaojian_list[this.mianbao_tiaojian_list.length - 1].dept_id,
          active: '1'
        }
      }).then(res => {
        if (res.data.code == 10168) {
          var date = JSON.parse(res.data.body.data)
          date.map(item => {
            this.$set(item, 'targetId', item.dept_id)
            this.$set(item, 'name', item.dept_name)
            this.$set(item, 'type', '1')
            this.$set(item, 'zhi', false)
          })
          this.faqi_bumen_list = date
          query_dept_staff_list({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id,
              dept_id: this.mianbao_tiaojian_list[this.mianbao_tiaojian_list.length - 1].dept_id,
              active: '1'
            }
          }).then(ras => {
            console.log(ras)
            if (ras.data.code == 10149) {
              const listdata = JSON.parse(ras.data.body.data)
              console.log(99999, listdata)
              listdata.map(itam => {
                this.$set(itam, 'targetId', itam.staff_id)
                this.$set(itam, 'name', itam.staff_name)
                this.$set(itam, 'type', '2')
                this.$set(itam, 'zhi', false)
              })
              this.faqi_bumenren_list = listdata
              this.tiaojian_youce_guanlian_zuoce()
            } else if (ras.data.code == 10150) {
            }
          })
        } else if (res.data.code == 10169) {
        }
      })
    },
    tiaojian_youce_guanlian_zuoce () {
      console.log(this.qiye_renyuan_list_xuan)
      for (let i = 0; i < this.qiye_renyuan_list_xuan.length; i++) {
        for (let a = 0; a < this.faqi_ren_list.length; a++) {
          if (this.faqi_ren_list[a].targetId == this.qiye_renyuan_list_xuan[i].targetId) {
            this.faqi_ren_list[a].zhi = true
          }
        }
        for (let a = 0; a < this.faqi_bumenren_list.length; a++) {
          if (this.faqi_bumenren_list[a].targetId == this.qiye_renyuan_list_xuan[i].targetId) {
            this.faqi_bumenren_list[a].zhi = true
          }
        }
        for (let a = 0; a < this.faqi_bumen_list.length; a++) {
          if (this.faqi_bumen_list[a].targetId == this.qiye_renyuan_list_xuan[i].targetId) {
            this.faqi_bumen_list[a].zhi = true
          }
        }
      }
    },
    mianbao_tiaojian_dian (i, index) {
      if (index == 0) {
        var list = this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi_flowPermission', '暂时')
        if (list == undefined) {
          this.biao_list = []
        } else {
          this.biao_list = this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi_flowPermission', '暂时')
        }
        list.map(item => {
          this.$set(item, 'zhi', false)
        })
        const lista = []
        const listb = []
        for (let a = 0; a < list.length; a++) {
          if (list[a].type == 1) {
            lista.push(list[a])
          } else if (list[a].type == 2) {
            listb.push(list[a])
          }
        }
        this.faqi_ren_list = listb
        this.faqi_bumenren_list = []
        this.faqi_bumen_list = lista
        this.mianbao_tiaojian_list = [
          {
            name: JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name,
            dept_id: '0'
          }
        ]
        this.tiaojian_youce_guanlian_zuoce()
      } else if (this.mianbao_tiaojian_list.length - 1 != 0) {
        this.mianbao_tiaojian_list.splice(index + 1, this.mianbao_tiaojian_list.length - 1)
        this.bumen_ren_jiekou()
      }
    },
    wuyong () {}
  }
}
</script>
<style  scoped lang="scss">
.error_tip {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(150%, 0);
    font-size: 0.24rem;

}
.el-drawer__wrapper{
  overflow: auto !important;
  overflow: visible!important;
}
.add-node-popover-body {
    display: flex;
}
.promoter_content {
    padding: 0 0.2rem;
}
.drawer_content_tit{
    ul{
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f2f2f2;
        li{
            font-size: 0.12rem;
            padding: 0.1rem 0.15rem;
            cursor: pointer;
        }
        .shenpi_ren_zise{
            color:#9b84dc;
            border-bottom: 3px solid #9b84dc;
        }
    }
}
.condition_content .el-button,
.copyer_content .el-button,
.approver_self_select .el-button,
.promoter_content .el-button,
.approver_content .el-button {
    margin-bottom: 0.2rem;
}

.promoter_content p {
    padding: 0.18rem 0;
    font-size: 0.14rem;
    line-height: 0.2rem;
    color: #000000;
}
.promoter_person .el-dialog__body {
    padding: 0.1rem 0.2rem 0.14rem 0.2rem;
}
.person_body {
    border: 0.01rem solid #f5f5f5;
    height: 5rem;
}
.person_tree {
    padding: 0.1rem 0.12rem 0 0.08rem;
    width: 2.8rem;
    height: 100%;
    border-right: 0.01rem solid #f5f5f5;
}
.person_tree input {
    padding-left: 0.22rem;
    width: 2.1rem;
    height: 0.3rem;
    font-size: 0.12rem;
    border-radius: 0.02rem;
    border: 0.01rem solid #d5dadf;
    background: url(~@/assets/images/list_search.png) no-repeat 0.1rem center;
    background-size: 0.14rem 0.14rem;
    margin-bottom: 0.14rem;
}
.tree_nav span {
    display: inline-block;
    padding-right: 0.1rem;
    margin-right: 0.05rem;
    max-width: 6em;
    color: #38adff;
    font-size: 0.12rem;
    cursor: pointer;
    background: url(~@/assets/images/jiaojiao.png) no-repeat right center;
}
.tree_nav span:last-of-type {
    background: none;
}
.person_tree ul,
.has_selected ul {
    height: 4.2rem;
    overflow-y: auto;
}
.person_tree li {
    padding: 0.05rem 0;
}
.person_tree li i {
    float: right;
    padding-left: 0.24rem;
    padding-right: 0.1rem;
    color: #3195f8;
    font-size: 0.12rem;
    cursor: pointer;
    background: url(~@/assets/images/next_level_active.png) no-repeat 0.1rem
        center;
    border-left: 0.01rem solid rgb(238, 238, 238);
}
.person_tree li a.active + i {
    color: rgb(197, 197, 197);
    background-image: url(~@/assets/images/next_level.png);
    pointer-events: none;
}
.person_tree img {
    width: 0.14rem;
    vertical-align: middle;
    margin-right: 0.05rem;
}
.has_selected {
    width: 2.76rem;
    height: 100%;
    font-size: 0.12rem;
}
.has_selected ul {
    height: 4.6rem;
}
.has_selected p {
    padding-left: 0.19rem;
    padding-right: 0.2rem;
    line-height: 0.37rem;
    border-bottom: 0.01rem solid #f2f2f2;
}
.has_selected p a {
    float: right;
}
.has_selected ul li {
    margin: 0.11rem 0.26rem 0.13rem 0.19rem;
    line-height: 0.17rem;
}
.has_selected li span {
    vertical-align: middle;
}
.has_selected li img:first-of-type {
    width: 0.14rem;
    vertical-align: middle;
    margin-right: 0.05rem;
}
.has_selected li img:last-of-type {
    float: right;
    margin-top: 0.02rem;
    width: 0.14rem;
}
el-radio-group {
    padding: 0.2rem 0;
}
.approver_content {
    padding-bottom: 0.1rem;
    border-bottom: 0.01rem solid #f2f2f2;
}
.approver_content .el-radio,
.approver_some .el-radio,
.approver_self_select .el-radio {
    width: 27%;
    margin-bottom: 0.2rem;
}
.copyer_content .el-checkbox {
    margin-bottom: 0.2rem;
}
.el-checkbox__label {
    font-size: 0.12rem;
}
.condition_content,
.copyer_content,
.approver_self_select,
.approver_manager,
.approver_content,
.approver_some {
    padding: 0.2rem 0.2rem 0;
}
.approver_manager p:first-of-type,
.approver_some p {
    line-height: 0.19rem;
    font-size: 0.14rem;
    margin-bottom: 0.14rem;
}
.biaodan_kongjian_quanxuan{
    .biaodan_kongjian_quanxuan_ul{
        .biaodan_kongjian_quanxuan_li{
            padding: 0.1rem 0.3rem;
            ul{
                display: flex;
                align-items: center;
                // justify-content: space-between;
                li{
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    &:nth-child(1){
                        width:40%;
                    }
                    &:nth-child(2),&:nth-child(3),&:nth-child(4){
                        width:20%;
                    }
                    img{
                        margin-right: 0.1rem;
                    }
                }
            }
        }
    }
}

.approver_manager p {
    line-height: 0.32rem;
}
.approver_manager select {
    width: 4.2rem;
    height: 0.32rem;
    background: rgba(255, 255, 255, 1);
    border-radius: 0.04rem;
    border: 0.01rem solid rgba(217, 217, 217, 1);
}
.approver_manager .zixuan select {
    width: 1.6rem;
    height: 0.32rem;
    background: rgba(255, 255, 255, 1);
    border-radius: 0.04rem;
    border: 0.01rem solid rgba(217, 217, 217, 1);
}
.approver_manager p.tip {
    margin: 0.1rem 0 0.22rem 0;
    font-size: 0.12rem;
    line-height: 0.16rem;
    color: #f8642d;
}
.approver_self {
    padding: 0.28rem 0.2rem;
}
.selected_list {
    margin-bottom: 0.2rem;
    line-height: 0.3rem;
}
.selected_list span {
    margin-right: 0.1rem;
    padding: 0.03rem 0.06rem 0.03rem 0.09rem;
    line-height: 0.3rem;
    white-space: nowrap;
    border-radius: 0.02rem;
    border: 0.01rem solid rgba(220, 220, 220, 1);
}
.selected_list img {
    margin-left: 0.05rem;
    width: 0.07rem;
    height: 0.07rem;
    cursor: pointer;
}
.approver_self_select h3 {
    margin: 0.05rem 0 0.2rem;
    font-size: 0.14rem;
    font-weight: bold;
    line-height: 0.19rem;
}
.condition_copyer .el-drawer__body .youxian_ji {
    position: absolute;
    top: 0.11rem;
    right: 0.3rem;
    width: 1rem;
    height: 0.32rem;
    background: rgba(255, 255, 255, 1);
    border-radius: 0.04rem;
    border: 0.01rem solid rgba(217, 217, 217, 1);
}
.condition_content p.tip {
    margin: 0.2rem 0;
    width: 5.1rem;
    text-indent: 0.17rem;
    line-height: 0.45rem;
    background: rgba(241, 249, 255, 1);
    border: 0.01rem solid rgba(64, 163, 247, 1);
    color: #46a6fe;
    font-size: 0.14rem;
}
.condition_content ul {
    max-height: 5rem;
    overflow-y: scroll;
    margin-bottom: 0.2rem;
}
.condition_content li > span {
    float: left;
    margin-right: 0.08rem;
    width: 0.7rem;
    line-height: 0.32rem;
    text-align: right;
}
.condition_content li > div {
    display: inline-block;
    width: 3.7rem;
}
.condition_content li:not(:last-child) > div > p {
    margin-bottom: 0.2rem;
}
.condition_content li > div > p:not(:last-child) {
    margin-bottom: 0.1rem;
}
.condition_content li > a {
    float: right;
    margin-right: 0.1rem;
    margin-top: 0.07rem;
}
.condition_content li select,
.condition_content li input {
    width: 100%;
    height: 0.32rem;
    background: rgba(255, 255, 255, 1);
    border-radius: 0.04rem;
    border: 0.01rem solid rgba(217, 217, 217, 1);
}
.condition_content li select + input {
    width: 2.6rem;
}
.condition_content li select {
    margin-right: 0.1rem;
    width: 1rem;
}
.condition_content li p.selected_list {
    padding-left: 0.1rem;
    border-radius: 0.04rem;
    min-height: 0.32rem;
    border: 0.01rem solid rgba(217, 217, 217, 1);
}
.condition_content li p.check_box {
    line-height: 0.32rem;
}
.condition_list .el-dialog__body {
    padding: 0.16rem 0.26rem;
}
.condition_list p {
    color: #666666;
    margin-bottom: 0.1rem;
}
.condition_list p.check_box {
    margin-bottom: 0;
    line-height: 0.36rem;
}
.tiao_bumen{
    display: flex;
    align-items: center;
    justify-content:center;
    .xuanze,.yixuan{
        height:3.54rem;
        width:3.85rem;
        background: #E7E8EB;
        margin: 0 0.11rem;
        margin-top: 0.2rem;
        position: relative;
        overflow-y:auto;
        &::-webkit-scrollbar {
            width: 0.2rem;
            height: 0.08rem;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0.12rem;
            border: 0.06rem solid rgba(0, 0, 0, 0);
            box-shadow: 0.08rem 0 0 #A5ADB7 inset;
        }

        &::-webkit-scrollbar-thumb:hover {
            box-shadow: 0.08rem 0 0 #4A4A4A inset;
        }
        .el-tree{
            background: none;
        }
        .tit{
            position: absolute;
            left:0;
            top:-0.3rem;
            font-size: 0.14rem;
            color:#1A2533;
        }
        .xuanze_sousuo{
            display: flex;
            align-items: center;
            justify-content: space-around;

            i{
              padding-top: 15px;
              padding-left: 10px;
            }
            input{
                height:0.3rem;
                width:0.74rem;
                border:none;
                background:#fff;
                padding: 0 0.33rem;
                margin: 0 auto;
                display: block;
                margin-top: 0.15rem;
                border-radius:0.02rem;
            }
        }
        .mianbaoxie{
            display: flex;
            // align-items: center;
            // justify-content: left;
            flex-wrap: wrap;
            margin-bottom: 0.1rem;
            margin-left: 0.23rem;
            margin-top:0.12rem;
            div{
                display: flex;
                align-items: center;
                cursor:pointer;
                p{
                    font-size: 0.14rem;
                }
                .p2{
                    color:#F96C6C;
                }
                .p1{
                    color:#1A2533;
                }
            }
        }
        .quanxuan{
            display: flex;
            align-items: center;
            margin-left: 0.24rem;
            input{
                -webkit-appearance: none;
                width: 0.18rem!important;
                height: 0.17rem!important;
                display: inline-block;
                margin: -0.03rem 0.05rem 0 0!important;
                cursor: pointer;
                vertical-align: middle;
                background:url(../assets/noccc.png) no-repeat;
                &:checked {
                    background: url(../assets/okblue.png) no-repeat;
                }
                outline-color:none;
                outline-style :none;
                outline-width:none;
            }
            span{
                color:#1A2533;
                font-size: 0.12rem;
            }
        }
        .nei_box{
            width:3.45rem;
            margin: 0 auto;
            max-height: 2.4rem;
            overflow:auto;
            // overflow-y: hidden;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 0.19rem;
                height: 0;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 0.12rem;
                border: 0.06rem solid rgba(0, 0, 0, 0);
                box-shadow: 0.08rem 0 0 #A5ADB7 inset;
            }

            &::-webkit-scrollbar-thumb:hover {
                box-shadow: 0.08rem 0 0 #4A4A4A inset;
            }
            .liebiao{
                width:3.25rem;
                margin: 0 auto;
                ul{
                    li{
                        display: flex;
                        height:0.3rem;
                        justify-content: space-between;
                        align-items: center;
                        color:#1A2533;
                        font-size: 0.12rem;
                        padding-left: 0.1rem;
                        cursor:pointer;
                        &:hover{
                            background: #eee;
                        }
                        .img1{
                            height:0.11rem;
                            width:0.2rem;
                        }
                        input{
                            -webkit-appearance: none;
                            width: 0.18rem!important;
                            height: 0.17rem!important;
                            display: inline-block;
                            margin: -0.03rem 0.05rem 0 0!important;
                            cursor: pointer;
                            vertical-align: middle;
                            background:url(../assets/noccc.png) no-repeat;
                            &:checked {
                                background: url(../assets/okblue.png) no-repeat;
                            }
                            outline-color:none;
                            outline-style :none;
                            outline-width:none;
                        }
                        .img2{
                            height:0.18rem;
                            width:0.18rem;
                            cursor: pointer;
                            margin: 0 0.05rem 0 0;
                        }
                    }
                }
            }
        }
    }
    .yixuan{
        .nei_box{
            width:3.45rem;
            margin: 0 auto;
            max-height: 3.5rem;
            overflow:auto;
            // overflow-y: hidden;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 0.19rem;
                height: 0;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 0.12rem;
                border: 0.06rem solid rgba(0, 0, 0, 0);
                box-shadow: 0.08rem 0 0 #A5ADB7 inset;
            }

            &::-webkit-scrollbar-thumb:hover {
                box-shadow: 0.08rem 0 0 #4A4A4A inset;
            }
            .liebiao{
                width:3.25rem;
                padding: 0.2rem 0;
                margin: 0 auto;
                ul{
                    li{
                        display: flex;
                        height:0.3rem;
                        justify-content: space-between;
                        align-items: center;
                        color:#1A2533;
                        font-size: 0.12rem;
                        padding-left: 0.1rem;
                        cursor:pointer;
                        &:hover{
                            background: #eee;
                        }
                        .img1{
                            height:0.11rem;
                            width:0.2rem;
                        }
                        input{
                            -webkit-appearance: none;
                            width: 0.18rem!important;
                            height: 0.17rem!important;
                            display: inline-block;
                            margin: -0.03rem 0.05rem 0 0!important;
                            cursor: pointer;
                            vertical-align: middle;
                            background:url(../assets/noccc.png) no-repeat;
                            &:checked {
                                background: url(../assets/okblue.png) no-repeat;
                            }
                            outline-color:none;
                            outline-style :none;
                            outline-width:none;
                        }
                    }
                }
            }
        }
    }
}
.foote{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.21rem;
    p{
        margin: 0 0.23rem;
        height:0.43rem;
        width:1.36rem;
        border-radius: 0.02rem;
        font-size: 0.14rem;
        line-height: 0.43rem;
        text-align: center;
        font-weight: bold;
        cursor:pointer;
    }
    .queding{
        color:#fff;
        background: #F96C6C;
    }
    .quxiao{
        color: #888888;
        border:0.01rem solid #888888;
        background: #fff;
    }
}
</style>
