import service from "./ajax";
//登录的时候掉的
export function trigger_backup_num(data) {
  return service({
    method: 'post',
    url: "/trigger_backup_num",
    data
  })
}
// 30秒调一次
export function brower_visit_server(data) {
  return service({
    method: 'post',
    url: "/brower_visit_server",
    data
  })
}
// 展示流程
export function query_form_appr_flow_list(data) {
  return service({
    method: 'post',
    url: "/query_form_appr_flow_list",
    data
  })
}
// 获取相关企业信息
export function query_user_ent_list(data) {
  return service({
    method: 'post',
    url: "/query_user_ent_list",
    data
  })
}
//登录15秒掉接口
export function single_login_check(data) {
  return service({
    method: 'post',
    url: "/single_login_check",
    data
  })
}
//产品类的接口
export function query_category_info_list(data) {
  return service({
    method: 'post',
    url: "/query_category_info_list",
    data
  })
}
//查询表单审批流程的设置
export function query_form_appr_set(data) {
  return service({
    method: 'post',
    url: "/query_form_appr_set",
    data
  })
}