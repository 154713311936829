/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 14:21:48
 * @LastEditTime: 2019-10-12 14:15:22
 * @LastEditors: Please set LastEditors
 */
import axios from 'axios';
import { showLoading, hideLoading } from '../Js/loading';

var _this=this
// //自定义配置创建axios的新实例
const service = axios.create({
  //  baseURL:'api/hwb_client/V1.0.0',
  // https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/pwd_login
  // baseURL:'https://www.mlcbr.com:8443/hwb_client/V1.0.0/',//正式服务器

  // baseURL: process.env.VUE_APP_BASE_API,//测试服务器
  //baseURL: 'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0', // 测试服务器
 // baseURL: process.env.VUE_APP_BASE_API,//测试服务器
  //baseURL: 'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0', // 测试服务器
  //baseURL: process.env.VUE_APP_BASE_API,//测试服务器
  baseURL:'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0',//服务器2号机
  timeout: 1200000,
  withCredentials: true
})
// 没用，为了隐藏
// function aa(){
  //  service.interceptors.request.use(
  //   config=>{
  //     showLoading()
  //     return config;
  //   }
  // )
  // // 请求拦截器,发起请求
  service.interceptors.request.use(
    config => {
      // loading.open()
      // if(sessionStorage.getItem('pc_mlbb_xinxi')!=undefined){
      //   if (JSON.parse(JSON.parse(sessionStorage.getItem('pc_mlbb_xinxi'))).client_login_token) {
      //     config.headers.token = JSON.parse(JSON.parse(sessionStorage.getItem('pc_mlbb_xinxi'))).client_login_token
      //     config.headers['Content-Type'] = 'application/json;charset=UTF-8'
      //   }
      // }
      // setTimeout(()=>{
        showLoading()
      // },3000)
      return config;
    }, err => {
      return Promise.reject(err)
    })
  // //响应拦截器,响应回来
  service.interceptors.response.use(
    response => {
      // console.log(response)
      // let date=response.data.code
      // console.log(date)
      hideLoading();
      return response;
    }, err => {
      hideLoading();
      return Promise.reject(err);
    }
  )
// }


export default service;
