import Vue from 'vue'
import App from './App.vue'
import router from './router'
import animated from 'animate.css' // npm install animate.css --save安装，再引入
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
// import '@css/style.scss'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import '@css/element-variables.scss'
import './Js/three.js'
import './Js/fangdou'
import 'vue-directive-image-previewer/dist/assets/style.css'
import ent from './entId'
import user from './userId'
import qianwei from './Js/qianweifu'
import shuzi from './Js/shuzi'
import quchong from './Js/quchong'
import paixu from './Js/paixu'
import chinepaixu from './Js/chinepaixu'
import bendi_cunfang_cunchu from './Js/bendicunchu'
import bendi_naqu_cunchu from './Js/naqucunchu'
import addNode from '@/components/addNode'
import echarts from 'echarts'
import '@/assets/remziti.js'
import VueAreaLinkage from 'vue-area-linkage'
import 'animate.css'
import 'bootstrap'
import './plugins/element.js'
import './Js/lasttime.js'
// import './Js/guanbi.js'
// import 'lib-flexible/flexible'
import './css/override-element-ui.css'
import func from './plugins/preload.js'
import ydc from './plugins/common.js'
import drag from './Js/tuozhuai'
import nodeWrap from '@/components/nodeWrap'
import VDistpicker from 'v-distpicker'
Vue.prototype.$echarts = echarts
window.drag = new Vue()
Vue.use(ElementUI)
Vue.use(addNode)
Vue.use(VueAreaLinkage)
Vue.use(VueDirectiveImagePreviewer)
Vue.use(animated)
Vue.use(nodeWrap)
Vue.component('v-distpicker', VDistpicker)

Vue.component('nodeWrap', nodeWrap) // 初始化组件

Vue.component('addNode', addNode) // 初始化组件
Vue.prototype.$func = func
Vue.prototype.$ydc = ydc

Vue.directive('enterNumber', {
  bind: function (el, { value = 2 }) {
    el = el.nodeName == 'INPUT' ? el : el.children[0]
    var RegStr = value == 0 ? '^[\\+\\-]?\\d+\\d{0,0}' : `^[\\+\\-]?\\d+\\.?\\d{0,${value}}`
    el.addEventListener('keyup', function () {
      el.value = el.value.match(new RegExp(RegStr, 'g'))
      el.dispatchEvent(new Event('input'))
    })
  }
})

Vue.directive('focus', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function (el) {
    el.focus()
  }
})

Vue.prototype.$ent_id = ent
Vue.prototype.$jichuxinxi = user
Vue.prototype.$qianwei = qianwei
Vue.prototype.$shuzi = shuzi
Vue.prototype.$quchong = quchong
Vue.prototype.$paixu = paixu
Vue.prototype.$chinepaixu = chinepaixu
Vue.prototype.$bendi_cunfang_cunchu = bendi_cunfang_cunchu
Vue.prototype.$bendi_naqu_cunchu = bendi_naqu_cunchu
Vue.config.productionTip = false

new Vue({
  router,
  store,
  components: {
    App
  },
  render: h => h(App)
}).$mount('#app')
