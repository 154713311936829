<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { logout } from './api/api'
export default {
  name: 'App',
  data () {
    return {
      _beforeUnload_time: 0,
      _gap_time: 0,
      is_fireFox: navigator.userAgent.indexOf('Firefox') > -1
    }
  },
  beforeCreate () {
    let count = 0
    const imgs = [
      require('../src/assets/wai_daohang/background_img3.png'), require('../src/assets/wai_daohang/background_img4.png')
    ]
    for (const img of imgs) {
      const image = new Image()
      image.src = img
      image.onload = () => {
        count++
      }
    }
  },
  created () {
    this.jichu()
  },
  mounted () {
    // window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
    //   window.addEventListener('unload', e => this.unloadHandler(e))

    // -----
    // window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
    //   window.addEventListener('unload', e => this.unloadHandler(e))
  },

  methods: {
    jichu () {
      const date = sessionStorage.getItem('pc_mlbb_jichuxinxi')
      if (date != undefined && JSON.parse(JSON.parse(date)).mobile != undefined) {

      } else {
        this.$router.push('/')
      }
    }
    // beforeunloadHandler(){
    //   this._beforeUnload_time=new Date().getTime();
    // },
    // unloadHandler(e){
    //   this._gap_time=new Date().getTime()-this._beforeUnload_time;
    //
    //   //判断是窗口关闭还是刷新
    //   if(this._gap_time<=5){
    //     //如果是登录状态，关闭窗口前，移除用户
    //     if(sessionStorage.getItem('pc_mlbb_jichuxinxi')!=undefined&&sessionStorage.getItem('pc_mlbb_jichuxinxi')!=undefined){
    //       let aa=JSON.parse(localStorage.getItem("phone_pc"))
    //       if(!this.showLoginButton){
    //         let data={
    //             'data':{
    //                 'mobile':aa
    //             }
    //         }
    //         let date=JSON.stringify(data)
    //         $.ajax({
    //           url: 'https://www.mlcbr.com/hwb_client/V1.0.0/logout',
    //           type:'POST',
    //           dataType:'json',
    //           contentType:'application/json;charset=UTF-8',
    //           async:false, //或false,是否异步
    //           data:date
    //         }).then(res=>{

    //         })
    //       }
    //     }
    //   }
    // }

    // ---------
    // beforeunloadHandler(){
    //     this._beforeUnload_time=new Date().getTime();
    //   },
    //   unloadHandler(e){
    //     this._gap_time=new Date().getTime()-this._beforeUnload_time;

    //     //判断是窗口关闭还是刷新
    //     if(this._gap_time<=5){
    //       //如果是登录状态，关闭窗口前，移除用户
    //       if(!this.showLoginButton){
    //
    //         if(sessionStorage.getItem('pc_mlbb_jichuxinxi')!=undefined&&sessionStorage.getItem('pc_mlbb_jichuxinxi')!=undefined){
    //           let aa=JSON.parse(localStorage.getItem("phone_pc"))
    //           let data={
    //               'data':{
    //                   'mobile':aa
    //               }
    //           }
    //           let date=JSON.stringify(data)
    //           $.ajax({
    //             url: 'https://www.mlcbr.com/hwb_client/V1.0.0/logout',
    //             type:'POST',
    //             dataType:'json',
    //             contentType:'application/json;charset=UTF-8',
    //             async:false, //或false,是否异步
    //             data:date
    //           })
    //         }
    //       }
    //     }
    //   },
  },
  destroyed () {
    // window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    //   window.removeEventListener('unload', e => this.unloadHandler(e))

    // -----
    // window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    //   window.removeEventListener('unload', e => this.unloadHandler(e))
  }
}
</script>

<style lang="scss">
*{
  margin:0;
  padding: 0;
  font-family: "微软雅黑";
  font-size: 0.12rem;
}
html, body{
    width: 100%;
    height: 100%;
    overflow-x: hidden;   //全局的X轴的溢出隐藏
}
 .v-modal{
   display:none!important;
 }
a,s{text-decoration: none;}
li{list-style: none;}
// .el-input__inner{
//   height:0.44rem;
//   width:6.45rem;
//   border:0.01rem solid #979797;
//   border-radius:0.06rem;
//   line-height: 0.44rem;
// }

.el-input__icon{
  line-height: 0.44rem;
}
//.el-input{width:6.45rem;}
.el-form-item__label{width:2rem;color:#676669;font-size: 0.18rem;font-weight: 500;line-height: 0.44rem;margin-right: 0.2rem;}
.iiinput .el-input__inner{
  width:3.6rem;
  margin-left: -2.86rem;
}
.iiinput .el-form-item .el-form-item__content .el-input .el-input__suffix .el-input__suffix-inner .el-input__count{
  position: relative;
  left: -2.9rem;
}
//li
.appdaochu .el-input--suffix .appdaochu .el-input__inner{
  font-size: 10px;
  height: 30px;
}

.appdaochu .el-input__prefix,.appdaochu .el-input__suffix{
  top: -4px!important;
  height: auto!important;
}

// .el-scrollbar__wrap{
//   width:1.45rem;
// }
.el-select-dropdown__item{
  padding: 0 0.1rem;
}
.el-drawer__header>:first-child:focus{
  outline: none;
}
.el-drawer__header{font-size: 0.18rem;color:#1A2533;font-weight: bold;}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none!important;
}
input[type="number"]{-moz-appearance:textfield;}
.liebiao{
  margin-top: 0.45rem;
  width: 5.6rem;
    .dd1{
        font-size: 0.14rem;
        color:#4C4A4D;
        line-height: 0.44rem;
        margin-right:0.18rem;
        margin-left: 0.2rem;
        margin-bottom: 0.45rem;
        width: 21%;
        text-align: right;
        float: left;
        span{
            font-size: 0.14rem;
            color:#F96C6C;
            line-height: 0.44rem;
        }
    }
    .dd2{
        float: left;
        width:72%;
        margin-bottom: 0.45rem;
        position: relative;
        p{
          font-size: 0.14rem;
          color:#4C4A4D;
          line-height: 0.44rem;
          cursor: pointer;
        }
        input{
            height:0.44rem;
            line-height: 0.44rem;
            width:2.6rem;
            border-radius: 0.06rem;
            border:0.01rem solid #979797;
            padding: 0 0.2rem;
            color: #B1B1B1;
            &::-webkit-input-placeholder {
              color: #B1B1B1;
            }
            &:-moz-placeholder {
              color: #B1B1B1;
            }
            &::-moz-placeholder {
              color: #B1B1B1;
            }
            &:-ms-input-placeholder {
              color: #B1B1B1;
            }
        }
        .area-select.large{
            height:0.44rem;
            width:2.8rem;
            border-radius: 0.06rem;
            border:0.01rem solid #979797;
            padding: 0 0.1rem;
            line-height: 0.27rem;
            color: #B1B1B1;
            &::-webkit-input-placeholder {
              color: #B1B1B1;
            }
            &:-moz-placeholder {
              color: #B1B1B1;
            }
            &::-moz-placeholder {
              color: #B1B1B1;
            }
            &:-ms-input-placeholder {
              color: #B1B1B1;
            }
        }
        .el-input__inner{
          width:3rem;
          color: #B1B1B1;
          &::-webkit-input-placeholder {
            color: #B1B1B1;
          }
          &:-moz-placeholder {
            color: #B1B1B1;
          }
          &::-moz-placeholder {
            color: #B1B1B1;
          }
          &:-ms-input-placeholder {
            color: #B1B1B1;
          }
          .el-el-input__icon .element_tu{
            margin-left: 1rem;
          }
        }
        .el-date-editor.el-input, .el-date-editor.el-input__inner {
            width: 3rem;
        }
        .element_tu{
          display: none;
        }
        .el-input{
          width:3rem;
        }
        .shuoming_wenzi{
          height:1rem;
          width:2.6rem;
          border-radius: 0.06rem;
          border:0.01rem solid #979797;
          padding: 0 0.2rem;
          color: #B1B1B1;
        }
        .el-icon-circle-close{
          position: absolute;
          font-size: 0.16rem;
          top:50%;
          margin-top: -0.08rem;
          // line-height: 0.4rem !important;
          right: 1.2rem;
          cursor: pointer;
          display: none;
        }
        &:hover{
          .el-icon-circle-close{
            display: block;
          }
        }
        .baifen_hao{
          position: absolute;
          right:0.8rem;
          line-height: 0.46rem;
        }
        .shanchu{
          position: absolute;
          left: 1.05rem;
          top:-0.05rem;
          cursor: pointer;
          font-size: 0.16rem;
        }
        .tupian_kuang{
          position: relative;
          height: 1rem;
          width: 1rem;
          text-align: center;
          .img_1 {
            height: 0.96rem;
            width: 0.96rem;
            cursor: pointer;
          }
          input {
            position: absolute;
            left: 0;
            height: 1rem!important;
            width: 1rem!important;
            opacity: 0;
            padding: 0!important;
            cursor: pointer;
          }
        }
    }
    .dd3{
        font-size: 0.14rem;
        color:#4C4A4D;
        line-height: 0.44rem;
        margin-right:0.18rem;
        margin-left: 0.2rem;
        margin-bottom: 0.45rem;
        width: 0.95rem;
        text-align: right;
        float: left;
        span{
            font-size: 0.14rem;
            color:#F96C6C;
            line-height: 0.44rem;
        }
    }
    .dd4{
        float: left;
        width:3.5rem;
        margin-bottom: 1.15rem;
        position: relative;
        input{
            height:0.44rem;
            width:2.6rem;
            border-radius: 0.06rem;
            border:0.01rem solid #979797;
            padding: 0 0.2rem;
            color: #B1B1B1;
            &::-webkit-input-placeholder {
              color: #B1B1B1;
            }
            &:-moz-placeholder {
              color: #B1B1B1;
            }
            &::-moz-placeholder {
              color: #B1B1B1;
            }
            &:-ms-input-placeholder {
              color: #B1B1B1;
            }
        }
        .area-select.large{
            height:0.44rem;
            width:2.8rem;
            border-radius: 0.06rem;
            border:0.01rem solid #979797;
            padding: 0 0.1rem;
            line-height: 0.3rem;
            color: #B1B1B1;
            &::-webkit-input-placeholder {
              color: #B1B1B1;
            }
            &:-moz-placeholder {
              color: #B1B1B1;
            }
            &::-moz-placeholder {
              color: #B1B1B1;
            }
            &:-ms-input-placeholder {
              color: #B1B1B1;
            }
        }
        .el-input__inner{
          width:3rem;
          color: #B1B1B1;
          &::-webkit-input-placeholder {
            color: #B1B1B1;
          }
          &:-moz-placeholder {
            color: #B1B1B1;
          }
          &::-moz-placeholder {
            color: #B1B1B1;
          }
          &:-ms-input-placeholder {
            color: #B1B1B1;
          }
          .el-el-input__icon .element_tu{
            margin-left: 1rem;
          }
        }
        .el-date-editor.el-input, .el-date-editor.el-input__inner {
            width: 3rem;
        }
        .element_tu{
          display: none;
        }
        .el-input{
          width:3rem;
        }
    }
    .dd5{
      float: left;
      width:3.5rem;
      margin-bottom: 0.45rem;
      color:#1A2533;
      font-size: 0.18rem;
      display: flex;
      margin-top: 0.1rem;
      input{
          -webkit-appearance: none;
          width: 0.18rem!important;
          height: 0.18rem!important;
          display: inline-block;
          margin: -0.03rem 0.05rem 0 0!important;
          cursor: pointer;
          vertical-align: middle;
          background:url(./assets/kongxin.png) no-repeat;
          &:checked {
              background: url(./assets/shixin.png) no-repeat;
          }
          outline-color:none;
          outline-style :none;
          outline-width:none;
      }
      .xuanze2{
        width: 3.5rem;
        p{
          img{
            height:0.16rem;
            width:0.16rem;
          }
          float: left;
          width:50%;
          display: flex;
          align-items: center;
          margin-bottom: 0.1rem;
          cursor: pointer;
        }
      }
    }
    .dd6{
        font-size: 0.14rem;
        color:#4C4A4D;
        line-height: 0.44rem;
        margin-right:0.18rem;
        margin-left: 0.2rem;
        margin-bottom: 0.45rem;
        width: 1.5rem;
        text-align: right;
        float: left;
        span{
            font-size: 0.14rem;
            color:#F96C6C;
            line-height: 0.44rem;
        }
    }
    .dd7{
        float: left;
        width:3.4rem;
        margin-bottom: 0.45rem;
        font-size: 0.14rem;
        color:#4C4A4D;
        p{
          line-height: 0.44rem;
        }
        input{
            height:0.44rem;
            width:2.2rem;
            border-radius: 0.06rem;
            border:0.01rem solid #979797;
            padding: 0 0.2rem;
            color: #B1B1B1;
            &::-webkit-input-placeholder {
              color: #B1B1B1;
            }
            &:-moz-placeholder {
              color: #B1B1B1;
            }
            &::-moz-placeholder {
              color: #B1B1B1;
            }
            &:-ms-input-placeholder {
              color: #B1B1B1;
            }
        }
    }
    .dd8{
      width:2.5rem;
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      div{
        display: flex;
        align-items: center;
        width:50%;
        cursor:pointer;
        margin-bottom: 0.32rem;
        font-size: 0.18rem;
        color:#1A2533;
        img{
          height:0.16rem;
          width:0.16rem;
          margin-right:0.12rem;
        }
        p{
          font-size: 0.18rem;
          color:#1A2533;
        }
      }
    }
}
.liebiao2{
  margin-top: 0.45rem;
  width: 5.6rem;
    .dd1{
        font-size: 0.14rem;
        color:#4C4A4D;
        line-height: 0.44rem;
        margin-right:0.18rem;
        margin-left: 0.2rem;
        float: left;width: 0.95rem;
        text-align: right;
        margin-bottom: 0.29rem;
        span{
            font-size: 0.14rem;
            color:#F96C6C;
            line-height: 0.44rem;
        }
    }
    .dd2{
        float: left;
        input{
            height:0.44rem;
            width:2.6rem;
            border-radius: 0.06rem;
            border:0.01rem solid #979797;
            margin-bottom: 0.29rem;
            padding: 0 0.2rem;
            color:#B1B1B1;
            &::-webkit-input-placeholder {
              color: #B1B1B1;
            }
            &:-moz-placeholder {
              color: #B1B1B1;
            }
            &:-ms-input-placeholder {
              color: #B1B1B1;
            }
        }

    }
}
.xuan_foot{
  position: fixed;
  bottom: 0;
  height:0.97rem;
  border-top:0.01rem solid #EEEEEE;
  display: flex;
  align-items: center;
  background-color: #fff;
  width:5.6rem;
  padding: 0 1.2rem;
  div{
    padding: 0 0.21rem;
    border-radius: 0.02rem;
    font-size: 0.14rem;
    line-height: 0.43rem;
    text-align: center;
    margin: 0 auto;
    cursor:pointer;
  }
}

.el-drawer.ltr, .el-drawer.rtl, .el-drawer__container{
  min-height:9rem;
}

.el-avatar, .el-drawer{
  overflow: auto;
}
.el-dialog{
  width:8.99rem;
  min-height:5.55rem;
  .el-dialog__header{
    span{
      color:#1A2533;
      font-size: 0.22rem;
    }
  }
}
.xuanzecainian{
  .rqi{
    display: none;
  }
  .qingchu{
    display: none;
  }
  .el-input__inner{
    width:2rem;
    border:none;
    margin-left: 0.76rem;
    background: #eee;
  }
}
.xitong_nianfen{
  .el-date-picker__header--bordered{
    display: none!important;
  }
}
.el-calendar-table .el-calendar-day{
  height:1.2rem;
}
.left_zhu{
  .el-date-editor .el-range-separator{
    line-height: 0.38rem;
  }
}
.right_zhu{
  p{
    .el-input{
      width:1.4rem!important;
      margin-left: 0.04rem;
      height:0.32rem;
    }
    .el-input__inner{
      width:1.4rem!important;
      margin-left: 0.04rem;
      height:0.32rem;
    }
    .el-input--suffix .el-input__inner{
      width:1.4rem!important;
      margin-left: 0.04rem;
      height:0.32rem;
      line-height: 0.32rem;
    }
    .el-input__icon{
      // height:116%;
      line-height: 0.32rem;
    }
  }
}
.tree{
  .el-tree-node__content{
    height:0.42rem;
    border-bottom: 0.01rem solid #eee;
  }
  .el-tree-node__expand-icon{
    font-size: 0.2rem;
  }
}
// .kemu_tree{
//   .el-tree-node__content{
//     height:0.8rem;
//   }
//   .el-tree-node__expand-icon{
//     font-size: 0.2rem;
//   }
// }

.tit_p1{
  display: flex;
  align-items: center;
  .el-input__inner{
    height:0.23rem;
    width:1.39rem;
    border:0.01rem solid #888888;
    border-radius: 0.02rem;
    line-height: 0.23rem;
  }
  .el-input{
    height:0.23rem;
    width:1.39rem;
    margin-left: 0.12rem;
    line-height: 0.23rem;
  }
  .el-input__icon{
    line-height: 0.23rem;
  }
  .el-input__suffix{
    right:0;
  }
}
.table_tit_left{
  display: flex;
  align-items: center;
  .el-input__inner{
    height:0.23rem;
    width:1.4rem;
    border:0.01rem solid #888888;
    border-radius: 0.02rem;
    line-height: 0.23rem;
  }
  .el-input{
    height:0.23rem;
    width:1.4rem;
    margin-left: 0.12rem;
    line-height: 0.23rem;
  }
  .el-input__icon{
    line-height: 0.23rem;
  }
  .el-input__suffix{
    right:0;
  }
}
/* 谷歌 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}
/* 火狐 */
input{
    -moz-appearance:textfield;
}
.con_box{
  display: flex;
  justify-content: center;
  .el-input__inner{
    width:2.37rem;
    border:0.01rem solid #888888;
    height:0.41rem;
    line-height: 0.41rem;
  }
  .el-input{
    height:0.41rem;
    width:2.37rem;
    line-height: 0.41rem;
  }
  .el-input__icon{
    line-height: 0.41rem;
  }
  .el-input__suffix{
    right:0;
  }
}
.demo .el-switch__label { //按钮css
    position: absolute;
    display: none;
    color: #fff;
}
/*打开时文字位置设置*/
.demo .el-switch__label--right {
    z-index: 1;
    right: -0.05rem;
}
/*关闭时文字位置设置*/
.demo .el-switch__label--left {
    z-index: 1;
    left: 0.22rem;
}
/*显示文字*/
.demo .el-switch__label.is-active {
    display: block;
}
.demo.el-switch .el-switch__core,
.el-switch .el-switch__label {
    width: 0.7rem !important;
    border-radius: 0.03rem;
}
.el-switch__core:after{
  border-radius: 0.03rem;
  height:0.14rem;
  width:0.14rem;
  top:0.03rem;
}
.el-switch__label *{
  font-size: 0.1rem;
}
.xuanze_nianfen{
  .el-input__inner{
    height:0.23rem;
    width:1.4rem;
    border:0.01rem solid #888888;
    border-radius: 0.02rem;
    line-height: 0.23rem;
  }
  .el-input{
    height:0.23rem;
    width:1.32rem;
    margin-left: 0.12rem;
    line-height: 0.23rem;
  }
  .el-input__icon{
    line-height: 0.23rem;
  }
  .el-input__suffix{
    right:0;
  }
}
.table_con{
  thead,.el-table{ color: #888; }
  .el-table td, .el-table th.is-leaf,.el-table--border, .el-table--group{
    border-color: #888;
    // background: #eee;
  }
  .el-table--border::after, .el-table--group::after, .el-table::before{
    background-color: #888;
    // background: #eee;
  }
}
.table_con_ccc{
  thead,.el-table{ color: #888; }
  .el-table td, .el-table th.is-leaf,.el-table--border, .el-table--group{
    border-color: #888;
    background: #eee;
  }
  .el-table--border::after, .el-table--group::after, .el-table::before{
    background-color: #888;
    background: #eee;
  }
  .el-table__footer tbody td{
    background: #E2EFDA;
    text-align: right;
  }
  .el-table__body tbody td{
    position: relative;
      input{
        position: absolute;
        height:100%;
        width: 84%;
        border:none;
        padding: 0 8%;
        text-align: right;
        left:0;
        top:0;
        &::-webkit-input-placeholder {
          text-align: center;
        }
      }
      .baifen_hao{
        position: absolute;
        z-index: 10;
        top:50%;
        font-size: 0.12rem;
        margin-top: -0.12rem;
        right:0;
      }
  }
  .el-table--border th{
    border-right:0.01rem solid #888;
    border-bottom:0.01rem solid #888;
  }
  .el-table thead.is-group th{
    background: #fff;
  }
}
// .el-table{
//   overflow: auto;
// }
// .el-table__body-wrapper,
// .el-table__header-wrapper,
// .el-table__footer-wrapper{
//   overflow:visible;
// }
// .el-table::after{
//   position: relative !important;
// }
.el-table__body-wrapper{
  z-index: 2;
}
.table_con{
  // .el-table__fixed-footer-wrapper tbody td{
  //   background:#E2EFDA;
  // }
  .el-table__footer tbody td{
    background: #E2EFDA;
    text-align: right;
  }
  .el-table__body tbody td{
    position: relative;
      input{
        position: absolute;
        height:100%;
        width: 84%;
        border:none;
        padding: 0 8%;
        text-align: right;
        left:0;
        top:0;
        &::-webkit-input-placeholder {
          text-align: center;
        }
      }
      .baifen_hao{
        position: absolute;
        z-index: 10;
        top:50%;
        margin-top: -0.14rem;
        right:0;
      }
  }
  .el-table--border th{
    border-right:0.01rem solid #888;
    border-bottom:0.01rem solid #888;
  }
  .el-table thead.is-group th{
    background: #fff;
  }
}
.con_xuanze_shenpi{
  .el-input{
    width:2.31rem!important;
    margin-left: 0.04rem;
    height:0.35rem;
  }
  .el-input__inner{
    width:2.31rem!important;
    margin-left: 0.04rem;
    height:0.35rem;
    border:0.01rem solid #CCCCCC;
  }
  .el-input--suffix .el-input__inner{
    width:2.31rem!important;
    margin-left: 0.04rem;
    height:0.35rem;
  }
  .el-input__icon{
    height:110%;
  }
}
.xuanze_shenpi_ren{
  .el-input{
    width:5.4rem!important;
    height:0.44rem;
    margin-right: 0.1rem;
  }
  .el-input__inner{
    width:5.4rem!important;
    height:0.44rem;
    margin-right: 0.1rem;
    border:0.01rem solid #CCCCCC;
  }
  .el-input--suffix .el-input__inner{
    width:5.4rem!important;
    height:0.44rem;
    margin-right: 0.1rem;
  }
  .el-input__icon{
    height:110%;
  }
}
.approver_some{
  .el-radio-button__inner, .el-radio-group{
    display: block;
  }
}
.xiaoshou_table{
  .el-table th{
    background: #f9fbfe;
  }
  .el-table th.is-leaf{
    border-bottom: 0.1rem solid #f8fafd;
  }
  .el-table td{
    border-bottom: 0.1rem solid #f8fafd;
    &:nth-child(1){
      background: #f4f7fd;
      border-radius: 0.06rem 0 0 0.06rem;
    }
  }
  .cell{
    font-size: 0.14rem!important;
    span{
      font-size: 0.14rem!important;
    }
  }
}
.rqi_qujian_kuang{
  .el-range__icon{
    height:0.44rem!important;
  }
  .el-range-separator{
    line-height:0.38rem;
  }
}
.shenpi_xuanze_rqi{
  .el-select,.el-input,.el-input__inner{
    width: 145px;
    line-height: 0.44rem;
  }
  .el-picker-panel__footer{
    .el-button{
      background: #fff;
    }
  }
}
.wai_div{
  .xingcheng_rqi_qujian{
    input{
      width:2.21rem!important;
    }
  }
  .xingcheng_rqi_sx{
    margin-left: 0.1rem;
    .el-input{
      width:2rem;
      input{
        width:2rem;
      }
    }
  }
}
.min_rqi_qujian{
  .el-range-editor.el-input__inner{
    width: 2.42rem;
    margin-left: 0.08rem;
  }
}
.kanban_box_table{
  .el-table th>.cell{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.fenpei_shunwei{
  .el-input{
      width:1.6rem;
  }
  .el-input__inner{
      width: 1.6rem;
  }
}
.tree_bumen{
  .el-tree-node__label{
    font-size: 0.12rem!important;
    color:#444;
  }
}
.liebiao_tishi{
    ul{
      width:0.6rem;
        li{
            color:rgb(24, 134, 249);
            line-height: 0.2rem;
            font-size: 0.14rem;
            text-align: center;
            cursor: pointer;
            &:hover{
              background: rgb(244, 247, 253);
            }
        }
    }
}
.distpicker-address-wrapper select{
  padding: 0 0.08rem!important;
  font-size: 0.12rem !important;
  border-radius: 0.03rem !important;
  height:0.44rem !important;
}
.shengr_wode_shezhi{
  .el-input__inner{
      width:2.99rem;
  }
}
.el-button--mini{
  background: none;
}
.tuihui_ren{
  width:2rem;
  .el-input {
    width: 2rem!important;
  }
  .el-input__inner{
    width:2rem!important;
    border:0.01rem solid #ccc;
  }
}
.xitong_fenpei_xuanze_bumen{
  .el-input {
    width: 2rem;
  }
  .el-input__inner{
    width:2rem;
    border:0.01rem solid #ccc;
  }
}
.fenye_qi{
  .el-input__inner{
    width:1rem;
  }
}
.shiyong_biaodan_xiala_kuang{
  margin-right:0.1rem;
  .el-input__inner{
    width:1.72rem;
  }
  .el-input{
    width:1.72rem;
  }
}
.kehu_sousuo_renyuan{
  width: 2rem!important;
  .el-input__inner {
    height: 0.24rem;
    width: 2rem;
    border: 0.01rem solid #e6e9ee;
    border-radius: 0.02rem;
    line-height: 0.24rem;
    font-size: 0.12rem;
  }
}
.kehu_tree{
  height:2.6rem;
  overflow-y:auto;
  .el-tree-node__label{
    font-size: 0.12rem;
  }
  &::-webkit-scrollbar {
      width: 0.2rem;
      height: 0.08rem;
  }

  &::-webkit-scrollbar-thumb {
      border-radius: 0.12rem;
      border: 0.06rem solid rgba(0, 0, 0, 0);
      box-shadow: 0.08rem 0 0 #A5ADB7 inset;
  }

  &::-webkit-scrollbar-thumb:hover {
      box-shadow: 0.08rem 0 0 #4A4A4A inset;
  }
}
.el-loading-mask{
  opacity: 0;
}
.lao_gao_zhong_ji_guize{
    td{
        padding: 0.06rem 0;
    }
}
.tiaojian_dan_duoxuan{
  .el-input{
    width: 2.45rem;
  }
  .el-input__inner{
    width: 2.45rem;
  }
}
.rqi_kuang_xiangqiang{
  .el-input{
    width: 3.1rem;
  }
  .el-input__inner{
    width: 3.1rem;
  }
}
.renzheng_qiye_hangye{
  .el-input__inner{
    width: 6.35rem;
  }
  .el-input{
    width: 6.35rem;
  }
}
.supplierprice_rli{
  .el-calendar-table .el-calendar-day{
    height: 0.4rem;
  }
  .text{
    position: relative;
    .lvse{
      position: absolute;
      height:0.1rem;
      width:0.1rem;
      border-radius: 50%;
      bottom: 0rem;
      right:0;
      background: #52C41A;
    }
  }
}
</style>
