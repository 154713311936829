import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    biaodan_list: {},
    //当前登录人员的全部信息
    infoData:{
      code:1
    }
  },
  getters:{
        //获取当前登录人员信息
    infoData:(state,data)=>{
      if(state.infoData){
        state.info = {}
      }else{
        state.infoData = data
      }
      return state.infoData

    }
  },
  mutations: {
    infoData(state,data){
       state.infoData = data
    },
    // 使用表单关联单据
    shiyong_biaodan_danju (state, data) {
      state.biaodan_list = data
    },
    // 清除vuex保存到的数据
    mapClearState (state) {
      for (const item in state) {
        state[item] = ''
      }
    }
  },
  actions: { // 类似于方法
    infoDataAction(context,params){
      context.commit('infoData',params);
    },
    shiyong_biaodan_danju_s (context, data) {
      context.commit('shiyong_biaodan_danju', data)
    }
  },
  plugins: [createPersistedState()]
})
