<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <header class="waihead">
        <div class="box">
            <img src="../assets/wai_daohang/logo_wai.png" @click="hui" alt="">
            <ul>
                <li v-for="(i,index) in list" :key="index" @click="dian_list(i)" :class="{ 'bei':i.path == path}">{{i.name}}</li>
            </ul>
            <p @click="denglu">用户登录</p>
            <!-- <p @click="ceshi">
                asdasd
            </p> -->
        </div>
    </header>
</template>

<script>
import { query_subject_info_list } from '../api/api'
export default {
  name: 'waihead',

  data () {
    return {
      list: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '产品介绍',
          path: '/producta'
        },
        {
          name: '成果案例',
          path: '/casea'
        },
        {
          name: '下载中心',
          path: '/downloada'
        },
        {
          name: '企业简介',
          path: '/Introductiona'
        },
        {
          name: '客户服务',
          path: '/customera'
        },
        {
          name: '服务培训',
          path: '/traina'
        }
      ],
      path: ''
    }
  },
  created () {
    this.lujing(this.$route.path)
  },
  mounted () {
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.lujing(val.fullPath)
      },
      deep: true
    }
  },
  methods: {
    // 基础信息
    lujing (path) {
      this.path = path
    },
    // 点击
    dian_list (i) {
      this.path = i.path
      this.$router.push(i.path)
    },
    hui () {
      this.$router.push('/')
    },
    // 登录
    denglu () {
      this.$router.push('/deng')
    }
    // ceshi(){
    //     query_subject_info_list({data:{
    //         active:'1',
    //         ent_id:"QY1586783524372",
    //         parent_id:'1',
    //     }}).then(res=>{
    //         console.log(res)
    //     })
    // }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.waihead {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #f5f5f5;
    z-index: 10;
    .box{
        height:0.8rem;
        width: 12rem;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img{
            cursor:pointer;
            height:0.65rem;
        }
        ul{
            margin: 0 0.68rem;
            display: flex;
            align-items: center;
            justify-content: center;
            li{
                margin: 0 0.4rem;
                font-size: 0.14rem;
                color:#1A2533;
                padding: 0.26rem 0;
                cursor:pointer;
                &:hover{
                    color:#F96C6C;
                    border-bottom: 0.04rem solid rgb(247, 155, 155);
                }
            }
            .bei{
                color:#F96C6C;
                border-bottom: 0.04rem solid #F96C6C;
            }
        }
        p{
            background: #F96C6C;
            font-size: 0.12rem;
            color:#FFFFFF;
            padding: 0.06rem;
            cursor:pointer;
        }
    }
}
</style>
