<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-10-07 16:46:29
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="home" @click="qiye_list==false?showa=false:qiye_list=false;">
    <div class="home_tit">
      <div class="home_tit_qiu">
        <img class="img1" @click="dian_gongneng" src="../assets/wai_daohang/logo_wai.png" alt="">
        <div class="wrapper" data-anim="base wrapper">
          <div class="circle" data-anim="base left"></div>
          <div class="circle" data-anim="base right"></div>
        </div>
        <div class="wrapper2" data-anim="base2 wrapper2">
          <div class="circle2" data-anim="base2 left2"></div>
          <div class="circle2" data-anim="base2 right2"></div>
        </div>
        <div class="wrapper3" data-anim="base3 wrapper3">
          <div class="circle3" data-anim="base3 left3"></div>
          <div class="circle3" data-anim="base3 right3"></div>
        </div>
        <p class="home_tit_p1 wow bounceIn" @click="jin_shezhi" data-wow-delay="0.4s">
          <img src="../assets/wai_daohang/houtai.png" alt="">
          <span>后台设置</span>
        </p>
        <p class="home_tit_p2 wow bounceIn" @click.stop @click="dakai_qiye_list" data-wow-delay="0.6s">
          <img src="../assets/wai_daohang/qiye.png" alt="">
          <span>企业切换</span>
          <!--  v-if="qiye_list" -->
          <ul v-if="qiye_list" @click.stop>
            <li v-for="(i,index) in qiyes_list" :key='index' @click="qiehuan_qiye(i)">{{i.ent_name}}</li>
          </ul>
        </p>
        <p class="home_tit_p3 wow bounceIn" @click="jin_guanli" data-wow-delay="0.8s">
          <img src="../assets/wai_daohang/shuju.png" alt="">
          <span>数据管理</span>
        </p>
        <p class="home_tit_p4 wow bounceIn" @click="jin_zhongxin" data-wow-delay="1s">
          <s v-if="Number(xiaoxi_num)!=0">{{xiaoxi_num}}</s>
          <img src="../assets/wai_daohang/shenpi.png" alt="">
          <span>审批中心</span>
        </p>
        <p class="home_tit_p5 wow bounceIn" data-wow-delay="1.2s">
          <img src="../assets/wai_daohang/xiaoxi.png" alt="">
          <span>消息中心</span>
        </p>
        <p class="home_tit_p6 wow bounceIn" data-wow-delay="1.4s">
          <img src="../assets/wai_daohang/kehu.png" alt="">
          <span>客户服务</span>
        </p>
        <p class="home_tit_p7 wow bounceIn" @click="out" data-wow-delay="1.6s">
          <img src="../assets/wai_daohang/tuichu.png" alt="">
          <span>退出登录</span>
        </p>
      </div>
      <!--  @click="jin_wode" -->
      <div class="touxiang">
        <img v-if="jichuxinxi.avatar!=undefined" :src="jichuxinxi.avatar" alt="">
        <p class="touxiang_ph" v-if="jichuxinxi.avatar==undefined">{{jichuxinxi.name!=undefined?jichuxinxi.name[0]:name}}</p>
        <p class="qiye_name" v-if="jichuxinxi.ent_info!=undefined">{{jichuxinxi.ent_info.ent_name}}</p>
        <i class="el-icon-caret-left icon-left"></i>
        <i class="el-icon-caret-bottom icon-bottom"></i>
        <div class="touxiang_box">
          <div class="touxiang_box_tit">
            <img class="touxiang_icon" v-if="jichuxinxi.avatar!=undefined" :src="jichuxinxi.avatar" alt="">
            <p class="touxiang_text" v-if="jichuxinxi.avatar==undefined">{{jichuxinxi.name!=undefined?jichuxinxi.name[0]:name}}</p>
            <div class="touxiang_box_tit_right">
              <p>{{jichuxinxi.name!=undefined?jichuxinxi.name:''}}</p>
              <div class="touxiang_box_tit_right_gongsi" @click="qiye_dakai=!qiye_dakai">
                <p>{{jichuxinxi.ent_info!=undefined?jichuxinxi.ent_info.ent_name:''}}</p>
                <i :class="qiye_dakai?'el-icon-caret-bottom':'el-icon-caret-left'"></i>
              </div>
            </div>
          </div>
          <ul class="touxiang_box_bottom">
            <li @click="jin_gongzuo_tai">
              <div class="ic-icon"></div>
              <p>我的工作台</p>
            </li>
            <li>
              <div class="ic-icon"></div>
              <p>消息中心</p>
            </li>
            <li @click="jin_wode">
              <div class="ic-icon"></div>
              <p>设置中心</p>
            </li>
            <li @click="out">
              <div class="ic-icon"></div>
              <p>退出登录</p>
            </li>
          </ul>
        </div>
        <!-- <div>
          <p class="qiye_name" v-if="jichuxinxi.ent_info!=undefined">{{jichuxinxi.ent_info.ent_name}}</p>
          <p class="geren_name" v-if="jichuxinxi.name!=undefined">{{jichuxinxi.name}}</p>
        </div> -->
      </div>
    </div>
    <div class="shenpi" v-if="this.$jichuxinxi().user_id=='9527'" @click="dakai_shenpi()">
      审批表单入口
    </div>
    <div class="paopi" v-if="this.$jichuxinxi().user_id=='9527'" @click="pao_liucheng()">
      跑批
    </div>
    <div class="con">
      <img
        class="text_img1 wow zoomIn"
        data-wow-delay="0.1s"
        src="../assets/wai_daohang/tit_top.png"
        alt
      >
      <img
        class="text_img2 wow zoomIn"
        data-wow-delay="0.5s"
        src="../assets/wai_daohang/tit_bottom.png"
        alt
      >
    </div>
    <ul class="foot" @click.stop>
        <li  @click="jin_mubiao">
            <div style="border-right: 0.01rem solid #9e71c3;">
                <img src="../assets/wai_daohang/mubiao.png" alt="">
                <p>目标</p>
                <p>明确事业的目的意义</p>
                <p>设立具体的目标</p>
            </div>
        </li>
        <li @click="jin_xiaoshou">
            <div style="border-right: 0.01rem solid #9e71c3;">
                <img src="../assets/wai_daohang/xiaoshou.png" alt="">
                <p>销售</p>
                <p>定价即经营</p>
                <p>付出不亚于任何人的努力</p>
            </div>
        </li>
         <li @click="jin_chanpin">
            <div style="border-right: 0.01rem solid #9e71c3;">
                <img src="../assets/wai_daohang/chanpin.png" alt="">
                <p>产品</p>
                <p>做出好产品的人</p>
                <p>能听见产品的声音</p>
            </div>
        </li>
         <li>
            <div>
                <img src="../assets/wai_daohang/hesuan.png" alt="">
                <p>核算</p>
                <p>数字化经营</p>
                <p>销售最大化，经费最小化</p>
            </div>
        </li>
      <!-- <img @click="jin_mubiao" class="wow bounceIn" data-wow-delay="1.2s" src="../assets/nei_img_zhu1.png" alt>
      <img @click="jin_xiaoshou" class="wow bounceIn" data-wow-delay="1.8s" src="../assets/nei_img_zhu2.png" alt>
      <img class="wow bounceIn" data-wow-delay="2.4s" src="../assets/nei_img_zhu3.png" alt>
      <img class="wow bounceIn" data-wow-delay="3s" src="../assets/nei_img_zhu4.png" alt>
      <img class="wow bounceIn" data-wow-delay="3.6s" src="../assets/nei_img_zhu5.png" alt>
      <img class="wow bounceIn" data-wow-delay="4.2s" src="../assets/nei_img_zhu6.png" alt> -->
    </ul>
  </div>
</template>

<script>
import { WOW } from 'wowjs'
import { manual_batch, query_all_module_sale_userid, insert_m_login_log, query_staff_dept_list,query_notify_num, query_module_status, query_all_module_read_power_list, query_user_info, auto_batch, query_aim_module_power_list, get_my_info, logout, toggle_user_ent, get_rel_list } from '../api/api.js'
import { query_user_ent_list } from '../api/noapi.js'
export default {
  name: 'home',
  data () {
    return {
      gongsi_name: '',
      showa: false,
      datea: '',
      qiye_list: false,
      qiyes_list: '',
      jichuxinxi: '',
      name: '智',
      shifou_jin_mubiao: false,
      zhuguan: false,
      shifou_jin_xiaoshou: false,
      quanbu_kejian: false,
      mubiao_quanbu: false,
      xiaoshou_quanbu: false,
      qiye_dakai: false,
      ziguan_guanli_yuan: false,
      ziguan_guanli_yuan_mubiao: false,
      xiaoxi_num: '0',
      ziguan_guanli_yuan_chanpin: false
    }
  },
  mounted () {
    new WOW().init()
    this.get_info_data()
  },
  created () {
    if (sessionStorage.getItem('pc_mlbb_jichuxinxi') != null && sessionStorage.getItem('pc_mlbb_jichuxinxi') != undefined && sessionStorage.getItem('pc_mlbb_jichuxinxi') != '') {
      this.jichuxinxi = JSON.parse(JSON.parse(sessionStorage.getItem('pc_mlbb_jichuxinxi')))
    }
    this.jichu()
  },
  methods: {
    //获取登录人员信息
  async  get_info_data(){
      try{
        const data =  await query_staff_dept_list({
        data:{
            ent_id: this.$ent_id(),
            staff_id: this.jichuxinxi.user_id
        }
      })
     // console.log(data.data.code)
        if(data.data.code == 200){
          //JSON.parse(data.data.body.data)[0],'这是页面前期发的接口')
            this.$store.dispatch('infoDataAction',JSON.parse(data.data.body.data)[0])
        }
      }catch(err){
        console.log(err)
      }

    },
    // 基础
    jichu () {
      if (this.$ent_id() != undefined && this.$ent_id() != null && this.$ent_id().length != 0) {
        query_module_status({
          data: {
            ent_id: this.$ent_id(),
            module_id: '1-2'
          }
        }).then(res => {
          if (res.data.code == 10189) {
            const date = JSON.parse(res.data.body.data)
            this.mubiao_quanbu = date == 1
          } else if (res.data.code == 10190) {}
        })
        query_module_status({
          data: {
            ent_id: this.$ent_id(),
            module_id: '2'
          }
        }).then(res => {
          if (res.data.code == 10189) {
            const date = JSON.parse(res.data.body.data)
            this.xiaoshou_quanbu = date == 1
          } else if (res.data.code == 10190) {}
        })
      }
      query_notify_num({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          read_flag: '0'
        }
      }).then(res => {
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)

          this.xiaoxi_num = Number(date) > 99 ? '99+' : date
        } else if (res.data.code == 500) {}
      })

      this.jichuxinxi = this.$jichuxinxi()
      if (sessionStorage.getItem('pc_mlbb_ent_flag') != null && sessionStorage.getItem('pc_mlbb_ent_flag') != undefined && sessionStorage.getItem('pc_mlbb_ent_flag') != '') {
        this.datea = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_flag'))
      }
      if (this.datea == 1) {
        this.gongsi_name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
      } else {
        this.gongsi_name = ''
      }
      get_rel_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        if (res.data.code == 10091) {

          const date = JSON.parse(res.data.body.data)

          if (date.rel_list == undefined || date.rel_list.length == 0) {
            this.shifou_jin_xiaoshou = false
            this.shifou_jin_chanpin = false
            this.huoqu_all_mokuai_xiaoshou()
          } else {
            for (let i = 0; i < date.rel_list.length; i++) {
              if (date.rel_list[i].module_id == 2) {
                this.shifou_jin_xiaoshou = true
              }
              if (date.rel_list[i].module_id == 3) {
                this.shifou_jin_chanpin = true
              }
            }
          }
        } else if (res.data.code == 10092) {}
      })
      query_all_module_read_power_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {

        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)

          if (date.length != 0) {
            this.quanbu_kejian = true
          } else {
            this.quanbu_kejian = false
          }
        } else if (res.data.code == 500) {}
      })
      // if(this.$jichuxinxi().ent_info!=undefined&&this.$jichuxinxi().ent_info.staff_level==1){
      //   this.shifou_jin_mubiao=true
      // }else{
      //   this.mubiao_quanxian_jiekou()
      // }
      query_user_info({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {

        if (res.data.code == 200) {
          if (res.data.body.staff_level == 1) {
            this.zhuguan = true
            this.ziguan_guanli_yuan = false
          } else if (res.data.body.staff_level == 2) {
            this.zhuguan = false
            this.ziguan_guanli_yuan = false
            const list = res.data.body.sub_manager_value.split(',')
            list.forEach(item => {
              if (item == 2) {
                this.ziguan_guanli_yuan = true
              }
              if (item == '1-2') {
                this.ziguan_guanli_yuan_mubiao = true
              }
              if (item == '3') {
                this.ziguan_guanli_yuan_chanpin = true
              }
            })
          } else {
            this.zhuguan = false
            this.ziguan_guanli_yuan = false
          }
        } else if (res.data.code == 500) {}
      })
      this.mubiao_quanxian_jiekou()
    },
    mubiao_quanxian_jiekou () {
      query_aim_module_power_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {

        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)

          if (date.length != 0) {
            this.shifou_jin_mubiao = true
            this.$bendi_cunfang_cunchu('mubiao_quanxian_list', date, '暂时')
          } else {
            this.shifou_jin_mubiao = false
          }
        } else if (res.data.code == 500) {}
      })
    },
    dian_gongneng () {
      this.showa = !this.showa
      this.qiye_list = false
    },
    out () {
      logout({
        data: {
          mobile: this.jichuxinxi.mobile
        }
      }).then(res => {

        if (res.data.code == 10015) {
          this.$message({
            message: '退出成功',
            type: 'success'
          })
          sessionStorage.clear()
          // sessionStorage.setItem("pc_pc_wai_daohang", JSON.stringify(0));
          this.$router.push('/')
        } else if (res.data.code == 10016) {
          this.$message({
            message: '退出失败',
            type: 'error'
          })
        } else if (res.data.code == 11000) {
          // this.$message({
          //     message: '您未登录',
          //     type: 'error'
          // });
          this.$message({
            message: '退出成功',
            type: 'success'
          })
          sessionStorage.clear()
          // sessionStorage.setItem("pc_pc_wai_daohang", JSON.stringify(0));
          this.$router.push('/')
        }
      })
    },
    // 进入设置页面
    jin_shezhi () {
      this.$router.push('/install')
    },
    jin_zhongxin () {

      if (this.jichuxinxi.ent_info != undefined && this.jichuxinxi.ent_info.ent_name.length != 0) {
        this.$router.push('/initiateapp')
      } else {
        this.$message({
          message: '您当前没有企业',
          type: 'warning'
        })
      }
    },
    // 进数据管理页面
    jin_guanli () {
      if (this.jichuxinxi.ent_info != undefined && this.jichuxinxi.ent_info.ent_name.length != 0) {
        // this.$router.push('/dbms')
      } else {
        this.$message({
          message: '您当前没有企业',
          type: 'warning'
        })
      }
    },
    // 打开企业列表
    dakai_qiye_list () {
      this.qiye_list = !this.qiye_list
      query_user_ent_list({
        data: {
          user_id: this.jichuxinxi.user_id
        }
      }).then(res => {

        if (res.data.code == 10080) {

          const date = JSON.parse(res.data.body.data)
          this.qiyes_list = date

        } else if (res.data.code == 10081) {
        }
      })
    },
    // 点击切换企业
    qiehuan_qiye (i) {
      if (i.use_status != 0) {
        if (i.node_flag != 0) {
          if (i.node_not_enough != 0) {
            toggle_user_ent({
              data: {
                ent_id: i.ent_id,
                user_id: this.jichuxinxi.user_id
              }
            }).then(res => {

              if (res.data.code == 10082) {
                this.$message({
                  message: '切换成功',
                  type: 'success'
                })
                get_my_info({
                  data: {
                    user_id: this.$jichuxinxi().user_id
                  }
                }).then(ras => {
                  if (ras.data.code == 10069) {
                    this.jichuxinxi = JSON.parse(ras.data.body.data)
                    sessionStorage.setItem('pc_mlbb_jichuxinxi', JSON.stringify(ras.data.body.data))

                  }
                })
                get_rel_list({
                  data: {
                    user_id: this.jichuxinxi.user_id
                  }
                }).then(ras => {
                  if (ras.data.code == 10091) {

                    const qiye = JSON.parse(ras.data.body.data)
                    sessionStorage.setItem('pc_mlbb_ent_flag', JSON.stringify(qiye.ent_flag))
                    sessionStorage.setItem('pc_mlbb_ent_info', JSON.stringify(qiye.staff_info))
                    // sessionStorage.setItem("pc_mlbb_quanxian", JSON.stringify(qiye.rel_list));
                    this.jichu()
                    this.qiye_list = false
                  } else if (ras.data.code == 10092) {}
                })
                insert_m_login_log({
                  data: {
                    ent_id: i.ent_id,
                    mobile: this.jichuxinxi.mobile
                  }
                })
              } else if (res.data.code == 10083) {
                this.$message({
                  message: '切换失败',
                  type: 'error'
                })
              } else if (res.data.code == 11700) {
                this.$message({
                  message: '您没有该企业的登录权限，请联系主管理员添加',
                  type: 'error'
                })
              }
            })
          } else {
            this.$message({
              message: '当前登录节点少于付费节点数，请联系主管理员进行设置',
              type: 'error'
            })
          }
        } else {
          this.$message({
            message: '您没有该企业的登录权限，请联系主管理员添加',
            type: 'error'
          })
        }
      } else {
        this.$message({
          message: '该企业暂不能使用，请联系官方客服',
          type: 'error'
        })
      }
    },
    // 进入目标模块
    jin_mubiao () {
      if (this.mubiao_quanbu) {
        if (this.shifou_jin_mubiao || this.zhuguan || this.quanbu_kejian || this.ziguan_guanli_yuan_mubiao) {
          this.$router.push('/aspreackeven')
        } else {
          this.$message({
            message: '您暂时没有权限进入目标模块',
            type: 'warning'
          })
        }
      } else {
        if (this.zhuguan) {
          this.$router.push('/aspreackeven')
        } else {
          this.$message({
            message: '您暂时没有权限进入目标模块',
            type: 'warning'
          })
        }
      }
    },
    // 获取是否有销售权限
    huoqu_all_mokuai_xiaoshou () {
      query_all_module_sale_userid({
        data: {
          ent_id: this.$ent_id(),
          read_flag: '1'
        }
      }).then(res => {

        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          if (date != null) {

            let ids = []
            if (date.staff_ids != undefined) {
              ids = date.staff_ids.split(',')
            }
            ids.forEach(item => {
              if (item == this.$jichuxinxi().user_id) {
                this.shifou_jin_xiaoshou = true
              }
            })
          }
        } else if (res.data.code == 500) {}
      })
    },
    // 进入销售模块
    jin_xiaoshou () {
      if (this.xiaoshou_quanbu) {
        if (this.shifou_jin_xiaoshou || this.zhuguan || this.quanbu_kejian || this.ziguan_guanli_yuan) {
          this.$router.push('/sale')
        } else {
          this.$message({
            message: '您暂时没有权限进入销售模块',
            type: 'warning'
          })
        }
      } else {
        if (this.zhuguan || this.shifou_jin_xiaoshou) {
          this.$router.push('/sale')
        } else {
          this.$message({
            message: '您暂时没有权限进入销售模块',
            type: 'warning'
          })
        }
      }
    },
    // 打开共有审批
    dakai_shenpi () {
      // if(this.jichuxinxi.ent_info!=undefined&&this.jichuxinxi.ent_info.ent_name.length!=0){
      this.$router.push('/biaodangg')
      // }else{
      //   this.$message({
      //       message: '您暂时没有权限进入模块',
      //       type: 'warning'
      //   });
      // }
    },
    jin_wode () {
      this.$router.push('/me')
    },
    pao_liucheng () {
      // auto_batch
      manual_batch({ data: {} }).then(res => {

        if (res.data.code == 200) {
          this.$message({
            message: '跑批成功',
            type: 'success'
          })
        } else if (res.data.code == 500) {
          this.$message({
            message: '跑批失败',
            type: 'error'
          })
        }
      })
    },
    jin_gongzuo_tai () {
      if (this.$jichuxinxi().ent_info != undefined && this.$jichuxinxi().ent_info.ent_name.length != 0) {
        this.$router.push('/rolekanban')
      } else {
        this.$message({
          message: '您当前没有企业',
          type: 'warning'
        })
      }
    },
    jin_chanpin () {
      // if(this.$jichuxinxi().ent_info!=undefined&&this.$jichuxinxi().ent_info.ent_name.length!=0){
      //   this.$router.push('/productModal')
      // }else{
      //   this.$message({
      //       message: '您当前没有企业',
      //       type: 'warning'
      //   });
      // }
      // if(this.xiaoshou_quanbu){
      //   if(this.shifou_jin_chanpin||this.zhuguan||this.quanbu_kejian||this.ziguan_guanli_yuan){
      //     this.$router.push('/productModal')
      //   }else{
      //     this.$message({
      //         message: '您暂时没有权限进入产品模块',
      //         type: 'warning'
      //     });
      //   }
      // }else{
      if (this.zhuguan || this.shifou_jin_chanpin || this.ziguan_guanli_yuan_chanpin) {
        this.$router.push('/productModal')
      } else {
        this.$message({
          message: '您暂时没有权限进入产品模块',
          type: 'warning'
        })
      }
      // }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.home {
    background-image: url("../assets/wai_daohang/background_img4.png");
    background-size:100% 100%;
    position:fixed;
    height: 100vh;
    width:100vw;
    background-repeat: no-repeat;
  .home_tit{
    position: relative;
    .home_tit_qiu{
      width:1.1rem;
      height:1.1rem;
      border-radius: 50%;
      position: relative;
      top:0.52rem;
      left:0.52rem;
      z-index: 10;
      @keyframes scaleDraw {
        /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
        0% {
          transform: scale(1); /*开始为原始大小*/
        }
        25% {
          transform: scale(1.1); /*放大1.1倍*/
        }
        50% {
          transform: scale(1);
        }
        75% {
          transform: scale(1.1);
        }
      }
      .img1 {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -0.55rem;
        margin-top: -0.55rem;
        width: 1.1rem;
        height: 1.1rem;
        border-radius: 50%;
        // box-shadow: 0 0 0.5rem rgb(250, 157, 157);
        -webkit-animation-name: scaleDraw; /*关键帧名称*/
        -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
        -webkit-animation-iteration-count: infinite; /*动画播放的次数*/
        -webkit-animation-duration: 5s; /*动画所花费的时间*/
        cursor: pointer;
      }
      &:hover{
        .wrapper{
          display: block;
        }
        .circle{
          display: block;
        }
        .wrapper2{
          display: block;
        }
        .circle2{
          display: block;
        }
        .wrapper3{
          display: block;
        }
        .circle3{
          display: block;
        }
        .home_tit_p1{
          display: block;
        }
        .home_tit_p2{
          display: block;
        }
        .home_tit_p3{
          display: block;
        }
        .home_tit_p4{
          display: block;
        }
        .home_tit_p5{
          display: block;
        }
        .home_tit_p6{
          display: block;
        }
        .home_tit_p7{
          display: block;
        }
      }
      .wrapper {
        width: 2.5rem; /* Set the size of the progress bar */
        height: 2.5rem;
        position: absolute; /* Enable clipping */
        left: 50%;
        top:50%;
        display: none;
        margin-left: -1.25rem;
        margin-top: -1.25rem;
        clip: rect(0, 2.5rem, 2.5rem, 1.25rem); /* Hide half of the progress bar */
      }
      /* Set the sizes of the elements that make up the progress bar */
      .circle {
        width: 2.48rem;
        height: 2.48rem;
        border: 0.01rem solid #6c44a6;
        border-radius: 50%;
        position: absolute;
        display: none;
        clip: rect(0, 1.25rem, 2.5rem, 0);
      }
      .wrapper2 {
        width: 4rem; /* Set the size of the progress bar */
        height: 4rem;
        position: absolute; /* Enable clipping */
        left: 50%;
        top:50%;
        display: none;
        margin-left: -2rem;
        margin-top: -2rem;
        clip: rect(0, 4rem, 4rem, 2rem); /* Hide half of the progress bar */
      }
      /* Set the sizes of the elements that make up the progress bar */
      .circle2 {
        width: 2.6rem;
        height: 2.6rem;
        border: 0.7rem solid #5c3095;
        border-radius: 50%;
        position: absolute;
        display: none;
        clip: rect(0, 2rem, 4rem, 0);
      }
      .wrapper3 {
        width: 4.2rem; /* Set the size of the progress bar */
        height: 4.2rem;
        position: absolute; /* Enable clipping */
        left: 50%;
        top:50%;
        display: none;
        margin-left: -2.1rem;
        margin-top: -2.1rem;
        clip: rect(0, 4.2rem, 4.2rem, 2.1rem); /* Hide half of the progress bar */
      }
      /* Set the sizes of the elements that make up the progress bar */
      .circle3 {
        width: 4.18rem;
        height: 4.18rem;
        border: 0.01rem solid #5c3095;
        border-radius: 50%;
        position: absolute;
        display: none;
        clip: rect(0, 2.1rem, 4.2rem, 0);
      }
      div[data-anim~=base],div[data-anim~=base2],div[data-anim~=base3] {
        -webkit-animation-iteration-count: 1;  /* Only run once */
        -webkit-animation-fill-mode: forwards; /* Hold the last keyframe */
        -webkit-animation-timing-function:linear; /* Linear animation */
      }

      .wrapper[data-anim~=wrapper],.wrapper2[data-anim~=wrapper2],.wrapper3[data-anim~=wrapper3] {
        -webkit-animation-duration: 0.01s; /* Complete keyframes asap */
        -webkit-animation-delay: 0.5s; /* Wait half of the animation */
        -webkit-animation-name: close-wrapper; /* Keyframes name */
      }

      .circle[data-anim~=left],.circle2[data-anim~=left2],.circle3[data-anim~=left3] {
        -webkit-animation-duration: 1s; /* Full animation time */
        -webkit-animation-name: left-spin;
      }

      .circle[data-anim~=right],.circle2[data-anim~=right2],.circle3[data-anim~=right3] {
        -webkit-animation-duration: 0.5s; /* Half animation time */
        -webkit-animation-name: right-spin;
      }
      /* Rotate the right side of the progress bar from 0 to 180 degrees */
      @-webkit-keyframes right-spin {
        from {
          -webkit-transform: rotate(0deg);
        }
        to {
          -webkit-transform: rotate(180deg);
        }
      }
      /* Rotate the left side of the progress bar from 0 to 360 degrees */
      @-webkit-keyframes left-spin {
        from {
          -webkit-transform: rotate(0deg);
        }
        to {
          -webkit-transform: rotate(360deg);
        }
      }
      /* Set the wrapper clip to auto, effectively removing the clip */
      @-webkit-keyframes close-wrapper {
        to {
          clip: rect(auto, auto, auto, auto);
        }
      }
      .home_tit_p1{
        position: absolute;
        height:0.42rem;
        width:0.42rem;
        right:-1.16rem;
        top:-0.3rem;
        display: none;
        img{
          height:0.42rem;
          width:0.42rem;
          display: block;
        }
        span{
          font-size: 0.2rem;
          color:#fff;
          display: none;
        }
        &:hover{
          img{
            display: none;
          }
          span{
            display: block;
          }
        }
        cursor: pointer;
      }
      .home_tit_p2{
        position: absolute;
        right:-1.3rem;
        height:0.42rem;
        width:0.42rem;
        top: 0.26rem;
        display: none;
        img{
          height:0.42rem;
          width:0.42rem;
          display: block;
        }
        span{
          font-size: 0.2rem;
          color:#fff;
          display: none;
        }
        &:hover{
          img{
            display: none;
          }
          span{
            display: block;
          }
        }
        ul{
          height:1.2rem;
          width:2rem;
          background:#fff;
          position: absolute;
          border-radius: 0.2rem;
          left:0.29rem;
          top: -0.1rem;
          padding: 0.2rem 0.1rem;
          opacity: 0.8;
          overflow-y:auto;
          &::-webkit-scrollbar {
              width: 0.2rem;
              height: 0.08rem;
          }

          &::-webkit-scrollbar-thumb {
              border-radius: 0.12rem;
              border: 0.06rem solid rgba(0, 0, 0, 0);
              box-shadow: 0.08rem 0 0 #A5ADB7 inset;
          }

          &::-webkit-scrollbar-thumb:hover {
              box-shadow: 0.08rem 0 0 #4A4A4A inset;
          }
          li{
            text-align: center;
            color:#1a2533;
            font-size: 0.14rem;
            line-height: 0.3rem;
            &:hover{
              background: #eee;
            }
          }
        }
        cursor: pointer;
      }
      .home_tit_p3{
        position: absolute;
        right:-1.18rem;
        height:0.42rem;
        width:0.42rem;
        top: 0.82rem;
        display: none;
        img{
          height:0.42rem;
          width:0.42rem;
          display: block;
        }
        span{
          font-size: 0.2rem;
          color:#fff;
          display: none;
        }
        &:hover{
          img{
            display: none;
          }
          span{
            display: block;
          }
        }
        cursor: pointer;
      }
      .home_tit_p4{
        position: absolute;
        right:-0.9rem;
        height:0.42rem;
        width:0.42rem;
        top: 1.45rem;
        display: none;
        s{
          display: inline-block;
          position: absolute;
          right: -0.1rem;
          color:#fff;
          top:-0.1rem;
          height:0.25rem;
          width:0.25rem;
          text-align: center;
          line-height: 0.25rem;
          background: #f96c6c;
          border-radius: 50%;
        }
        img{
          height:0.42rem;
          width:0.42rem;
          display: block;
        }
        span{
          font-size: 0.2rem;
          color:#fff;
          display: none;
        }
        &:hover{
          img{
            display: none;
          }
          span{
            display: block;
          }
        }
        cursor: pointer;
      }
      .home_tit_p5{
        position: absolute;
        right:-0.36rem;
        height:0.42rem;
        width:0.42rem;
        top: 1.85rem;
        display: none;
        img{
          height:0.42rem;
          width:0.42rem;
          display: block;
        }
        span{
          font-size: 0.2rem;
          color:#fff;
          display: none;
        }
        &:hover{
          img{
            display: none;
          }
          span{
            display: block;
          }
        }
        cursor: pointer;
      }
      .home_tit_p6{
        position: absolute;
        right:0.24rem;
        height:0.42rem;
        width:0.42rem;
        top: 1.96rem;
        display: none;
        img{
          height:0.42rem;
          width:0.42rem;
          display: block;
        }
        span{
          font-size: 0.2rem;
          color:#fff;
          display: none;
        }
        &:hover{
          img{
            display: none;
          }
          span{
            display: block;
          }
        }
        cursor: pointer;
      }
      .home_tit_p7{
        position: absolute;
        top:1.86rem;
        left:-0.21rem;
        height:0.42rem;
        width:0.42rem;
        display: none;
        img{
          height:0.42rem;
          width:0.42rem;
          display: block;
        }
        span{
          font-size: 0.2rem;
          color:#fff;
          display: none;
        }
        &:hover{
          img{
            display: none;
          }
          span{
            display: block;
          }
        }
        cursor: pointer;
      }
    }
    .touxiang{
      position: absolute;
      right:0.5rem;
      top:0.19rem;
      height:0.36rem;
      width: 2.3rem;
      display: flex;
      align-items: center;
      justify-content:space-between;
      padding-bottom: 0.06rem;
      cursor: pointer;
      img{
        height:0.36rem;
        width: 0.36rem;
        border-radius: 50%;
        margin-right: 0.07rem;
      }
      .touxiang_ph{
        height:0.36rem;
        width: 0.36rem;
        border-radius: 50%;
        margin-right: 0.04rem;
        border-radius: 50%;
        line-height: 0.36rem;
        background: #d2d2d2;
        color:#444;
        font-size: 0.2rem;
        text-align: center;
        font-family: NSimSun;
      }
      .icon-left{
        color:#c4c7cd;
        font-size: 0.1rem;
      }
      .icon-bottom{
        display: none;
        color:#c4c7cd;
        font-size: 0.1rem;
      }
      &:hover{
        .icon-left{
          display: none;
        }
        .icon-bottom{
          display: block;
        }
        .touxiang_box{
          display: block;
        }
      }
      .touxiang_box{
        height:1.4rem;
        width:2.8rem;
        background:#fff;
        border:0.01rem solid #f6f3f9;
        position: absolute;
        top: 0.42rem;
        right:0;
        padding: 0.24rem 0.19rem;
        display: none;
        .touxiang_box_tit{
          display: flex;
          align-items: center;
          padding-bottom: 0.25rem;
          border-bottom: 0.01rem solid #e6e9ee;
          .touxiang_icon{
            margin-right: 0.1rem;
            height:0.4rem;
            width: 0.4rem;
          }
          .touxiang_text{
            height:0.4rem;
            width: 0.4rem;
            border-radius: 50%;
            margin-right: 0.1rem;
            border-radius: 50%;
            line-height: 0.4rem;
            background: #d2d2d2;
            color:#444;
            font-size: 0.18rem;
            text-align: center;
            font-family: NSimSun;
          }
          .touxiang_box_tit_right{
            p{
              font-size: 0.12rem;
              width:1.5rem;
              color:#666;
              overflow: hidden;/*超出部分隐藏*/
              white-space: nowrap;/*不换行*/
              text-overflow:ellipsis;/*超出部分文字以...显示*/
              &:nth-child(1){
                margin-bottom: 0.05rem;
              }
            }
            .touxiang_box_tit_right_gongsi{
              display: flex;
              align-items: center;
              justify-content: space-between;
              p{
                margin: 0;
              }
              i{
                color:#c4c7cd;
                font-size: 0.06rem;
              }
            }
          }
        }
        .touxiang_box_bottom{
          margin-top: 0.25rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          li{
            &:nth-child(1){
              .ic-icon{
                background: url('../assets/me/gongzuo_hui.png') no-repeat center;
                background-size:0.3rem 0.26rem;
              }
              &:hover{
                .ic-icon{
                  background: url('../assets/me/gongzuo_zi.png') no-repeat center;
                  background-size:0.3rem 0.26rem;
                }
              }
            }
            &:nth-child(2){
              .ic-icon{
                background: url('../assets/me/xiaoxi_hui.png') no-repeat center;
                background-size: 0.3rem 0.22rem;
              }
              &:hover{
                .ic-icon{
                  background: url('../assets/me/xiaoxi_zi.png') no-repeat center;
                  background-size: 0.3rem 0.22rem;
                }
              }
            }
            &:nth-child(3){
              .ic-icon{
                background: url('../assets/me/shezhi_hui.png') no-repeat center;
                background-size:0.26rem 0.26rem;
              }
              &:hover{
                .ic-icon{
                  background: url('../assets/me/shezhi_zi.png') no-repeat center;
                  background-size:0.26rem 0.26rem;
                }
              }
            }
            &:nth-child(4){
              .ic-icon{
                background: url('../assets/me/tuichu_hui.png') no-repeat center;
                background-size:0.24rem 0.25rem;
              }
              &:hover{
                .ic-icon{
                  background: url('../assets/me/tuichu_zi.png') no-repeat center;
                  background-size:0.24rem 0.25rem;
                }
              }
            }
            .ic-icon{
              margin: 0 auto;
              height:0.26rem;
              width:0.3rem;
              margin-bottom: 0.12rem;
            }
            p{
              font-size: 0.12rem;
              color:#666;
            }
            &:hover{
              p{
                color:#9a86db;
              }
            }
          }
        }
      }
      .qiye_name{
        width:1.8rem;
        font-size: 0.16rem;
        color:#fff;
        overflow: hidden;/*超出部分隐藏*/
        white-space: nowrap;/*不换行*/
        text-overflow:ellipsis;/*超出部分文字以...显示*/
        line-height: 0.38rem;
      }
      // div{
      //   .qiye_name{
      //     width:1.8rem;
      //     font-size: 0.24rem;
      //     color:#fff;
      //     overflow: hidden;/*超出部分隐藏*/
      //     white-space: nowrap;/*不换行*/
      //     text-overflow:ellipsis;/*超出部分文字以...显示*/
      //     line-height: 0.38rem;
      //   }
      //   .geren_name{
      //     width:1.8rem;
      //     font-size: 0.2rem;
      //     color:#fff;
      //     overflow: hidden;/*超出部分隐藏*/
      //     white-space: nowrap;/*不换行*/
      //     text-overflow:ellipsis;/*超出部分文字以...显示*/
      //   }
      // }
    }
  }
  .shenpi{
    height:2rem;
    width:2rem;
    position: absolute;
    top:4rem;
    left:0;
    text-align: center;
    line-height: 2rem;
    background: red;
    cursor: pointer;
    border-radius: 50%;
    font-size: 0.24rem;
    color: #fff;
    z-index: 9999;
  }
  .paopi{
    height:1rem;
    width:1rem;
    position: absolute;
    top:4rem;
    left:2rem;
    text-align: center;
    line-height: 1rem;
    background: red;
    cursor: pointer;
    border-radius: 50%;
    font-size: 0.24rem;
    color: #fff;
    z-index: 9999;
  }
  .con {
    width: 6rem;
    margin: 0.7rem auto;
    .text_img1 {
      width:7.9rem;
      height:0.73rem;
      margin-top: 0.57rem;
      margin-left: -3.29rem;
    }
    .text_img2 {
      height: 0.58rem;
      width:5.37rem;
      margin-top: 0.44rem;
      margin-left: -3.25rem;
    }
  }
  .foot{
      width:11.40rem;
      margin: 0 auto;
      margin-top:2.53rem;
      display: flex;
      justify-content: center;
      li{
          width:25%;
          padding: 0.2rem 0;
          cursor: pointer;
          &:hover{
            background: #410b74;
        }
        div{
            img{
                display: block;
                margin: 0 auto;
                height:0.69rem;
                width:0.59rem;
                margin-bottom: 0.28rem;
            }
            p{
                &:nth-child(2){
                    font-size: 0.3rem;
                    color:#fff;
                    text-align: center;
                    margin-bottom: 0.32rem;
                }
                &:nth-child(3){
                    font-size: 0.18rem;
                    color:#fff;
                    text-align: center;
                    margin-bottom: 0.13rem;
                }
                &:nth-child(4){
                    font-size: 0.18rem;
                    color:#fff;
                    text-align: center;
                }
            }
        }
      }
    //   img{
    //       display: block;
    //       height:3.2rem;
    //       width:1.57rem;
    //       border-radius: 0.18rem;
    //       box-shadow: 0 0 0.2rem rgb(148, 149, 207);
    //       &:hover{
    //         margin-top: -0.4rem;
    //         border:0.01rem solid rgb(51, 112, 245);
    //       }
    //   }
  }
}
@media screen and (max-width: 1280px) {
  .home {
    // background-size:200%;
    width: 1280px;
  }
}
@media screen and (max-height: 690px) {
  .home {
    // background-size:200%;
    height: 690px;
  }
}
</style>
