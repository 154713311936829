import { single_login_check, brower_visit_server } from '../api/noapi.js'
function sanshi_miao_jiance () {
  if (sessionStorage.getItem('pc_mlbb_xinxi') !== undefined && sessionStorage.getItem('pc_mlbb_xinxi') !== null && sessionStorage.getItem('pc_mlbb_jichuxinxi') != undefined && sessionStorage.getItem('pc_mlbb_jichuxinxi') != null) {
    const date = JSON.parse(JSON.parse(sessionStorage.getItem('pc_mlbb_xinxi')))
    // let time=sessionStorage.getItem("pc_zkkj_time")
    const jichuxinxi = JSON.parse(JSON.parse(sessionStorage.getItem('pc_mlbb_jichuxinxi')))
    // let ent_id=JSON.parse(sessionStorage.getItem("pc_mlbb_ent_info")).ent_id
    console.log('登录之后30秒调一次', date.client_login_token)
    // console.log('登录之后30秒调一次',time)
    // brower_visit_server({data:{
    //     token:date.client_login_token,
    //     num:Number(time)+1,
    //     user_id:jichuxinxi.user_id,
    //     ent_id:ent_id
    // }}).then(res=>{
    //     time=Number(time)+1
    //     console.log(res)
    //     sessionStorage.setItem("pc_zkkj_time", JSON.stringify(time));
    // })
    single_login_check({
      data: {
        token: date.client_login_token,
        mobile: jichuxinxi.mobile,
        user_id: jichuxinxi.user_id
      }
    }).then(rus => {
      console.log(rus)
    })
  }
}
/* 定时器 间隔30秒检测是否长时间未操作页面 */
window.setInterval(sanshi_miao_jiance, 15000)
