/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 14:21:48
 * @LastEditTime: 2019-09-29 11:27:43
 * @LastEditors: Please set LastEditors
 */

import axios from "./ajax";
//发送验证码
export function sms(data) {
  return axios({
    method: 'post',
    url: "/sms",
    data
  })
}
// 注册接口
export function register(data) {
  return axios({
    method: 'post',
    url: "/register",
    data
  })
}
// 密码登录
export function pwd_login(data) {
  return axios({
    method: 'post',
    url: "/pwd_login",
    data
  })
}
// 修改密码接口忘记
export function forget_user_pwd(data) {
  return axios({
    method: 'post',
    url: "/forget_user_pwd",
    data
  })
}
// 修改密码接口
export function alter_user_pwd(data) {
  return axios({
    method: 'post',
    url: "/alter_user_pwd",
    data
  })
}
// 判断是否注册
export function register_judge(data) {
  return axios({
    method: 'post',
    url: "/register_judge",
    data
  })
}
// 企业认证
export function upload_img(data) {
  return axios({
    method: 'post',
    url: "/ent_info_aut",
    data
  })
}
// 企业账号登录
export function ent_acct_login(data) {
  return axios({
    method: 'post',
    url: "/ent_acct_login",
    data
  })
}
// 首次登陆修改企业账号密码
export function first_login_alter_ent_acct_pwd(data) {
  return axios({
    method: 'post',
    url: "/first_login_alter_ent_acct_pwd",
    data
  })
}
// 忘记密码
export function forget_ent_acct_pwd(data) {
  return axios({
    method: 'post',
    url: "/forget_ent_acct_pwd",
    data
  })
}
// 切换企业
export function toggle_user_ent(data) {
  return axios({
    method: 'post',
    url: "/toggle_user_ent",
    data
  })
}
// 获取用户相关企业列表
export function query_user_aut_ent_list(data) {
  return axios({
    method: 'post',
    url: "/query_user_aut_ent_list",
    data
  })
}
// 上传ex
export function import_dept(data) {
  return axios({
    method: 'post',
    url: "/import_dept",
    data
  })
}
// 查询主管理员
export function query_ent_primary_manager(data) {
  return axios({
    method: 'post',
    url: "/query_ent_primary_manager",
    data
  })
}
// 修改主管理员
export function update_ent_primary_manager(data) {
  return axios({
    method: 'post',
    url: "/update_ent_primary_manager",
    data
  })
}
// 删除主管理员
export function delete_ent_primary_manager(data) {
  return axios({
    method: 'post',
    url: "/delete_ent_primary_manager",
    data
  })
}
// 设置主管理员
export function set_ent_primary_manager(data) {
  return axios({
    method: 'post',
    url: "/set_ent_primary_manager",
    data
  })
}
// 添加企业认证的信息
export function add_ent_aut_info(data) {
  return axios({
    method: 'post',
    url: "/add_ent_aut_info",
    data
  })
}
// 查询用户认证的企业信息-单个
export function query_user_aut_ent(data) {
  return axios({
    method: 'post',
    url: "/query_user_aut_ent",
    data
  })
}
// 修改用户认证的企业信息-单个
export function update_ent_aut_info(data) {
  return axios({
    method: 'post',
    url: "/update_ent_aut_info",
    data
  })
}
// 设置字段库
export function set_field_base(data) {
  return axios({
    method: 'post',
    url: "/set_field_base",
    data
  })
}
// 查询人员
export function query_ent_staff_list(data) {
  return axios({
    method: 'post',
    url: "/query_ent_staff_list",
    data
  })
}
// 查询结构人员
export function query_dept_staff_list(data) {
  return axios({
    method: 'post',
    url: "/query_dept_staff_list",
    data
  })
}
// 所有人员
export function insert_staff(data) {
  return axios({
    method: 'post',
    url: "/insert_staff",
    data
  })
}
// 获取部门列表
export function query_ent_dept_list(data) {
  return axios({
    method: 'post',
    url: "/query_ent_dept_list",
    data
  })
}
// 修改部门信息
export function update_dept_info(data) {
  return axios({
    method: 'post',
    url: "/update_dept_info",
    data
  })
}
// 删除部门信息
export function delete_dept_info(data) {
  return axios({
    method: 'post',
    url: "/delete_dept_info",
    data
  })
}
// 修改员工
export function update_staff(data) {
  return axios({
    method: 'post',
    url: "/update_staff",
    data
  })
}
// 删除员工
export function delete_staff(data) {
  return axios({
    method: 'post',
    url: "/delete_staff",
    data
  })
}
// 获取我的基础信息
export function get_my_info(data) {
  return axios({
    method: 'post',
    url: "/get_my_info",
    data
  })
}
// 获取产品列表
export function query_prod_info_list(data) {
  return axios({
    method: 'post',
    url: "/query_prod_info_list",
    data
  })
}
// 修改产品
export function update_prod_info(data) {
  return axios({
    method: 'post',
    url: "/update_prod_info",
    data
  })
}
// 新增部门
export function set_ent_dept_info(data) {
  return axios({
    method: 'post',
    url: "/set_ent_dept_info",
    data
  })
}
// 新增产品--单个
export function insert_prod_info(data) {
  return axios({
    method: 'post',
    url: "/insert_prod_info",
    data
  })
}
// 删除产品————批量删除
export function delete_prod_info(data) {
  return axios({
    method: 'post',
    url: "/delete_prod_info",
    data
  })
}
// 查询财年
export function query_ent_fiscal_year(data) {
  return axios({
    method: 'post',
    url: "/query_ent_fiscal_year",
    data
  })
}
// 编辑财年
export function set_ent_fiscal_year(data) {
  return axios({
    method: 'post',
    url: "/set_ent_fiscal_year",
    data
  })
}
// 删除财年
export function delete_ent_fiscal_year(data) {
  return axios({
    method: 'post',
    url: "/delete_ent_fiscal_year",
    data
  })
}
// 获取当前是否有企业
export function get_rel_list(data) {
  return axios({
    method: 'post',
    url: "/get_rel_list",
    data
  })
}
// 退出登录
export function logout(data) {
  return axios({
    method: 'post',
    url: "/logout",
    data
  })
}
// 查询子管理员
export function query_ent_sub_manager(data) {
  return axios({
    method: 'post',
    url: "/query_ent_sub_manager",
    data
  })
}
// 设置子管理员
export function set_ent_sub_manager(data) {
  return axios({
    method: 'post',
    url: "/set_ent_sub_manager",
    data
  })
}
// 修改子管理员
export function update_ent_sub_manager(data) {
  return axios({
    method: 'post',
    url: "/update_ent_sub_manager",
    data
  })
}
// 删除子管理员
export function delete_ent_sub_manager(data) {
  return axios({
    method: 'post',
    url: "/delete_ent_sub_manager",
    data
  })
}
// // 获取相关企业信息
export function query_user_ent_list(data) {
  return axios({
    method: 'post',
    url: "/query_user_ent_list",
    data
  })
}
// 查询字段库
export function query_field_base(data) {
  return axios({
    method: 'post',
    url: "/query_field_base",
    data
  })
}
// 字段库转化
export function update_field_base(data) {
  return axios({
    method: 'post',
    url: "/update_field_base",
    data
  })
}
// 新增自定义字段
export function insert_field_base(data) {
  return axios({
    method: 'post',
    url: "/insert_field_base",
    data
  })
}
// 批量删除自定义字段
export function delete_field_base_batch(data) {
  return axios({
    method: 'post',
    url: "/delete_field_base_batch",
    data
  })
}
// 查询方法
export function query_method_set(data) {
  return axios({
    method: 'post',
    url: "/query_method_set",
    data
  })
}
// 修改核算里面数据
export function update_con_stage_rate_batch(data) {
  return axios({
    method: 'post',
    url: "/update_con_stage_rate_batch",
    data
  })
}
// 修改分析数据方法
export function update_method_set(data) {
  return axios({
    method: 'post',
    url: "/update_method_set",
    data
  })
}
// 模块开启状态
export function query_module_status(data) {
  return axios({
    method: 'post',
    url: "/query_module_status",
    data
  })
}
// 修改模块开启状态
export function update_module_status(data) {
  return axios({
    method: 'post',
    url: "/update_module_status",
    data
  })
}
// 模糊查询
export function get_staff_list_like(data) {
  return axios({
    method: 'post',
    url: "/get_staff_list_like",
    data
  })
}
// 查询产品种类list
export function query_category_info_list(data) {
  return axios({
    method: 'post',
    url: "/query_category_info_list",
    data
  })
}
// 新增产品类型
export function insert_category_info(data) {
  return axios({
    method: 'post',
    url: "/insert_category_info",
    data
  })
}
// 修改产品类型
export function update_category_info(data) {
  return axios({
    method: 'post',
    url: "/update_category_info",
    data
  })
}
// 删除产品类型
export function delete_category_info(data) {
  return axios({
    method: 'post',
    url: "/delete_category_info",
    data
  })
}
// 查询导入日志
export function query_import_log_list(data) {
  return axios({
    method: 'post',
    url: "/query_import_log_list",
    data
  })
}
// 科目列表
export function query_subject_info_list(data) {
  return axios({
    method: 'post',
    url: "/query_subject_info_list",
    data
  })
}
// 新增科目
export function insert_subject_info(data) {
  return axios({
    method: 'post',
    url: "/insert_subject_info",
    data
  })
}
// 批量删除科目
export function delete_subject_info(data) {
  return axios({
    method: 'post',
    url: "/delete_subject_info",
    data
  })
}
// 批量修改产品启用停用状态
export function update_subject_active_batch(data) {
  return axios({
    method: 'post',
    url: "/update_subject_active_batch",
    data
  })
}
// 修改科目
export function update_subject_info(data) {
  return axios({
    method: 'post',
    url: "/update_subject_info",
    data
  })
}
//获取系统日志接口
export function get_system_logs_list(data) {
  return axios({
    method: 'post',
    url: "/get_system_logs_list",
    data
  })
}
//查询主管理员详情
export function query_ent_primary_manager_detail(data) {
  return axios({
    method: 'post',
    url: "/query_ent_primary_manager_detail",
    data
  })
}
//查询销售的日志
export function query_import_log_list_sale(data) {
  return axios({
    method: 'post',
    url: "/query_import_log_list_sale",
    data
  })
}
//批量离职接口
export function update_staff_active_batch(data) {
  return axios({
    method: 'post',
    url: "/update_staff_active_batch",
    data
  })
}
//批量产品类型停启用
export function update_prod_category_active_batch(data) {
  return axios({
    method: 'post',
    url: "/update_prod_category_active_batch",
    data
  })
}
//批量产品停启用
export function update_prod_active_batch(data) {
  return axios({
    method: 'post',
    url: "/update_prod_active_batch",
    data
  })
}
//批量产品停启用---模糊查询
export function get_prod_list_like(data) {
  return axios({
    method: 'post',
    url: "/get_prod_list_like",
    data
  })
}
//批量部门停用
export function update_dept_active_batch(data) {
  return axios({
    method: 'post',
    url: "/update_dept_active_batch",
    data
  })
}
//部门树形
export function query_ent_dept_list_aim(data) {
  return axios({
    method: 'post',
    url: "/query_ent_dept_list_aim",
    data
  })
}
// 科目list    固定经费和变动经费
export function query_subject_info_list_fixed_variable(data) {
  return axios({
    method: 'post',
    url: "/query_subject_info_list_fixed_variable",
    data
  })
}
// 查询各个图表的已有年份列表
export function query_as_year_list(data) {
  return axios({
    method: 'post',
    url: "/query_as_year_list",
    data
  })
}
// 查询企业核算科目list目标模块
export function query_ent_subject_list(data) {
  return axios({
    method: 'post',
    url: "/query_ent_subject_list",
    data
  })
}
// 查询判断科目表中是否有重复的编码
export function query_subject_code(data) {
  return axios({
    method: 'post',
    url: "/query_subject_code",
    data
  })
}
// 新增盈亏平衡表
export function insert_as_breakeven(data) {
  return axios({
    method: 'post',
    url: "/insert_as_breakeven",
    data
  })
}
// 查询企业核算科目list 明细
export function query_ent_subject_list_detail(data) {
  return axios({
    method: 'post',
    url: "/query_ent_subject_list_detail",
    data
  })
}
// 查询企业核算科目list tree
export function query_ent_subject_list_tree(data) {
  return axios({
    method: 'post',
    url: "/query_ent_subject_list_tree",
    data
  })
}
// 查询盈亏平衡表
export function query_as_breakeven(data) {
  return axios({
    method: 'post',
    url: "/query_as_breakeven",
    data
  })
}
// 生成盈亏平衡表图表
export function generate_as_breakeven_picture(data) {
  return axios({
    method: 'post',
    url: "/generate_as_breakeven_picture",
    data
  })
}
// 盈亏平衡测算--模拟测算
export function compute_as_breakeven(data) {
  return axios({
    method: 'post',
    url: "/compute_as_breakeven",
    data
  })
}
// 查询企业产品(类型)list--树状图
export function query_ent_prod_list_tree(data) {
  return axios({
    method: 'post',
    url: "/query_ent_prod_list_tree",
    data
  })
}
// 查询产品销售目标
export function query_as_aim(data) {
  return axios({
    method: 'post',
    url: "/query_as_aim",
    data
  })
}
// 保存产品销售目标
export function insert_as_prod_aim(data) {
  return axios({
    method: 'post',
    url: "/insert_as_prod_aim",
    data
  })
}
// 调整产品销售目标
export function update_as_prod_aim(data) {
  return axios({
    method: 'post',
    url: "/update_as_prod_aim",
    data
  })
}
// 查询产品销售目标调整日志
export function query_as_prod_aim_log(data) {
  return axios({
    method: 'post',
    url: "/query_as_prod_aim_log",
    data
  })
}
// 生成《产品销售目标》条形图
export function generate_as_prod_aim_bar_chart(data) {
  return axios({
    method: 'post',
    url: "/generate_as_prod_aim_bar_chart",
    data
  })
}
// 生成《产品销售目标》饼图
export function generate_as_prod_aim_pie_chart(data) {
  return axios({
    method: 'post',
    url: "/generate_as_prod_aim_pie_chart",
    data
  })
}
// 查询企业部门list   带目标属性最底层部门平铺
export function query_ent_dept_list_aim_tile(data) {
  return axios({
    method: 'post',
    url: "/query_ent_dept_list_aim_tile",
    data
  })
}
// 获取《产品销售目标》中的产品列表
export function query_prod_list_of_as_prod_aim(data) {
  return axios({
    method: 'post',
    url: "/query_prod_list_of_as_prod_aim",
    data
  })
}
// 查询产品销售规则
export function query_as_prod_rule(data) {
  return axios({
    method: 'post',
    url: "/query_as_prod_rule",
    data
  })
}
// 设置产品销售规则
export function insert_as_prod_rule(data) {
  return axios({
    method: 'post',
    url: "/insert_as_prod_rule",
    data
  })
}
// 提供《部门销售目标》数据（自动分配任务）
export function supply_as_dept_aim_data(data) {
  return axios({
    method: 'post',
    url: "/supply_as_dept_aim_data",
    data
  })
}
// 生成《部门销售目标》图形
export function generate_as_dept_aim(data) {
  return axios({
    method: 'post',
    url: "/generate_as_dept_aim",
    data
  })
}
// 调整《部门销售目标》
export function update_as_dept_aim(data) {
  return axios({
    method: 'post',
    url: "/update_as_dept_aim",
    data
  })
}
// 查询<部门销售目标> 调整日志
export function query_as_dept_aim_log(data) {
  return axios({
    method: 'post',
    url: "/query_as_dept_aim_log",
    data
  })
}
// 查询<人员销售目标》数据（手动分配任务）
export function query_as_staff_aim(data) {
  return axios({
    method: 'post',
    url: "/query_as_staff_aim",
    data
  })
}
// 提供<人员销售目标》数据（手动分配任务）
export function supply_as_staff_aim_data(data) {
  return axios({
    method: 'post',
    url: "/supply_as_staff_aim_data",
    data
  })
}
// 新增《人员销售目标》
export function insert_as_staff_aim(data) {
  return axios({
    method: 'post',
    url: "/insert_as_staff_aim",
    data
  })
}
// 调整《人员销售目标》
export function update_as_staff_aim(data) {
  return axios({
    method: 'post',
    url: "/update_as_staff_aim",
    data
  })
}
// 《人员销售目标》历史查询
export function query_as_staff_aim_log(data) {
  return axios({
    method: 'post',
    url: "/query_as_staff_aim_log",
    data
  })
}
// 《人员销售目标》图
export function generate_as_staff_aim(data) {
  return axios({
    method: 'post',
    url: "/generate_as_staff_aim",
    data
  })
}
// 提供销售目标进度表
export function supply_as_aim_plan_data(data) {
  return axios({
    method: 'post',
    url: "/supply_as_aim_plan_data",
    data
  })
}
// 新建销售目标进度表
export function insert_as_aim_plan(data) {
  return axios({
    method: 'post',
    url: "/insert_as_aim_plan",
    data
  })
}
// 查询销售目标进度表
export function query_as_aim_plan(data) {
  return axios({
    method: 'post',
    url: "/query_as_aim_plan",
    data
  })
}
// 生成《销售目标进度》图
export function generate_as_aim_plan(data) {
  return axios({
    method: 'post',
    url: "/generate_as_aim_plan",
    data
  })
}
// 生成《销售任务责任书》图
export function generate_as_aim_resp(data) {
  return axios({
    method: 'post',
    url: "/generate_as_aim_resp",
    data
  })
}
// 合同日报
export function get_con_daily(data) {
  return axios({
    method: 'post',
    url: "/get_con_daily",
    data
  })
}
// 回款日报
export function get_rec_daily(data) {
  return axios({
    method: 'post',
    url: "/get_rec_daily",
    data
  })
}
// 发票日报
export function get_inv_daily(data) {
  return axios({
    method: 'post',
    url: "/get_inv_daily",
    data
  })
}
// 合同日报顾过去
export function get_con_daily_history(data) {
  return axios({
    method: 'post',
    url: "/get_con_daily_history",
    data
  })
}
// 发票日报顾过去
export function get_inv_daily_history(data) {
  return axios({
    method: 'post',
    url: "/get_inv_daily_history",
    data
  })
}
// 回款日报顾过去
export function get_rec_daily_history(data) {
  return axios({
    method: 'post',
    url: "/get_rec_daily_history",
    data
  })
}
// 合同月报
export function get_con_month(data) {
  return axios({
    method: 'post',
    url: "/get_con_month",
    data
  })
}
// 合同月报历史记录
export function get_con_month_history(data) {
  return axios({
    method: 'post',
    url: "/get_con_month_history",
    data
  })
}
// 到款月报
export function get_rec_month(data) {
  return axios({
    method: 'post',
    url: "/get_rec_month",
    data
  })
}
// 发票月报
export function get_inv_month(data) {
  return axios({
    method: 'post',
    url: "/get_inv_month",
    data
  })
}
// 发票月报历史
export function get_inv_month_history(data) {
  return axios({
    method: 'post',
    url: "/get_inv_month_history",
    data
  })
}
// 到款月报历史
export function get_rec_month_history(data) {
  return axios({
    method: 'post',
    url: "/get_rec_month_history",
    data
  })
}
// 合同年报
export function get_con_year(data) {
  return axios({
    method: 'post',
    url: "/get_con_year",
    data
  })
}
// 发票年报
export function get_inv_year(data) {
  return axios({
    method: 'post',
    url: "/get_inv_year",
    data
  })
}
// 到款年报
export function get_rec_year(data) {
  return axios({
    method: 'post',
    url: "/get_rec_year",
    data
  })
}
// 查询角色组list
export function query_role_group_info_list(data) {
  return axios({
    method: 'post',
    url: "/query_role_group_info_list",
    data
  })
}
// 新增角色组
export function insert_role_group_info(data) {
  return axios({
    method: 'post',
    url: "/insert_role_group_info",
    data
  })
}
// 新增角色
export function insert_role_info(data) {
  return axios({
    method: 'post',
    url: "/insert_role_info",
    data
  })
}
// 查询角色list
export function query_role_info_list(data) {
  return axios({
    method: 'post',
    url: "/query_role_info_list",
    data
  })
}
// 修改角色组
export function update_role_group_info(data) {
  return axios({
    method: 'post',
    url: "/update_role_group_info",
    data
  })
}
// 修改角色
export function update_role_info(data) {
  return axios({
    method: 'post',
    url: "/update_role_info",
    data
  })
}
// 删除角色组
export function delete_role_group_info(data) {
  return axios({
    method: 'post',
    url: "/delete_role_group_info",
    data
  })
}
// 删除角色
export function delete_role_info(data) {
  return axios({
    method: 'post',
    url: "/delete_role_info",
    data
  })
}
// 审批流程
export function insert_flow_action_info(data) {
  return axios({
    method: 'post',
    url: "/insert_flow_action_info",
    data
  })
}
// 新增审批流程的流程设置str
export function insert_flow_action_str(data) {
  return axios({
    method: 'post',
    url: "/insert_flow_action_str",
    data
  })
}
// 新增审批表单设置str
export function insert_flow_form_info(data) {
  return axios({
    method: 'post',
    url: "/insert_flow_form_info",
    data
  })
}
// 新增审批基础高级设置
export function insert_flow_info(data) {
  return axios({
    method: 'post',
    url: "/insert_flow_info",
    data
  })
}
// 查询审批list
export function query_flow_info_list(data) {
  return axios({
    method: 'post',
    url: "/query_flow_info_list",
    data
  })
  // datas.then(res=>{
  //   console.log(JSON.parse(res.data.body.data),'这是获取的数据1')
  // })


}
// 查询审批流程
export function query_flow_info_all(data) {
  return axios({
    method: 'post',
    url: "/query_flow_info_all",
    data
  })
}
// 修改审批流程的流程设置
export function update_flow_action_info(data) {
  return axios({
    method: 'post',
    url: "/update_flow_action_info",
    data
  })
}
// 使用表单
export function start_flow_action(data) {
  return axios({
    method: 'post',
    url: "/start_flow_action",
    data
  })
}
// 关联客户列表list
export function query_rel_customer_list(data) {
  return axios({
    method: 'post',
    url: "/query_rel_customer_list",
    data
  })
}
// 关联客户的决策人列表list
export function query_rel_customer_decision_list(data) {
  return axios({
    method: 'post',
    url: "/query_rel_customer_decision_list",
    data
  })
}
// 关联拜访记录列表list
export function query_rel_visit_record_list(data) {
  return axios({
    method: 'post',
    url: "/query_rel_visit_record_list",
    data
  })
}
// 关联漏斗单列表list
export function query_rel_funn_list(data) {
  return axios({
    method: 'post',
    url: "/query_rel_funn_list",
    data
  })
}
// 关联合同单列表list
export function query_rel_contract_list(data) {
  return axios({
    method: 'post',
    url: "/query_rel_contract_list",
    data
  })
}
// 关联发票单列表list
export function query_rel_invoice_list(data) {
  return axios({
    method: 'post',
    url: "/query_rel_invoice_list",
    data
  })
}
// 查询合同相关信息列表list
export function query_contract_rel_info_list(data) {
  return axios({
    method: 'post',
    url: "/query_contract_rel_info_list",
    data
  })
}
// 查询审批流程的表单str————私有
export function query_flow_form_info_list(data) {
  return axios({
    method: 'post',
    url: "/query_flow_form_info_list",
    data
  })
}
// 表单设计——私有
export function replace_flow_form_info_private(data) {
  return axios({
    method: 'post',
    url: "/replace_flow_form_info_private",
    data
  })
}
// 待我审批的列表list
export function query_flow_list_wait_me_for_appr(data) {
  return axios({
    method: 'post',
    url: "/query_flow_list_wait_me_for_appr",
    data
  })
}
// 我发起的列表list
export function query_flow_list_start_by_me(data) {
  return axios({
    method: 'post',
    url: "/query_flow_list_start_by_me",
    data
  })
}
// 我已审批的列表list
export function query_flow_list_have_appr_by_me(data) {
  return axios({
    method: 'post',
    url: "/query_flow_list_have_appr_by_me",
    data
  })
}
// 抄送我的列表list
export function query_flow_list_copy_to_me(data) {
  return axios({
    method: 'post',
    url: "/query_flow_list_copy_to_me",
    data
  })
}
// 新增评论
export function insert_flow_cmt(data) {
  return axios({
    method: 'post',
    url: "/insert_flow_cmt",
    data
  })
}
// 查询评论
export function query_flow_cmt_list(data) {
  return axios({
    method: 'post',
    url: "/query_flow_cmt_list",
    data
  })
}
// 审批的详情信息
export function query_appr_detail(data) {
  return axios({
    method: 'post',
    url: "/query_appr_detail",
    data
  })
}
// 审批审核动作
export function appr_flow_action(data) {
  return axios({
    method: 'post',
    url: "/appr_flow_action",
    data
  })
}
// 审批转交动作
export function deliver_flow_action(data) {
  return axios({
    method: 'post',
    url: "/deliver_flow_action",
    data
  })
}
// 新增审批流程基础信息————公开
export function insert_flow_info_public(data) {
  return axios({
    method: 'post',
    url: "/insert_flow_info_public",
    data
  })
}
// 发起审批前验证是否单据已设置审批
export function query_flow_form_have_set(data) {
  return axios({
    method: 'post',
    url: "/query_flow_form_have_set",
    data
  })
}
// 判断客户是否已经存在
export function customer_have_exist(data) {
  return axios({
    method: 'post',
    url: "/customer_have_exist",
    data
  })
}
// 判断营业执照账号是否已经存在
export function biz_lic_no_have_exist(data) {
  return axios({
    method: 'post',
    url: "/biz_lic_no_have_exist",
    data
  })
}
// ------------------------------------------------------------------------------
// 拜访记录单
export function insert_s_visit_record(data) {
  return axios({
    method: 'post',
    url: "/insert_s_visit_record",
    data
  })
}
// 漏斗单
export function insert_funn(data) {
  return axios({
    method: 'post',
    url: "/insert_funn",
    data
  })
}
// 派工单
export function insert_s_dispatch(data) {
  return axios({
    method: 'post',
    url: "/insert_s_dispatch",
    data
  })
}
// 合同单
export function insert_contract(data) {
  return axios({
    method: 'post',
    url: "/insert_contract",
    data
  })
}
// 发票单
export function insert_invoice(data) {
  return axios({
    method: 'post',
    url: "/insert_invoice",
    data
  })
}
// 回款单
export function insert_received_cash(data) {
  return axios({
    method: 'post',
    url: "/insert_received_cash",
    data
  })
}
// 出差
export function insert_s_biz_trip(data) {
  return axios({
    method: 'post',
    url: "/insert_s_biz_trip",
    data
  })
}
// 交付单
export function insert_s_deliver(data) {
  return axios({
    method: 'post',
    url: "/insert_s_deliver",
    data
  })
}
// 外出单
export function insert_s_go_out(data) {
  return axios({
    method: 'post',
    url: "/insert_s_go_out",
    data
  })
}
// 客户录入单
export function insert_customer(data) {
  return axios({
    method: 'post',
    url: "/insert_customer",
    data
  })
}
// 访客计划单
export function insert_s_visit_plan(data) {
  return axios({
    method: 'post',
    url: "/insert_s_visit_plan",
    data
  })
}
// 拜访记录单中的漏斗现阶段取值
export function query_stage_from_plan(data) {
  return axios({
    method: 'post',
    url: "/query_stage_from_plan",
    data
  })
}
// 新增客户录入单
export function insert_customer_form(data) {
  return axios({
    method: 'post',
    url: "/insert_customer_form",
    data
  })
}
// 单据——外出单（）年外出时长
export function query_go_out_year_hour_long(data) {
  return axios({
    method: 'post',
    url: "/query_go_out_year_hour_long",
    data
  })
}
// 单据——出差单（）年累计出差天数
export function query_year_trip_days(data) {
  return axios({
    method: 'post',
    url: "/query_year_trip_days",
    data
  })
}
// 三位一体   合同
export function trinity_contract(data) {
  return axios({
    method: 'post',
    url: "/trinity_contract",
    data
  })
}
// 三位一体   发票
export function trinity_invoice(data) {
  return axios({
    method: 'post',
    url: "/trinity_invoice",
    data
  })
}
// 三位一体   回款
export function trinity_received_cash(data) {
  return axios({
    method: 'post',
    url: "/trinity_received_cash",
    data
  })
}
// 眼睛--单据列表
export function query_form_list(data) {
  return axios({
    method: 'post',
    url: "/query_form_list",
    data
  })
}
// 访客计划单（）漏斗现阶段
export function query_stage_from_record_and_funn(data) {
  return axios({
    method: 'post',
    url: "/query_stage_from_record_and_funn",
    data
  })
}
// 判断漏斗表合同表中是否已经存在归档号
export function funn_contract_have_exist_file_no(data) {
  return axios({
    method: 'post',
    url: "/funn_contract_have_exist_file_no",
    data
  })
}
// 单据--发票单（）合同金额，累计发票金额
export function query_con_amt_and_inv_amt_sum(data) {
  return axios({
    method: 'post',
    url: "/query_con_amt_and_inv_amt_sum",
    data
  })
}
// 单据--回款金额
export function query_rec_amt_sum_and_inv_amt_sum(data) {
  return axios({
    method: 'post',
    url: "/query_rec_amt_sum_and_inv_amt_sum",
    data
  })
}
// 单据--合同单明细
export function get_prod_detail_for_deliver(data) {
  return axios({
    method: 'post',
    url: "/get_prod_detail_for_deliver",
    data
  })
}
// 客户管理--已分配列表
export function query_customer_list_alloted(data) {
  return axios({
    method: 'post',
    url: "/query_customer_list_alloted",
    data
  })
}
// 客户管理--公海池列表
export function query_customer_list_sea(data) {
  return axios({
    method: 'post',
    url: "/query_customer_list_sea",
    data
  })
}
// 客户管理--冷冻仓列表
export function query_customer_list_cold(data) {
  return axios({
    method: 'post',
    url: "/query_customer_list_cold",
    data
  })
}
// 客户分配规则明细
export function query_customer_allot_rule_detail(data) {
  return axios({
    method: 'post',
    url: "/query_customer_allot_rule_detail",
    data
  })
}
// 设置分配规则
export function set_customer_allot_rule(data) {
  return axios({
    method: 'post',
    url: "/set_customer_allot_rule",
    data
  })
}
// 客户等级列表list
export function query_cust_level_list(data) {
  return axios({
    method: 'post',
    url: "/query_cust_level_list",
    data
  })
}
// 客户管理主界面
export function customer_manager_main_interface(data) {
  return axios({
    method: 'post',
    url: "/customer_manager_main_interface",
    data
  })
}
// 销售管理————柱状图
export function generate_sale_mng_bar_chart(data) {
  return axios({
    method: 'post',
    url: "/generate_sale_mng_bar_chart",
    data
  })
}
// 销售管理————看板
export function generate_sale_mng_board(data) {
  return axios({
    method: 'post',
    url: "/generate_sale_mng_board",
    data
  })
}
// 新增销售管理预警规则
export function insert_sale_mng_warn_rule(data) {
  return axios({
    method: 'post',
    url: "/insert_sale_mng_warn_rule",
    data
  })
}
// 查询销售管理预警规则
export function query_sale_mng_warn_rule(data) {
  return axios({
    method: 'post',
    url: "/query_sale_mng_warn_rule",
    data
  })
}
// 查询主管的员工list
export function query_mng_staff_list(data) {
  return axios({
    method: 'post',
    url: "/query_mng_staff_list",
    data
  })
}
// 单据——漏斗单现阶段
export function query_funn_stage_for_funn(data) {
  return axios({
    method: 'post',
    url: "/query_funn_stage_for_funn",
    data
  })
}
// 组织结构树，既有部门，又有人员tree
export function query_dept_staff_tree_aim(data) {
  return axios({
    method: 'post',
    url: "/query_dept_staff_tree_aim",
    data
  })
}
// 我设置
export function update_user_info(data) {
  return axios({
    method: 'post',
    url: "/update_user_info",
    data
  })
}
// 邮箱认证
export function send_check_user_email(data) {
  return axios({
    method: 'post',
    url: "/send_check_user_email",
    data
  })
}
// 验证邮箱
export function check_user_email(data) {
  return axios({
    method: 'post',
    url: "/check_user_email",
    data
  })
}
// 我的建议
export function add_my_advise(data) {
  return axios({
    method: 'post',
    url: "/add_my_advise",
    data
  })
}
// 设置角色员工的关系
export function set_staff_role_rel(data) {
  return axios({
    method: 'post',
    url: "/set_staff_role_rel",
    data
  })
}
// 角色下的人员列表
export function query_user_list_of_role(data) {
  return axios({
    method: 'post',
    url: "/query_user_list_of_role",
    data
  })
}
// 设置用户与模块的关系
export function set_user_module_rel(data) {
  return axios({
    method: 'post',
    url: "/set_user_module_rel",
    data
  })
}
// 删除角色的员工
export function delete_staff_role_rel(data) {
  return axios({
    method: 'post',
    url: "/delete_staff_role_rel",
    data
  })
}
// 模块下的人员列表
export function query_user_list_of_module(data) {
  return axios({
    method: 'post',
    url: "/query_user_list_of_module",
    data
  })
}
// 多个员工批量设置部门
export function batch_set_dept_for_many_staff(data) {
  return axios({
    method: 'post',
    url: "/batch_set_dept_for_many_staff",
    data
  })
}
// 查询当前人的部门
export function query_staff_dept_list(data) {
  return axios({
    method: 'post',
    url: "/query_staff_dept_list",
    data
  })
}
// 加售卖规则的产品树
export function query_prod_tree_sale_rule(data) {
  return axios({
    method: 'post',
    url: "/query_prod_tree_sale_rule",
    data
  })
}
// 派工单明细
export function query_s_dispatch_detail(data) {
  return axios({
    method: 'post',
    url: "/query_s_dispatch_detail",
    data
  })
}
// 判断该信用代码是否注册过企业
export function aut_ent_have_exist(data) {
  return axios({
    method: 'post',
    url: "/aut_ent_have_exist",
    data
  })
}
// 查询企业员工数及已注册数
export function query_ent_staffNum_registerNum(data) {
  return axios({
    method: 'post',
    url: "/query_ent_staffNum_registerNum",
    data
  })
}
// 查询企业部门list 不带目标属性tree结构
export function query_ent_dept_list_aim_not(data) {
  return axios({
    method: 'post',
    url: "/query_ent_dept_list_aim_not",
    data
  })
}
// 解散认证企业
export function dismiss_aut_ent(data) {
  return axios({
    method: 'post',
    url: "/dismiss_aut_ent",
    data
  })
}
// 调整部门顺序
export function update_dept_sort(data) {
  return axios({
    method: 'post',
    url: "/update_dept_sort",
    data
  })
}
// 查询企业员工列表--未注册
export function query_staff_list_register_not(data) {
  return axios({
    method: 'post',
    url: "/query_staff_list_register_not",
    data
  })
}
// 设置模块权限
export function set_module_power(data) {
  return axios({
    method: 'post',
    url: "/set_module_power",
    data
  })
}
// 模块下的权限明细
export function query_module_power_detail(data) {
  return axios({
    method: 'post',
    url: "/query_module_power_detail",
    data
  })
}
// 查询子管理员列表
export function query_ent_sub_manager_list(data) {
  return axios({
    method: 'post',
    url: "/query_ent_sub_manager_list",
    data
  })
}
// 查询子管理员列表
export function query_ent_sub_manager_list_by_module(data) {
  return axios({
    method: 'post',
    url: "/query_ent_sub_manager_list_by_module",
    data
  })
}
// 所有部门人员树
export function query_dept_staff_tree_aim_not(data) {
  return axios({
    method: 'post',
    url: "/query_dept_staff_tree_aim_not",
    data
  })
}
// 批量修改产品类型顺序
export function update_category_sort(data) {
  return axios({
    method: 'post',
    url: "/update_category_sort",
    data
  })
}
// 批量修改产品顺序
export function update_prod_sort(data) {
  return axios({
    method: 'post',
    url: "/update_prod_sort",
    data
  })
}
// 浏览器30秒访问一次服务器
export function brower_visit_server(data) {
  return axios({
    method: 'post',
    url: "/brower_visit_server",
    data
  })
}
// // 登录之后传
// export function trigger_backup_num(data) {
//   return axios({
//     method: 'post',
//     url: "/trigger_backup_num",
//     data
//   })
// }
// 登录之后传
export function trigger_check_num(data) {
  return axios({
    method: 'post',
    url: "/trigger_check_num",
    data
  })
}
// 产品大类树
export function query_ent_prod_cat_list_tree(data) {
  return axios({
    method: 'post',
    url: "/query_ent_prod_cat_list_tree",
    data
  })
}
// 修改产品种类
export function update_prod_cat_batch(data) {
  return axios({
    method: 'post',
    url: "/update_prod_cat_batch",
    data
  })
}
// 修改产品种类种类
export function update_prod_cat_cat_batch(data) {
  return axios({
    method: 'post',
    url: "/update_prod_cat_cat_batch",
    data
  })
}
// 停用产品种类查询
export function query_cat_list_active_0(data) {
  return axios({
    method: 'post',
    url: "/query_cat_list_active_0",
    data
  })
}
// 查询部门及多级子部门下的所有人员
export function query_all_staff_list_of_dept(data) {
  return axios({
    method: 'post',
    url: "/query_all_staff_list_of_dept",
    data
  })
}
// 查询员工的部门列表（一个员工可能存在于多个部门中）
export function query_dept_list_of_staff(data) {
  return axios({
    method: 'post',
    url: "/query_dept_list_of_staff",
    data
  })
}
// // 表单的审批流程列表
// export function query_form_appr_flow_list(data) {
//   return axios({
//     method: 'post',
//     url: "/query_form_appr_flow_list",
//     data
//   })
// }
//新增审批流程步骤列表
export function add_appr_flow_step_list(data) {
  return axios({
    method: 'post',
    url: "/add_appr_flow_step_list",
    data
  })
}
//审批中心展示列表
export function query_form_use_power_list(data) {
  return axios({
    method: 'post',
    url: "/query_form_use_power_list",
    data
  })
}
//查询客户的归档号列表
export function query_customer_file_list(data) {
  return axios({
    method: 'post',
    url: "/query_customer_file_list",
    data
  })
}
//撤回接口
export function undo_flow_action(data) {
  return axios({
    method: 'post',
    url: "/undo_flow_action",
    data
  })
}
//查询表单的管理者
export function query_manager_of_form(data) {
  return axios({
    method: 'post',
    url: "/query_manager_of_form",
    data
  })
}
//查询审批进程列表
export function query_appr_step_list(data) {
  return axios({
    method: 'post',
    url: "/query_appr_step_list",
    data
  })
}
//表单管理权限列表list
export function query_form_manage_power_list(data) {
  return axios({
    method: 'post',
    url: "/query_form_manage_power_list",
    data
  })
}
//眼睛--单据列表
export function query_form_list_file_nos(data) {
  return axios({
    method: 'post',
    url: "/query_form_list_file_nos",
    data
  })
}
//查询退回的审批节点列表
export function query_back_appr_step_list(data) {
  return axios({
    method: 'post',
    url: "/query_back_appr_step_list",
    data
  })
}
//判断科目编码是否已存在
export function subject_have_exist(data) {
  return axios({
    method: 'post',
    url: "/subject_have_exist",
    data
  })
}
//检验产品销售目标与实际发生的金额
export function check_prod_aim_and_con_amt(data) {
  return axios({
    method: 'post',
    url: "/check_prod_aim_and_con_amt",
    data
  })
}
//设置目标模块权限
export function set_aim_module_power(data) {
  return axios({
    method: 'post',
    url: "/set_aim_module_power",
    data
  })
}
//查看模块下的权限明细
export function query_aim_module_power_detail(data) {
  return axios({
    method: 'post',
    url: "/query_aim_module_power_detail",
    data
  })
}
//查询人员的目标模块权限列表
export function query_aim_module_power_list(data) {
  return axios({
    method: 'post',
    url: "/query_aim_module_power_list",
    data
  })
}
//查询（销售目标进度）冲刺接口
export function query_as_aim_plan_spurt(data) {
  return axios({
    method: 'post',
    url: "/query_as_aim_plan_spurt",
    data
  })
}
//客户管理--退回
export function back_customer(data) {
  return axios({
    method: 'post',
    url: "/back_customer",
    data
  })
}
//客户管理--激活
export function activate_customer(data) {
  return axios({
    method: 'post',
    url: "/activate_customer",
    data
  })
}
//客户管理--指派
export function allot_customer(data) {
  return axios({
    method: 'post',
    url: "/allot_customer",
    data
  })
}
//人员对应的下属所有人及以下属人员的部门列表
export function query_after_user_info_by_user_id(data) {
  return axios({
    method: 'post',
    url: "/query_after_user_info_by_user_id",
    data
  })
}
//用户身份信息：主管理员，子管理员，主管等
export function query_user_info(data) {
  return axios({
    method: 'post',
    url: "/query_user_info",
    data
  })
}
//系统分配客户
export function system_allot_customer(data) {
  return axios({
    method: 'post',
    url: "/system_allot_customer",
    data
  })
}
//查询客户指派操作日志列表
export function query_customer_allot_log_list(data) {
  return axios({
    method: 'post',
    url: "/query_customer_allot_log_list",
    data
  })
}
//指派同意或拒绝
export function update_customer_allot_log(data) {
  return axios({
    method: 'post',
    url: "/update_customer_allot_log",
    data
  })
}
//查询指派日志列表
export function query_customer_allot_log_list_feed_back(data) {
  return axios({
    method: 'post',
    url: "/query_customer_allot_log_list_feed_back",
    data
  })
}
//读取指派日志列表
export function update_customer_allot_log_read_flag(data) {
  return axios({
    method: 'post',
    url: "/update_customer_allot_log_read_flag",
    data
  })
}
//查询客户详情
export function query_customer_detail(data) {
  return axios({
    method: 'post',
    url: "/query_customer_detail",
    data
  })
}
//已分配里编辑
export function update_customer_form(data) {
  return axios({
    method: 'post',
    url: "/update_customer_form",
    data
  })
}
//侧边栏，客户操作日志
export function query_customer_mng_log_list(data) {
  return axios({
    method: 'post',
    url: "/query_customer_mng_log_list",
    data
  })
}
//查询部门的员工列表
export function query_staff_list_of_dept(data) {
  return axios({
    method: 'post',
    url: "/query_staff_list_of_dept",
    data
  })
}
//自动跑皮
export function auto_batch(data) {
  return axios({
    method: 'post',
    url: "/auto_batch",
    data
  })
}
//产品售卖规则——已经销售过的产品
export function no_sale_prod_list(data) {
  return axios({
    method: 'post',
    url: "/no_sale_prod_list",
    data
  })
}
//销售管理————看板上的归档
export function update_sale_mng_board_file_status(data) {
  return axios({
    method: 'post',
    url: "/update_sale_mng_board_file_status",
    data
  })
}
//设置全模块只读权限
export function set_all_module_read_power(data) {
  return axios({
    method: 'post',
    url: "/set_all_module_read_power",
    data
  })
}
//查询人员的全模块只读权限列表
export function query_all_module_read_power_list(data) {
  return axios({
    method: 'post',
    url: "/query_all_module_read_power_list",
    data
  })
}
//全模块下的权限明细
export function query_all_module_read_power_detail(data) {
  return axios({
    method: 'post',
    url: "/query_all_module_read_power_detail",
    data
  })
}
//销售管理--看板上的附件
export function upload_sale_mng_board_fj(data,config) {
  return axios({
    method: 'post',
    url: "/upload_sale_mng_board_fj",
    data,
    config
  })
}
//获取已上传的附件
export function get_con_annex(data) {
  return axios({
    method: 'post',
    url: "/get_con_annex",
    data
  })
}
//查询产品类型下的所有产品list
export function query_all_prod_list_of_category(data) {
  return axios({
    method: 'post',
    url: "/query_all_prod_list_of_category",
    data
  })
}
//单据红冲作废动作
export function form_cancel(data) {
  return axios({
    method: 'post',
    url: "/form_cancel",
    data
  })
}
//看板规则的新增和编辑
export function insert_role_board(data) {
  return axios({
    method: 'post',
    url: "/insert_role_board",
    data
  })
}
//看板规则的查询
export function query_role_board_rule(data) {
  return axios({
    method: 'post',
    url: "/query_role_board_rule",
    data
  })
}
//公海池删除客户
export function delete_customer_sea(data) {
  return axios({
    method: 'post',
    url: "/delete_customer_sea",
    data
  })
}
//新增角色组
export function insert_flow_group_info(data) {
  return axios({
    method: 'post',
    url: "/insert_flow_group_info",
    data
  })
}
//删除分组
export function delete_flow_group_info(data) {
  return axios({
    method: 'post',
    url: "/delete_flow_group_info",
    data
  })
}
//编辑分组
export function update_flow_group_info(data) {
  return axios({
    method: 'post',
    url: "/update_flow_group_info",
    data
  })
}
//修改表单分组
export function update_group_of_flow_form(data) {
  return axios({
    method: 'post',
    url: "/update_group_of_flow_form",
    data
  })
}
//查询表单列表
export function query_flow_group_info_list(data) {
  return axios({
    method: 'post',
    url: "/query_flow_group_info_list",
    data
  })
}
//获取角色看板列表数据
export function getRoleBoardData(data) {
  return axios({
    method: 'post',
    url: "/getRoleBoardData",
    data
  })
}
//表单分组修改顺序
export function update_flow_group_sort(data) {
  return axios({
    method: 'post',
    url: "/update_flow_group_sort",
    data
  })
}
//看板合同单编辑
export function update_contract(data) {
  return axios({
    method: 'post',
    url: "/update_contract",
    data
  })
}
//看板漏斗编辑
export function update_funn(data) {
  return axios({
    method: 'post',
    url: "/update_funn",
    data
  })
}
//查询消息条数
export function query_notify_num(data) {
  return axios({
    method: 'post',
    url: "/query_notify_num",
    data
  })
}
//消息已读
export function update_notify(data) {
  return axios({
    method: 'post',
    url: "/update_notify",
    data
  })
}
//查询眼睛表单list
export function query_eye_form_list(data) {
  return axios({
    method: 'post',
    url: "/query_eye_form_list",
    data
  })
}
//客户录入单附件下载--侧边栏
export function customer_annex_download(data) {
  return axios({
    method: 'post',
    url: "/customer_annex_download",
    data
  })
}
//查询工作日
export function query_workday(data) {
  return axios({
    method: 'post',
    url: "/query_workday",
    data
  })
}
//保存工作日
export function update_workday(data) {
  return axios({
    method: 'post',
    url: "/update_workday",
    data
  })
}
//客户是否能创立漏斗单
export function customer_if_can_creat_funn(data) {
  return axios({
    method: 'post',
    url: "/customer_if_can_creat_funn",
    data
  })
}
//判断合同表中是否能新建合同
export function contract_have_exist_file_no(data) {
  return axios({
    method: 'post',
    url: "/contract_have_exist_file_no",
    data
  })
}
//查询客户指派操作日志
export function query_cust_allot_log_list(data) {
  return axios({
    method: 'post',
    url: "/query_cust_allot_log_list",
    data
  })
}
//生成老高中级看板的数据
export function generate_role_board(data) {
  return axios({
    method: 'post',
    url: "/generate_role_board",
    data
  })
}
//判断产品中是否已经存在产品编号
export function have_exist_prod_code(data) {
  return axios({
    method: 'post',
    url: "/have_exist_prod_code",
    data
  })
}
//审批或签共享的列表list
export function query_flow_list_share_or(data) {
  return axios({
    method: 'post',
    url: "/query_flow_list_share_or",
    data
  })
}
//自定义表单  编辑保存
export function insert_cust_form(data) {
  return axios({
    method: 'post',
    url: "/insert_cust_form",
    data
  })
}
//自定义表单  点击编辑
export function query_cust_form_detail(data) {
  return axios({
    method: 'post',
    url: "/query_cust_form_detail",
    data
  })
}
//自定义表单  点击提交
export function insert_cust_form_data(data) {
  return axios({
    method: 'post',
    url: "/insert_cust_form_data",
    data
  })
}
//自定义表单  审批人可修改
export function update_cust_form_data(data) {
  return axios({
    method: 'post',
    url: "/update_cust_form_data",
    data
  })
}
//表单自定义下载pdf
export function generate_pdf_form(data) {
  return axios({
    method: 'post',
    url: "/generate_pdf_form",
    data
  })
}
//批量修改表单的顺序
export function update_flow_info_sort(data) {
  return axios({
    method: 'post',
    url: "/update_flow_info_sort",
    data
  })
}
//表单停用
export function form_active_stop(data) {
  return axios({
    method: 'post',
    url: "/form_active_stop",
    data
  })
}
//表单启用
export function form_active_start(data) {
  return axios({
    method: 'post',
    url: "/form_active_start",
    data
  })
}
//获取表单的编辑权限
export function query_edit_list(data) {
  return axios({
    method: 'post',
    url: "/query_edit_list",
    data
  })
}
//客户录入单明细
export function query_customer_detail_form(data) {
  return axios({
    method: 'post',
    url: "/query_customer_detail_form",
    data
  })
}
//部门停用列表
export function query_ent_dept_list2(data) {
  return axios({
    method: 'post',
    url: "/query_ent_dept_list2",
    data
  })
}
//看板汇总列表
export function query_form_list_gather(data) {
  return axios({
    method: 'post',
    url: "/query_form_list_gather",
    data
  })
}
// 表单里的编辑
export function update_s_deliver(data) {
  return axios({
    method: 'post',
    url: "/update_s_deliver",
    data
  })
}
export function update_s_biz_trip(data) {
  return axios({
    method: 'post',
    url: "/update_s_biz_trip",
    data
  })
}
export function update_s_go_out(data) {
  return axios({
    method: 'post',
    url: "/update_s_go_out",
    data
  })
}
export function update_received_cash(data) {
  return axios({
    method: 'post',
    url: "/update_received_cash",
    data
  })
}
export function update_invoice(data) {
  return axios({
    method: 'post',
    url: "/update_invoice",
    data
  })
}
export function update_s_dispatch(data) {
  return axios({
    method: 'post',
    url: "/update_s_dispatch",
    data
  })
}
export function update_s_visit_record(data) {
  return axios({
    method: 'post',
    url: "/update_s_visit_record",
    data
  })
}
export function update_s_visit_plan(data) {
  return axios({
    method: 'post',
    url: "/update_s_visit_plan",
    data
  })
}
export function update_customer_extra(data) {
  return axios({
    method: 'post',
    url: "/update_customer_extra",
    data
  })
}
export function update_funn_extra(data) {
  return axios({
    method: 'post',
    url: "/update_funn_extra",
    data
  })
}
// 企业部门列表
export function query_ent_dept_list_of_all(data) {
  return axios({
    method: 'post',
    url: "/query_ent_dept_list_of_all",
    data
  })
}
// 单据查询剩余的交付单金额
export function query_left_deliver_amt(data) {
  return axios({
    method: 'post',
    url: "/query_left_deliver_amt",
    data
  })
}
// 单据查询剩余的回款单金额
export function query_left_rec_amt(data) {
  return axios({
    method: 'post',
    url: "/query_left_rec_amt",
    data
  })
}
// 单据查询剩余的发票单金额
export function query_left_inv_amt(data) {
  return axios({
    method: 'post',
    url: "/query_left_inv_amt",
    data
  })
}
// 更新是否有子部门
export function update_dept_child_flag(data) {
  return axios({
    method: 'post',
    url: "/update_dept_child_flag",
    data
  })
}
// 查询当前部门的产品大类树
export function query_ent_prod_cat_list_tree_of_parent(data) {
  return axios({
    method: 'post',
    url: "/query_ent_prod_cat_list_tree_of_parent",
    data
  })
}
// 查询产品list   多个产品分类线的最末级产品平铺
export function query_prod_info_list_of_cats(data) {
  return axios({
    method: 'post',
    url: "/query_prod_info_list_of_cats",
    data
  })
}
// 单据--三位一体--交付单
export function trinity_deliver(data) {
  return axios({
    method: 'post',
    url: "/trinity_deliver",
    data
  })
}
// 成交时间、成交金额、退回册数、状态、停留时长、冷冻时长
export function query_customer_else_info(data) {
  return axios({
    method: 'post',
    url: "/query_customer_else_info",
    data
  })
}
// 编辑客户  编辑漏斗
export function update_edit_flag(data) {
  return axios({
    method: 'post',
    url: "/update_edit_flag",
    data
  })
}
// 编辑客户  编辑漏斗
export function staff_left_ent(data) {
  return axios({
    method: 'post',
    url: "/staff_left_ent",
    data
  })
}
// 更换手机号
export function change_user_mobile(data) {
  return axios({
    method: 'post',
    url: "/change_user_mobile",
    data
  })
}
// 企业账号获取验证码
export function ent_sms(data) {
  return axios({
    method: 'post',
    url: "/ent_sms",
    data
  })
}
// 用户登录日志
export function insert_m_login_log(data) {
  return axios({
    method: 'post',
    url: "/insert_m_login_log",
    data
  })
}
// 查询员工账号节点
export function query_staff_node(data) {
  return axios({
    method: 'post',
    url: "/query_staff_node",
    data
  })
}
// 设置员工账号节点
export function update_staff_node(data) {
  return axios({
    method: 'post',
    url: "/update_staff_node",
    data
  })
}
// 查询企业节点数
export function query_ent_node_num(data) {
  return axios({
    method: 'post',
    url: "/query_ent_node_num",
    data
  })
}
// 删除未认证企业
export function delete_un_aut_ent(data) {
  return axios({
    method: 'post',
    url: "/delete_un_aut_ent",
    data
  })
}
// 查询表单代写
export function query_form_replace_write(data) {
  return axios({
    method: 'post',
    url: "/query_form_replace_write",
    data
  })
}
// 保存表单代写
export function update_form_replace_write(data) {
  return axios({
    method: 'post',
    url: "/update_form_replace_write",
    data
  })
}
// 查询全模块销售
export function query_all_module_sale_userid(data) {
  return axios({
    method: 'post',
    url: "/query_all_module_sale_userid",
    data
  })
}
// 查询全模块销售
export function set_all_module_sale(data) {
  return axios({
    method: 'post',
    url: "/set_all_module_sale",
    data
  })
}
//查询发起人是否被代写   代写人
export function query_form_replace_write_user(data) {
  return axios({
    method: 'post',
    url: "/query_form_replace_write_user",
    data
  })
}
//设置代写人接口
export function set_replace_write_user(data) {
  return axios({
    method: 'post',
    url: "/set_replace_write_user",
    data
  })
}
//手动跑批
export function manual_batch(data) {
  return axios({
    method: 'post',
    url: "/manual_batch",
    data
  })
}
//新增仓库
export function insert_store_house(data) {
  return axios({
    method: 'post',
    url: "/insert_store_house",
    data
  })
}
//查询仓库list
export function query_store_house_list(data) {
  return axios({
    method: 'post',
    url: "/query_store_house_list",
    data
  })
}
//修改仓库名称
export function update_store_house(data) {
  return axios({
    method: 'post',
    url: "/update_store_house",
    data
  })
}
//判断供应商是否存在
export function supply_have_exist(data) {
  return axios({
    method: 'post',
    url: "/supply_have_exist",
    data
  })
}
//新增供应商录入单
export function insert_supply(data) {
  return axios({
    method: 'post',
    url: "/insert_supply",
    data
  })
}
//新增采购单接口
export function insert_purchase(data) {
  return axios({
    method: 'post',
    url: "/insert_purchase",
    data
  })
}
//新增采购发票单接口
export function insert_pur_inv(data) {
  return axios({
    method: 'post',
    url: "/insert_pur_inv",
    data
  })
}
//新增付款单接口
export function insert_payment(data) {
  return axios({
    method: 'post',
    url: "/insert_payment",
    data
  })
}
//新增入库单接口
export function insert_in_store(data) {
  return axios({
    method: 'post',
    url: "/insert_in_store",
    data
  })
}
//新增出库单
export function insert_out_store(data) {
  return axios({
    method: 'post',
    url: "/insert_out_store",
    data
  })
}
//新增采购规则
export function insert_rule_purchase(data) {
  return axios({
    method: 'post',
    url: "/insert_rule_purchase",
    data
  })
}
//新增库存规则
export function insert_rule_stock(data) {
  return axios({
    method: 'post',
    url: "/insert_rule_stock",
    data
  })
}
//新增库存规则明细列表
export function insert_rule_stock_qty(data) {
  return axios({
    method: 'post',
    url: "/insert_rule_stock_qty",
    data
  })
}
//查询库存规则
export function query_rule_stock_detail(data) {
  return axios({
    method: 'post',
    url: "/query_rule_stock_detail",
    data
  })
}
//查询采购规则
export function query_rule_purchase_detail(data) {
  return axios({
    method: 'post',
    url: "/query_rule_purchase_detail",
    data
  })
}
//查询库存规则明细list
export function query_rule_stock_qty_list(data) {
  return axios({
    method: 'post',
    url: "/query_rule_stock_qty_list",
    data
  })
}
//查询仓库--树形图
export function query_ent_site_tree(data) {
  return axios({
    method: 'post',
    url: "/query_ent_site_tree",
    data
  })
}
//查询产品对应的供应商列表
export function query_quotation_list(data) {
  return axios({
    method: 'post',
    url: "/query_quotation_list",
    data
  })
}
//查询产品类型下的所有产品list-- 加采购规则
export function query_all_prod_list_of_category_rule_pur(data) {
  return axios({
    method: 'post',
    url: "/query_all_prod_list_of_category_rule_pur",
    data
  })
}
//采购单--查询供应商产品的库存信息-0----库存数量，采购数量（最大库存）
export function query_prod_stock_info(data) {
  return axios({
    method: 'post',
    url: "/query_prod_stock_info",
    data
  })
}
//采购管理柱状图
export function generate_pur_mng_bar_chart(data) {
  return axios({
    method: 'post',
    url: "/generate_pur_mng_bar_chart",
    data
  })
}
//采购看板列表
export function generate_pur_mng_board(data) {
  return axios({
    method: 'post',
    url: "/generate_pur_mng_board",
    data
  })
}
//供应商管理列表
export function query_supply_mng_list(data) {
  return axios({
    method: 'post',
    url: "/query_supply_mng_list",
    data
  })
}
//查询供应商list
export function query_supply_list(data) {
  return axios({
    method: 'post',
    url: "/query_supply_list",
    data
  })
}
//查询供应商比价列表
export function query_compare_price_list(data) {
  return axios({
    method: 'post',
    url: "/query_compare_price_list",
    data
  })
}
//库存管理--柱状图
export function generate_stock_mng_bar_chart(data) {
  return axios({
    method: 'post',
    url: "/generate_stock_mng_bar_chart",
    data
  })
}
//库存管理--库存管理列表
export function query_stock_mng_list(data) {
  return axios({
    method: 'post',
    url: "/query_stock_mng_list",
    data
  })
}
//库存管理--库存管理盘点
export function query_stock_check_list(data) {
  return axios({
    method: 'post',
    url: "/query_stock_check_list",
    data
  })
}
//查询产品类型瞎的所有产品list
export function query_all_prod_list_of_category_in_store(data) {
  return axios({
    method: 'post',
    url: "/query_all_prod_list_of_category_in_store",
    data
  })
}
//查询产品类型瞎的所有产品list  //采购发票单
export function query_all_prod_list_of_category_pur_inv(data) {
  return axios({
    method: 'post',
    url: "/query_all_prod_list_of_category_pur_inv",
    data
  })
}
//付款单   查询各种金额
export function query_amts_of_payment(data) {
  return axios({
    method: 'post',
    url: "/query_amts_of_payment",
    data
  })
}
//查询供应商录入单的明细
export function query_supply_detail(data) {
  return axios({
    method: 'post',
    url: "/query_supply_detail",
    data
  })
}
//删除供应商
export function delete_supply(data) {
  return axios({
    method: 'post',
    url: "/delete_supply",
    data
  })
}
//查询供应商的最新采购日期
export function query_pur_date_and_pur_amt_sum(data) {
  return axios({
    method: 'post',
    url: "/query_pur_date_and_pur_amt_sum",
    data
  })
}
//供应商编辑
export function update_supply(data) {
  return axios({
    method: 'post',
    url: "/update_supply",
    data
  })
}
//供应商附件
export function upload_stock_mng_board_fj(data) {
  return axios({
    method: 'post',
    url: "/upload_stock_mng_board_fj",
    data
  })
}
//供应商录入单附件下载
export function supply_annex_download(data) {
  return axios({
    method: 'post',
    url: "/supply_annex_download",
    data
  })
}
//单据--编辑更新供应商录入单的产品标签（产品是最底层）
export function update_supply_prod_ids(data) {
  return axios({
    method: 'post',
    url: "/update_supply_prod_ids",
    data
  })
}
//出库单选择客户   带出交付单的产品
export function query_all_prod_list_of_category_out_store(data) {
  return axios({
    method: 'post',
    url: "/query_all_prod_list_of_category_out_store",
    data
  })
}
//查询停用仓库列表
export function query_stop_store_house_list(data) {
  return axios({
    method: 'post',
    url: "/query_stop_store_house_list",
    data
  })
}
//停用仓库--批量
export function store_house_active_stop(data) {
  return axios({
    method: 'post',
    url: "/store_house_active_stop",
    data
  })
}
//启用仓库--批量
export function store_house_active_start(data) {
  return axios({
    method: 'post',
    url: "/store_house_active_start",
    data
  })
}
//查询某个仓库下的产品
export function query_prod_of_store_house_list(data) {
  return axios({
    method: 'post',
    url: "/query_prod_of_store_house_list",
    data
  })
}
//批量修改产品的仓库属性
export function batch_update_prod_store(data) {
  return axios({
    method: 'post',
    url: "/batch_update_prod_store",
    data
  })
}
//查询供应商产品报价list---2
export function query_quotation_list2(data) {
  return axios({
    method: 'post',
    url: "/query_quotation_list2",
    data
  })
}
//修改供应商产品报价
export function insert_quotation_batch(data) {
  return axios({
    method: 'post',
    url: "/insert_quotation_batch",
    data
  })
}
//查询供应商产品报价list--历史查询
export function query_quotation_his_list(data) {
  return axios({
    method: 'post',
    url: "/query_quotation_his_list",
    data
  })
}
//供应商产品报价历史表--里面的日期list
export function query_quotation_his_date_list(data) {
  return axios({
    method: 'post',
    url: "/query_quotation_his_date_list",
    data
  })
}
//采购看板--供应商的产品列表数量统计
export function query_prod_qty_of_supply_list(data) {
  return axios({
    method: 'post',
    url: "/query_prod_qty_of_supply_list",
    data
  })
}
//库存管理--库存管理列表--明细记账
export function query_stock_mng_detail_list(data) {
  return axios({
    method: 'post',
    url: "/query_stock_mng_detail_list",
    data
  })
}
//库存管理--明细记账中的产品列表
export function query_stock_mng_detail_prod_list(data) {
  return axios({
    method: 'post',
    url: "/query_stock_mng_detail_prod_list",
    data
  })
}
//库存管理--提供盘点
export function supply_stock_check_list(data) {
  return axios({
    method: 'post',
    url: "/supply_stock_check_list",
    data
  })
}
//库存盘点历史表--里面的日期list
export function query_stock_check_his_date_list(data) {
  return axios({
    method: 'post',
    url: "/query_stock_check_his_date_list",
    data
  })
}
//库存盘点--保存
export function save_stock_check_info(data) {
  return axios({
    method: 'post',
    url: "/save_stock_check_info",
    data
  })
}
//搜索人员范围列表
export function query_staff_list_of_dept_3(data) {
  return axios({
    method: 'post',
    url: "/query_staff_list_of_dept_3",
    data
  })
}
//供应商列表--带权限
export function query_supply_list_of_power(data) {
  return axios({
    method: 'post',
    url: "/query_supply_list_of_power",
    data
  })
}
//单据--查询客户的纳税识别号
export function query_uscc_of_cust(data) {
  return axios({
    method: 'post',
    url: "/query_uscc_of_cust",
    data
  })
}
//导入日志表--里面的日期list
export function query_import_log_date_list(data) {
  return axios({
    method: 'post',
    url: "/query_import_log_date_list",
    data
  })
}
//出库单--查询出库产品的库存信息--库存数量
export function query_prod_stock_num(data) {
  return axios({
    method: 'post',
    url: "/query_prod_stock_num",
    data
  })
}
//供应商列表--自己的
export function query_supply_list_of_self(data) {
  return axios({
    method: 'post',
    url: "/query_supply_list_of_self",
    data
  })
}
//供应商修改负责人
export function change_supply_staff(data) {
  return axios({
    method: 'post',
    url: "/change_supply_staff",
    data
  })
}
//筛选仓库
export function query_stock_mng_detail_site_list(data) {
  return axios({
    method: 'post',
    url: '/query_stock_mng_detail_site_list',
    data
  })
}

export function query_check_no_list(data) {
  return axios({
    method: 'post',
    url: '/query_check_no_list',
    data
  })
}

export function save_pre_stock_check_info(data) {
  return axios({
    method: 'post',
    url: '/save_pre_stock_check_info',
    data
  })
}