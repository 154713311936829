
!(function (n) {
  var e = n.document
    
  var t = e.documentElement

  var i = 1920

  var d = i / 100

  var o = 'orientationchange' in n ? 'orientationchange' : 'resize'

  var a = function () {
    var n = t.clientWidth
    n <= 1280 && (n = 1280)
    t.style.fontSize = n / d + 'px'
  }
  console.log(e)
  console.log(t)
  console.log(d)
  console.log(o)
  e.addEventListener && (n.addEventListener(o, a, !1), e.addEventListener('DOMContentLoaded', a, !1))
}(window))
