var bendi_naqu_cunchu = function (name, type) {
  if (type == '暂时') {
    if (sessionStorage.getItem(name) != null && sessionStorage.getItem(name) != undefined && sessionStorage.getItem(name) != '') {
      return JSON.parse(sessionStorage.getItem(name))
    }
  }
  if (type == '永久') {
    if (localStorage.getItem(name) != null && localStorage.getItem(name) != undefined && localStorage.getItem(name) != '') {
      return JSON.parse(localStorage.getItem(name))
    }
  }
}
export default bendi_naqu_cunchu
