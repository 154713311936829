<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-10-07 16:46:29
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="homea">
    <vue-canvas-nest style="z-index:0"></vue-canvas-nest>
    <!-- <header class="header">
      <div class="center_title">
        <div class="header_left">
          <img class="logo" src="../assets/logo.png" alt>
          <img class="text" src="../assets/text2.png" alt>
          <img class="phone" src="../assets/phone.png" alt>
          <p class="phone_text">公司电话 1008611</p>
        </div>
        <div class="p">
          手机端
          <span class="erweima" style="color:red;">登录 <img class="er" src="../assets/er.png" alt></span>
          <router-link style="color:green;" to="/enterprise">企业登录</router-link>
          
        </div>
      </div>
        
    </header> -->
    <waihead></waihead>
    <!-- <div class="click_btn">
      <router-link class="log" to="/save">登录</router-link>
      <router-link class="reg" to="/register">注册</router-link>
    </div> -->
  </div>
</template>

<script>
import waihead from '../components/Waihead'
import vueCanvasNest from 'vue-canvas-nest'
export default {
  name: 'homea',
  data () {
    return {}
  },
  components: { vueCanvasNest, waihead },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.homea {
  background-image: url("../assets/background-img.png");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  .header {
    position: fixed;
    top: 0;
    height: 0.8rem;
    width: 100%;
    background-color: #f5f5f5;
    .center_title {
      margin: 0 auto;
      height: 100%;
      width: 9.55rem;
      background-color: #f5f5f5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header_left {
        display: flex;
        align-items: center;
        .logo {
          height: 0.33rem;
          width: 0.35rem;
        }
        .text {
          height: 0.18rem;
          width: 0.75rem;
          margin-left: 0.05rem;
          margin-right: 0.23rem;
        }
        .phone {
          height: 0.18rem;
          width: 0.17rem;
          margin-right: 0.05rem;
        }
        .phone_text {
          font-size: 0.14rem;
          color: #3c3a3e;
        }
        .p {
        }
      }
    }
    .erweima {
      &:hover .er{
        display: block;
      }
    }
      .er {
        height: 1.6rem;
        width: 1.6rem;
        position: absolute;
        display: none;
      }
    @media screen and (max-width: 1000px) {
      .center_title {
        width: 90%;
      }
    }
    @media screen and (max-width: 500px) {
      .phone,
      .phone_text {
        display: none;
      }
    }
  }

  .click_btn {
    width: 477px;
    height: 0.38rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: 70%;
    z-index: 99;
    .log {
      height: 0.38rem;
      width: 1.96rem;
      background-color: #fc0506;
      line-height: 0.38rem;
      text-align: center;
      border-radius: 0.06rem;
      color: #fff;
      font-size: 0.16rem;
      box-shadow: 0 0.04rem 0.06rem 0 rgba(0, 0, 0, 0.5);
      opacity: 0.8;
      &:hover{
        background: #A22824;
      }
    }
    .reg {
      height: 0.38rem;
      width: 1.96rem;
      background-color: #fff;
      line-height: 0.38rem;
      text-align: center;
      border-radius: 0.06rem;
      color: #000;
      font-size: 0.16rem;
      border: 0.01rem solid #949393;
    }
  }
}
@media screen and (max-width: 12rem) {
  .homea {
    width:12rem;
  }
}
</style>
