
import { logout } from '../api/api.js'  
        
var lastTime = new Date().getTime()
var currentTime = new Date().getTime()
// var timeOut = 15 * 60 * 1000; //设置超时时间： 30分
var timeOut = 15 * 60 * 1000 // 设置超时时间： 30分
    
window.onload = function () {
  window.document.onmousedown = function () {
    sessionStorage.setItem('lastTime', new Date().getTime())
  }
}
function checkTimeout () {
  if (sessionStorage.getItem('pc_mlbb_jichuxinxi') !== undefined && sessionStorage.getItem('pc_mlbb_jichuxinxi') !== null) {
    currentTime = new Date().getTime() // 更新当前时间
    lastTime = sessionStorage.getItem('lastTime')
    // console.log(currentTime - lastTime);
    // console.log(timeOut);
    if (currentTime - lastTime > timeOut && window.location.href.indexOf('39.98.186.128:8080') == -1) { // 判断是否超时
      // console.log("超时");
      var url = window.location.href
      var newUrl = url.match(/(\S*)#/)[1]

      window.open(newUrl + '#/', '_self')
      logout({
        data: {
          mobile: JSON.parse(JSON.parse(sessionStorage.getItem('pc_mlbb_jichuxinxi'))).mobile
        }
      }).then(res => {
        console.log(res)
        sessionStorage.clear()
        if (res.data.code == 10015) {
        } else if (res.data.code == 10016) {
        } else if (res.data.code == 11000) {
        }
      })
    }
  }
}
    
/* 定时器 间隔30秒检测是否长时间未操作页面 */
window.setInterval(checkTimeout, 30000)
